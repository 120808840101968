import React, { useEffect } from "react";
import styles from "./styles.module.css";
import { Helmet } from "react-helmet";
import Navbar from "../../../components/Navbar";
import Footer from "../../../components/Footer";

import { useLocation } from "react-router-dom";
import { setLocalStorageItem } from "../../../utils/helpers";
export default function Layout({ children, toggleDetails, setToggleDetails }) {
  const { pathname } = useLocation();

  useEffect(() => {
    setLocalStorageItem("persist", {
      primary: "all projects",
      secondary: null,
    });
  }, []);

  useEffect(() => {
    setToggleDetails(false);
  }, [pathname]);

  return (
    <div className={pathname === "/project" || pathname === "/stories" ? styles.rootOffWhite : styles.root}>
      <Helmet>
        <meta charSet='utf-8' />
        <title>
          {pathname === "/"
            ? "Home "
            : pathname.replace("/", "").charAt(0).toUpperCase() + pathname.replace("/", "").slice(1)}{" "}
          | Between Lines
        </title>
        <meta name='description' content='Dummy Description' />
        <meta property='og:title' content='Between Lines' />
        <meta property='og:description' content='Dummy Description' />
        <meta property='og:image' content='/logo.png' />
        <meta property='twitter:title' content='Between Lines' />
        <meta property='twitter:description' content='Dummy Description' />
        <meta property='twitter:image' content='/logo.png' />
        <meta property='og:type' content='website' />
        <meta property='og:image' content='/favicon.png' />
        <link rel='apple-touch-icon' href='/favicon.png' />
        <link rel='shortcut icon' href='/favicon.png' type='image/x-icon' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />

        <link rel='stylesheet' href='/assets/brand_slider.css' type='text/css' />
        <link rel='stylesheet' href='/assets/styles.css' type='text/css' />
      </Helmet>
      <Navbar toggleDetails={toggleDetails} />
      <main className={styles.main} style={{ paddingTop: "5rem" }}>
        {children}
      </main>

      {pathname !== "/stories" && <Footer />}
    </div>
  );
}
