import React from "react";

function ArrowDown() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='31' viewBox='0 0 32 31' fill='none'>
      <path
        d='M16.25 7C16.25 6.58579 15.9142 6.25 15.5 6.25C15.0858 6.25 14.75 6.58579 14.75 7L16.25 7ZM16.25 23L16.25 7L14.75 7L14.75 23L16.25 23Z'
        fill='#ACACAC'
      />
      <path
        d='M8.72047 17.2193C8.79003 17.1496 8.87265 17.0943 8.96359 17.0566C9.05453 17.0189 9.15202 16.9995 9.25047 16.9995C9.34892 16.9995 9.4464 17.0189 9.53734 17.0566C9.62829 17.0943 9.7109 17.1496 9.78047 17.2193L15.5005 22.9383L21.2205 17.2193C21.2901 17.1497 21.3727 17.0945 21.4636 17.0568C21.5546 17.0191 21.652 16.9997 21.7505 16.9997C21.8489 16.9997 21.9464 17.0191 22.0373 17.0568C22.1282 17.0945 22.2109 17.1497 22.2805 17.2193C22.3501 17.2889 22.4053 17.3715 22.4429 17.4624C22.4806 17.5534 22.5 17.6508 22.5 17.7493C22.5 17.8477 22.4806 17.9452 22.4429 18.0361C22.4053 18.127 22.3501 18.2097 22.2805 18.2793L16.0305 24.5293C15.9609 24.5989 15.8783 24.6542 15.7873 24.6919C15.6964 24.7296 15.5989 24.749 15.5005 24.749C15.402 24.749 15.3045 24.7296 15.2136 24.6919C15.1226 24.6542 15.04 24.5989 14.9705 24.5293L8.72047 18.2793C8.6508 18.2097 8.59554 18.1271 8.55783 18.0361C8.52012 17.9452 8.50071 17.8477 8.50071 17.7493C8.50071 17.6508 8.52012 17.5533 8.55783 17.4624C8.59554 17.3714 8.6508 17.2888 8.72047 17.2193Z'
        fill='#ACACAC'
      />
      <circle cx='16' cy='15.5' r='15' stroke='#ACACAC' />
    </svg>
  );
}

export default ArrowDown;
