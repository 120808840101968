import React from "react";

function Down() {
  return (
    <svg
      viewBox='0 0 24 24'
      width='20'
      height='20'
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      className='feather feather-chevron-down'
    >
      <polyline points='6 9 12 15 18 9'></polyline>
    </svg>
  );
}

export default Down;
