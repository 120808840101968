import React from "react";

function Quote() {
  return (
    <svg width='15' height='12' viewBox='0 0 15 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        id='Vector'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.19885 0.107662C2.11112 2.09069 0.395856 4.39849 0.0526384 7.03228C-0.481665 11.1316 3.18424 13.138 5.04179 11.3354C6.89935 9.5324 5.78605 7.24428 4.44106 6.61854C3.09608 5.9932 2.2735 6.21094 2.41702 5.37484C2.56054 4.53914 4.47387 2.2215 6.1551 1.14223C6.21467 1.09157 6.25268 1.02009 6.26137 0.942371C6.27006 0.864649 6.24877 0.786541 6.20184 0.723977L5.78605 0.183113C5.60562 -0.0514397 5.43258 -0.0424186 5.19885 0.107252V0.107662ZM13.3286 0.107662C10.2409 2.09069 8.52522 4.3989 8.18242 7.03228C7.64811 11.1316 11.314 13.138 13.1716 11.3354C15.0291 9.5324 13.9158 7.24428 12.5704 6.61854C11.2254 5.9932 10.4029 6.21094 10.5464 5.37484C10.6899 4.53914 12.6036 2.2215 14.2849 1.14223C14.3444 1.09152 14.3823 1.02001 14.3909 0.942288C14.3995 0.864569 14.3782 0.786492 14.3312 0.723977L13.9154 0.183113C13.735 -0.0514397 13.5619 -0.0424186 13.3286 0.107252V0.107662Z'
        fill='#F8F8F8'
      />
    </svg>
  );
}

export default Quote;
