import React from "react";

function Up() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      height='20'
      width='20'
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      className='feather feather-chevron-up'
    >
      <polyline points='18 15 12 9 6 15'></polyline>
    </svg>
  );
}

export default Up;
