import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import ArrowDown from "../../assets/svg/ArrowDown";
import LandingFrame from "../../assets/svg/LandingFrame";
import d0 from "../../assets/landing/d0.png";
import d1 from "../../assets/landing/d1.png";
import d2 from "../../assets/landing/d2.png";
import d3 from "../../assets/landing/d3.png";
import d4 from "../../assets/landing/d4.png";
import d5 from "../../assets/landing/d5.png";

import { motion, AnimatePresence } from "framer-motion";
import { useWindowSize } from "@uidotdev/usehooks";

export default function Landing() {
  const [visible, setVisible] = useState(true);
  const scrollDown = () => {
    window.scrollBy({ top: window.innerHeight, behavior: "smooth" });
  };
  const size = useWindowSize();
  const images = [d1, d2, d3, d4, d5];
  useEffect(() => {
    setTimeout(() => {
      setVisible(false);
    }, 4000);
  }, []);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    if (visible) {
      const interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
      }, 4000);

      return () => clearInterval(interval);
    }
    return undefined;
  }, [images.length]);
  return (
    <div className={styles.root} id='landing'>
      <div className={styles.frame}>
        {visible ? (
          size.width > 1024 ? (
            <LandingFrame />
          ) : (
            <motion.img
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
              }}
              exit={{ opacity: 0 }}
              transition={{ duration: 3, ease: "easeOut" }}
              src={d0}
              alt='asd'
              srcSet=''
              style={
                size.width > 1024
                  ? {
                      position: "absolute",
                      width: "100%",
                      height: "80%",
                      objectFit: "cover",
                      padding: "3.75rem",
                    }
                  : {
                      position: "absolute",
                      width: "100%",
                      height: "80%",
                      objectFit: "cover",
                      padding: "1.25rem",
                    }
              }
            />
          )
        ) : (
          <AnimatePresence>
            {images.map((image, index) => (
              <motion.img
                key={index}
                initial={{ opacity: 0 }}
                animate={{
                  opacity: index === currentImageIndex ? 1 : 0,
                }}
                exit={{ opacity: 0 }}
                transition={{ duration: 3, ease: "easeOut" }}
                src={image}
                alt='asd'
                srcSet=''
                style={
                  size.width > 1024
                    ? {
                        position: "absolute",
                        width: "100%",
                        height: "80%",
                        objectFit: "cover",
                        padding: "3.75rem",
                      }
                    : {
                        position: "absolute",
                        width: "100%",
                        height: "80%",
                        objectFit: "cover",
                        padding: "1.25rem",
                      }
                }
              />
            ))}
          </AnimatePresence>
        )}
      </div>
      <div className={styles.arrow}>
        <button onClick={scrollDown}>
          <ArrowDown />
        </button>
      </div>
    </div>
  );
}
