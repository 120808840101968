import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import Cross from "../../assets/svg/Cross";
import logonohover from "../../assets/logo.png";
import logoonhover from "../../assets/logonohover.png";

import { useWindowSize } from "@uidotdev/usehooks";
import Hamburger from "../../assets/svg/Hamburger";
import { slide as Menu } from "react-burger-menu";
import { navlinks } from "../../utils/constants";
import { Link } from "react-router-dom";
import logonohoverwhite from "../../assets/logonohoverwhite.png";
import logoonhoverwhite from "../../assets/logowhite.png";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import { useScroll, useSpring, motion, AnimatePresence } from "framer-motion";
export default function Navbar({ toggleDetails }) {
  const size = useWindowSize();
  const { pathname } = useLocation();
  const [isOpen, setOpen] = useState(false);
  const { scrollYProgress } = useScroll();
  const [scrolled, setScrolled] = useState(false);
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  const resizeHeaderOnScroll = () => {
    const distanceY = window.pageYOffset || document.documentElement.scrollTop;
    const shrinkOn = size.width >= 1024 ? 700 : 900;
    const headerEl = document.getElementById("navbar");

    if (distanceY > shrinkOn) {
      if (size.width >= 500) headerEl.classList.remove("smaller");
      setScrolled(true);
    } else {
      headerEl.classList.add("smaller");
      setScrolled(false);
    }
  };

  useEffect(() => {
    if (pathname === "/") {
      window.addEventListener("scroll", resizeHeaderOnScroll);

      return () => {
        window.removeEventListener("scroll", resizeHeaderOnScroll);
      };
    }
  }, [size.width, pathname]);

  const handleIsOpen = () => {
    setOpen(!isOpen);
  };

  const closeSideBar = () => {
    setOpen(false);
  };

  const conditionalStyles = {
    "/": { background: scrolled ? "#d9d9d9" : "#2f2f2f", color: scrolled ? "black" : "#acacac" },
    "/project": { background: "#f8f8f8" },
    "/contact": { background: "#2f2f2f", color: "#acacac" },
  };
  const conditionalStylesOnHover = {
    "/": { background: "#2f2f2f", color: "#fff" },
    "/project": { background: "#f8f8f8" },
    "/contact": { background: "#2f2f2f", color: "#fff" },
    "/work": { color: "black" },
    "/practice": { color: "black" },
    "/news": { color: "black" },
    "/stories": { color: "black" },
  };

  return (
    <nav
      className={size.width > 1024 && pathname === "/" ? classNames(styles.root, "smaller") : classNames(styles.root)}
      style={conditionalStyles[pathname]}
      id='navbar'
    >
      {pathname !== "/contact" && pathname !== "/" && pathname !== "/project" && (
        <Link to={"/"} className={styles.logo} id='logo'>
          <img src={logonohover} alt='logo' className='img-noshadow' />
          <img src={logoonhover} alt='logo' className='img-noshadow' />
        </Link>
      )}
      {pathname === "/" && pathname !== "/project" && (
        <Link to={"/"} className={styles.logo} id='logo'>
          <img src={scrolled ? logonohover : logoonhoverwhite} alt='logo' className='img-noshadow' />
          <img src={!scrolled ? logonohoverwhite : logoonhover} alt='logo' className='img-noshadow' />
        </Link>
      )}
      {pathname === "/contact" && (
        <Link to={"/"} className={styles.logo} id='logo'>
          <img src={logoonhoverwhite} alt='logo' className='img-noshadow' />
          <img src={logonohoverwhite} alt='logo' className='img-noshadow' />
        </Link>
      )}

      {pathname === "/project" && (
        <Link to={"/"} className={styles.logo} id='logo'>
          <img src={logoonhover} alt='logo' className='img-noshadow' />
          <img src={logonohover} alt='logo' className='img-noshadow' />
        </Link>
      )}
      {size.width > 1024 && (
        <div className={styles.navItems}>
          {navlinks.map((item, index) => {
            return (
              <Link
                key={index}
                className={styles.navItem}
                style={item.text.toLowerCase() === pathname.slice(1) ? conditionalStylesOnHover[pathname] : {}}
                to={item.link}
              >
                <p>{item.text}</p>
              </Link>
            );
          })}
        </div>
      )}
      {size.width <= 1024 && (
        <Menu
          isOpen={isOpen}
          onOpen={handleIsOpen}
          onClose={handleIsOpen}
          right
          width={size.width >= 1025 ? size.width / 4 : size.width >= 576 ? size.width / 4.35 : size.width + 0.5}
          customCrossIcon={<Cross fill={"#D6D6D6"} />}
          customBurgerIcon={<Hamburger scrolled={scrolled} />}
        >
          {navlinks.map((item, index) => {
            return (
              <Link key={index} className={styles.menuItem} to={item.link} onClick={closeSideBar}>
                <p>{item.text}</p>
              </Link>
            );
          })}
        </Menu>
      )}
      {toggleDetails && pathname === "/project" && <motion.div className='progress-bar' style={{ scaleX }} />}
    </nav>
  );
}
