import React from "react";
import { motion } from "framer-motion";
export default function LoadingBlack() {
  const delays = [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6];
  return (
    <svg width='100%' viewBox='0 0 1304 115' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <motion.circle
        initial={{ scale: 1, filter: "drop-shadow(0px 0px 0px rgba(0, 0, 0, 0))" }}
        animate={{
          scale: 1.7,
          filter: "drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.63))",
        }}
        transition={{
          delay: delays[0],
          type: "spring",
          stiffness: 260,
          damping: 90,
        }}
        cx='13.5'
        cy='13.5'
        r='6.5'
        fill='#2F2F2F'
        stroke='#2F2F2F'
      />

      <circle cx='58.5' cy='13.5' r='6.5' fill='#2F2F2F' stroke='#2F2F2F' />
      <motion.circle
        initial={{ scale: 1, filter: "drop-shadow(0px 0px 0px rgba(0, 0, 0, 0))" }}
        animate={{
          scale: 1.7,
          filter: "drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.63))",
        }}
        transition={{
          delay: delays[0],
          type: "spring",
          stiffness: 260,
          damping: 90,
        }}
        cx='13.5'
        cy='57.5'
        r='6.5'
        fill='#2F2F2F'
        stroke='#2F2F2F'
      />
      <circle cx='58.5' cy='57.5' r='6.5' fill='#2F2F2F' stroke='#2F2F2F' />
      <circle cx='13.5' cy='101.5' r='6.5' fill='#2F2F2F' stroke='#2F2F2F' />
      <circle cx='58.5' cy='101.5' r='6.5' fill='#2F2F2F' stroke='#2F2F2F' />
      {/*  */}
      <motion.circle
        initial={{ scale: 1, filter: "drop-shadow(0px 0px 0px rgba(0, 0, 0, 0))" }}
        animate={{
          scale: 1.7,
          filter: "drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.63))",
        }}
        transition={{
          delay: delays[1],
          type: "spring",
          stiffness: 260,
          damping: 90,
        }}
        cx='125.5'
        cy='13.5'
        r='6.5'
        fill='#2F2F2F'
        stroke='#2F2F2F'
      />
      <circle cx='170.5' cy='13.5' r='6.5' fill='#2F2F2F' stroke='#2F2F2F' />
      <circle cx='125.5' cy='57.5' r='6.5' fill='#2F2F2F' stroke='#2F2F2F' />
      <motion.circle
        initial={{ scale: 1, filter: "drop-shadow(0px 0px 0px rgba(0, 0, 0, 0))" }}
        animate={{
          scale: 1.7,
          filter: "drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.63))",
        }}
        transition={{
          delay: delays[1],
          type: "spring",
          stiffness: 260,
          damping: 90,
        }}
        cx='170.5'
        cy='57.5'
        r='6.5'
        fill='#2F2F2F'
        stroke='#2F2F2F'
      />
      <circle cx='125.5' cy='101.5' r='6.5' fill='#2F2F2F' stroke='#2F2F2F' />
      <circle cx='170.5' cy='101.5' r='6.5' fill='#2F2F2F' stroke='#2F2F2F' />
      {/*  */}
      <circle cx='237.5' cy='13.5' r='6.5' fill='#2F2F2F' stroke='#2F2F2F' />
      <motion.circle
        initial={{ scale: 1, filter: "drop-shadow(0px 0px 0px rgba(0, 0, 0, 0))" }}
        animate={{
          scale: 1.7,
          filter: "drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.63))",
        }}
        transition={{
          delay: delays[2],
          type: "spring",
          stiffness: 260,
          damping: 90,
        }}
        cx='282.5'
        cy='13.5'
        r='6.5'
        fill='#2F2F2F'
        stroke='#2F2F2F'
      />
      <motion.circle
        initial={{ scale: 1, filter: "drop-shadow(0px 0px 0px rgba(0, 0, 0, 0))" }}
        animate={{
          scale: 1.7,
          filter: "drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.63))",
        }}
        transition={{
          delay: delays[2],
          type: "spring",
          stiffness: 260,
          damping: 90,
        }}
        cx='237.5'
        cy='57.5'
        r='6.5'
        fill='#2F2F2F'
        stroke='#2F2F2F'
      />
      <motion.circle
        initial={{ scale: 1, filter: "drop-shadow(0px 0px 0px rgba(0, 0, 0, 0))" }}
        animate={{
          scale: 1.7,
          filter: "drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.63))",
        }}
        transition={{
          delay: delays[2],
          type: "spring",
          stiffness: 260,
          damping: 90,
        }}
        cx='282.5'
        cy='57.5'
        r='6.5'
        fill='#2F2F2F'
        stroke='#2F2F2F'
      />
      <motion.circle
        initial={{ scale: 1, filter: "drop-shadow(0px 0px 0px rgba(0, 0, 0, 0))" }}
        animate={{
          scale: 1.7,
          filter: "drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.63))",
        }}
        transition={{
          delay: delays[2],
          type: "spring",
          stiffness: 260,
          damping: 90,
        }}
        cx='237.5'
        cy='101.5'
        r='6.5'
        fill='#2F2F2F'
        stroke='#2F2F2F'
      />
      <circle cx='282.5' cy='101.5' r='6.5' fill='#2F2F2F' stroke='#2F2F2F' />
      {/*  */}
      <circle cx='349.5' cy='13.5' r='6.5' fill='#2F2F2F' stroke='#2F2F2F' />
      <motion.circle
        initial={{ scale: 1, filter: "drop-shadow(0px 0px 0px rgba(0, 0, 0, 0))" }}
        animate={{
          scale: 1.7,
          filter: "drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.63))",
        }}
        transition={{
          delay: delays[3],
          type: "spring",
          stiffness: 260,
          damping: 90,
        }}
        cx='394.5'
        cy='13.5'
        r='6.5'
        fill='#2F2F2F'
        stroke='#2F2F2F'
      />
      <motion.circle
        initial={{ scale: 1, filter: "drop-shadow(0px 0px 0px rgba(0, 0, 0, 0))" }}
        animate={{
          scale: 1.7,
          filter: "drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.63))",
        }}
        transition={{
          delay: delays[3],
          type: "spring",
          stiffness: 260,
          damping: 90,
        }}
        cx='349.5'
        cy='57.5'
        r='6.5'
        fill='#2F2F2F'
        stroke='#2F2F2F'
      />
      <motion.circle
        initial={{ scale: 1, filter: "drop-shadow(0px 0px 0px rgba(0, 0, 0, 0))" }}
        animate={{
          scale: 1.7,
          filter: "drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.63))",
        }}
        transition={{
          delay: delays[3],
          type: "spring",
          stiffness: 260,
          damping: 90,
        }}
        cx='394.5'
        cy='57.5'
        r='6.5'
        fill='#2F2F2F'
        stroke='#2F2F2F'
      />
      <circle cx='349.5' cy='101.5' r='6.5' fill='#2F2F2F' stroke='#2F2F2F' />
      <motion.circle
        initial={{ scale: 1, filter: "drop-shadow(0px 0px 0px rgba(0, 0, 0, 0))" }}
        animate={{
          scale: 1.7,
          filter: "drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.63))",
        }}
        transition={{
          delay: delays[3],
          type: "spring",
          stiffness: 260,
          damping: 90,
        }}
        cx='394.5'
        cy='101.5'
        r='6.5'
        fill='#2F2F2F'
        stroke='#2F2F2F'
      />
      {/*  */}
      <motion.circle
        initial={{ scale: 1, filter: "drop-shadow(0px 0px 0px rgba(0, 0, 0, 0))" }}
        animate={{
          scale: 1.7,
          filter: "drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.63))",
        }}
        transition={{
          delay: delays[4],
          type: "spring",
          stiffness: 260,
          damping: 90,
        }}
        cx='461.5'
        cy='13.5'
        r='6.5'
        fill='#2F2F2F'
        stroke='#2F2F2F'
      />
      <circle cx='506.5' cy='13.5' r='6.5' fill='#2F2F2F' stroke='#2F2F2F' />
      <circle cx='461.5' cy='57.5' r='6.5' fill='#2F2F2F' stroke='#2F2F2F' />
      <motion.circle
        initial={{ scale: 1, filter: "drop-shadow(0px 0px 0px rgba(0, 0, 0, 0))" }}
        animate={{
          scale: 1.7,
          filter: "drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.63))",
        }}
        transition={{
          delay: delays[4],
          type: "spring",
          stiffness: 260,
          damping: 90,
        }}
        cx='506.5'
        cy='57.5'
        r='6.5'
        fill='#2F2F2F'
        stroke='#2F2F2F'
      />
      <circle cx='461.5' cy='101.5' r='6.5' fill='#2F2F2F' stroke='#2F2F2F' />
      <circle cx='506.5' cy='101.5' r='6.5' fill='#2F2F2F' stroke='#2F2F2F' />
      {/*  */}
      <motion.circle
        initial={{ scale: 1, filter: "drop-shadow(0px 0px 0px rgba(0, 0, 0, 0))" }}
        animate={{
          scale: 1.7,
          filter: "drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.63))",
        }}
        transition={{
          delay: delays[5],
          type: "spring",
          stiffness: 260,
          damping: 90,
        }}
        cx='573.5'
        cy='13.5'
        r='6.5'
        fill='#2F2F2F'
        stroke='#2F2F2F'
      />
      <circle cx='618.5' cy='13.5' r='6.5' fill='#2F2F2F' stroke='#2F2F2F' />
      <circle cx='573.5' cy='57.5' r='6.5' fill='#2F2F2F' stroke='#2F2F2F' />
      <motion.circle
        initial={{ scale: 1, filter: "drop-shadow(0px 0px 0px rgba(0, 0, 0, 0))" }}
        animate={{
          scale: 1.7,
          filter: "drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.63))",
        }}
        transition={{
          delay: delays[5],
          type: "spring",
          stiffness: 260,
          damping: 90,
        }}
        cx='618.5'
        cy='57.5'
        r='6.5'
        fill='#2F2F2F'
        stroke='#2F2F2F'
      />
      <circle cx='573.5' cy='101.5' r='6.5' fill='#2F2F2F' stroke='#2F2F2F' />
      <circle cx='618.5' cy='101.5' r='6.5' fill='#2F2F2F' stroke='#2F2F2F' />
      {/*  */}
      <motion.circle
        initial={{ scale: 1, filter: "drop-shadow(0px 0px 0px rgba(0, 0, 0, 0))" }}
        animate={{
          scale: 1.7,
          filter: "drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.63))",
        }}
        transition={{
          delay: delays[6],
          type: "spring",
          stiffness: 260,
          damping: 90,
        }}
        cx='685.5'
        cy='13.5'
        r='6.5'
        fill='#2F2F2F'
        stroke='#2F2F2F'
      />
      <motion.circle
        initial={{ scale: 1, filter: "drop-shadow(0px 0px 0px rgba(0, 0, 0, 0))" }}
        animate={{
          scale: 1.7,
          filter: "drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.63))",
        }}
        transition={{
          delay: delays[6],
          type: "spring",
          stiffness: 260,
          damping: 90,
        }}
        cx='730.5'
        cy='13.5'
        r='6.5'
        fill='#2F2F2F'
        stroke='#2F2F2F'
      />
      <circle cx='685.5' cy='57.5' r='6.5' fill='#2F2F2F' stroke='#2F2F2F' />
      <motion.circle
        initial={{ scale: 1, filter: "drop-shadow(0px 0px 0px rgba(0, 0, 0, 0))" }}
        animate={{
          scale: 1.7,
          filter: "drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.63))",
        }}
        transition={{
          delay: delays[6],
          type: "spring",
          stiffness: 260,
          damping: 90,
        }}
        cx='730.5'
        cy='57.5'
        r='6.5'
        fill='#2F2F2F'
        stroke='#2F2F2F'
      />
      <motion.circle
        initial={{ scale: 1, filter: "drop-shadow(0px 0px 0px rgba(0, 0, 0, 0))" }}
        animate={{
          scale: 1.7,
          filter: "drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.63))",
        }}
        transition={{
          delay: delays[6],
          type: "spring",
          stiffness: 260,
          damping: 90,
        }}
        cx='685.5'
        cy='101.5'
        r='6.5'
        fill='#2F2F2F'
        stroke='#2F2F2F'
      />
      <circle cx='730.5' cy='101.5' r='6.5' fill='#2F2F2F' stroke='#2F2F2F' />
      {/*  */}
      <motion.circle
        initial={{ scale: 1, filter: "drop-shadow(0px 0px 0px rgba(0, 0, 0, 0))" }}
        animate={{
          scale: 1.7,
          filter: "drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.63))",
        }}
        transition={{
          delay: delays[7],
          type: "spring",
          stiffness: 260,
          damping: 90,
        }}
        cx='797.5'
        cy='13.5'
        r='6.5'
        fill='#2F2F2F'
        stroke='#2F2F2F'
      />
      <circle cx='842.5' cy='13.5' r='6.5' fill='#2F2F2F' stroke='#2F2F2F' />
      <motion.circle
        initial={{ scale: 1, filter: "drop-shadow(0px 0px 0px rgba(0, 0, 0, 0))" }}
        animate={{
          scale: 1.7,
          filter: "drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.63))",
        }}
        transition={{
          delay: delays[7],
          type: "spring",
          stiffness: 260,
          damping: 90,
        }}
        cx='797.5'
        cy='57.5'
        r='6.5'
        fill='#2F2F2F'
        stroke='#2F2F2F'
      />
      <circle cx='842.5' cy='57.5' r='6.5' fill='#2F2F2F' stroke='#2F2F2F' />
      <motion.circle
        initial={{ scale: 1, filter: "drop-shadow(0px 0px 0px rgba(0, 0, 0, 0))" }}
        animate={{
          scale: 1.7,
          filter: "drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.63))",
        }}
        transition={{
          delay: delays[7],
          type: "spring",
          stiffness: 260,
          damping: 90,
        }}
        cx='797.5'
        cy='101.5'
        r='6.5'
        fill='#2F2F2F'
        stroke='#2F2F2F'
      />
      <circle cx='842.5' cy='101.5' r='6.5' fill='#2F2F2F' stroke='#2F2F2F' />
      {/*  */}
      <circle cx='909.5' cy='13.5' r='6.5' fill='#2F2F2F' stroke='#2F2F2F' />
      <motion.circle
        initial={{ scale: 1, filter: "drop-shadow(0px 0px 0px rgba(0, 0, 0, 0))" }}
        animate={{
          scale: 1.7,
          filter: "drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.63))",
        }}
        transition={{
          delay: delays[8],
          type: "spring",
          stiffness: 260,
          damping: 90,
        }}
        cx='954.5'
        cy='13.5'
        r='6.5'
        fill='#2F2F2F'
        stroke='#2F2F2F'
      />
      <motion.circle
        initial={{ scale: 1, filter: "drop-shadow(0px 0px 0px rgba(0, 0, 0, 0))" }}
        animate={{
          scale: 1.7,
          filter: "drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.63))",
        }}
        transition={{
          delay: delays[8],
          type: "spring",
          stiffness: 260,
          damping: 90,
        }}
        cx='909.5'
        cy='57.5'
        r='6.5'
        fill='#2F2F2F'
        stroke='#2F2F2F'
      />
      <circle cx='954.5' cy='57.5' r='6.5' fill='#2F2F2F' stroke='#2F2F2F' />
      <circle cx='909.5' cy='101.5' r='6.5' fill='#2F2F2F' stroke='#2F2F2F' />
      <circle cx='954.5' cy='101.5' r='6.5' fill='#2F2F2F' stroke='#2F2F2F' />
      {/*  */}
      <motion.circle
        initial={{ scale: 1, filter: "drop-shadow(0px 0px 0px rgba(0, 0, 0, 0))" }}
        animate={{
          scale: 1.7,
          filter: "drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.63))",
        }}
        transition={{
          delay: delays[9],
          type: "spring",
          stiffness: 260,
          damping: 90,
        }}
        cx='1021.5'
        cy='13.5'
        r='6.5'
        fill='#2F2F2F'
        stroke='#2F2F2F'
      />
      <motion.circle
        initial={{ scale: 1, filter: "drop-shadow(0px 0px 0px rgba(0, 0, 0, 0))" }}
        animate={{
          scale: 1.7,
          filter: "drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.63))",
        }}
        transition={{
          delay: delays[9],
          type: "spring",
          stiffness: 260,
          damping: 90,
        }}
        cx='1066.5'
        cy='13.5'
        r='6.5'
        fill='#2F2F2F'
        stroke='#2F2F2F'
      />
      <circle cx='1021.5' cy='57.5' r='6.5' fill='#2F2F2F' stroke='#2F2F2F' />
      <motion.circle
        initial={{ scale: 1, filter: "drop-shadow(0px 0px 0px rgba(0, 0, 0, 0))" }}
        animate={{
          scale: 1.7,
          filter: "drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.63))",
        }}
        transition={{
          delay: delays[9],
          type: "spring",
          stiffness: 260,
          damping: 90,
        }}
        cx='1066.5'
        cy='57.5'
        r='6.5'
        fill='#2F2F2F'
        stroke='#2F2F2F'
      />
      <motion.circle
        initial={{ scale: 1, filter: "drop-shadow(0px 0px 0px rgba(0, 0, 0, 0))" }}
        animate={{
          scale: 1.7,
          filter: "drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.63))",
        }}
        transition={{
          delay: delays[9],
          type: "spring",
          stiffness: 260,
          damping: 90,
        }}
        cx='1021.5'
        cy='101.5'
        r='6.5'
        fill='#2F2F2F'
        stroke='#2F2F2F'
      />
      <circle cx='1066.5' cy='101.5' r='6.5' fill='#2F2F2F' stroke='#2F2F2F' />
      {/*  */}
      <motion.circle
        initial={{ scale: 1, filter: "drop-shadow(0px 0px 0px rgba(0, 0, 0, 0))" }}
        animate={{
          scale: 1.7,
          filter: "drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.63))",
        }}
        transition={{
          delay: delays[10],
          type: "spring",
          stiffness: 260,
          damping: 90,
        }}
        cx='1133.5'
        cy='13.5'
        r='6.5'
        fill='#2F2F2F'
        stroke='#2F2F2F'
      />
      <circle cx='1178.5' cy='13.5' r='6.5' fill='#2F2F2F' stroke='#2F2F2F' />
      <circle cx='1133.5' cy='57.5' r='6.5' fill='#2F2F2F' stroke='#2F2F2F' />
      <motion.circle
        initial={{ scale: 1, filter: "drop-shadow(0px 0px 0px rgba(0, 0, 0, 0))" }}
        animate={{
          scale: 1.7,
          filter: "drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.63))",
        }}
        transition={{
          delay: delays[10],
          type: "spring",
          stiffness: 260,
          damping: 90,
        }}
        cx='1178.5'
        cy='57.5'
        r='6.5'
        fill='#2F2F2F'
        stroke='#2F2F2F'
      />
      <circle cx='1133.5' cy='101.5' r='6.5' fill='#2F2F2F' stroke='#2F2F2F' />
      <circle cx='1178.5' cy='101.5' r='6.5' fill='#2F2F2F' stroke='#2F2F2F' />
      {/*  */}
      <circle cx='1245.5' cy='13.5' r='6.5' fill='#2F2F2F' stroke='#2F2F2F' />
      <motion.circle
        initial={{ scale: 1, filter: "drop-shadow(0px 0px 0px rgba(0, 0, 0, 0))" }}
        animate={{
          scale: 1.7,
          filter: "drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.63))",
        }}
        transition={{
          delay: delays[11],
          type: "spring",
          stiffness: 260,
          damping: 90,
        }}
        cx='1290.5'
        cy='13.5'
        r='6.5'
        fill='#2F2F2F'
        stroke='#2F2F2F'
      />
      <motion.circle
        initial={{ scale: 1, filter: "drop-shadow(0px 0px 0px rgba(0, 0, 0, 0))" }}
        animate={{
          scale: 1.7,
          filter: "drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.63))",
        }}
        transition={{
          delay: delays[11],
          type: "spring",
          stiffness: 260,
          damping: 90,
        }}
        cx='1245.5'
        cy='57.5'
        r='6.5'
        fill='#2F2F2F'
        stroke='#2F2F2F'
      />
      <circle cx='1290.5' cy='57.5' r='6.5' fill='#2F2F2F' stroke='#2F2F2F' />
      <motion.circle
        initial={{ scale: 1, filter: "drop-shadow(0px 0px 0px rgba(0, 0, 0, 0))" }}
        animate={{
          scale: 1.7,
          filter: "drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.63))",
        }}
        transition={{
          delay: delays[11],
          type: "spring",
          stiffness: 260,
          damping: 90,
        }}
        cx='1245.5'
        cy='101.5'
        r='6.5'
        fill='#2F2F2F'
        stroke='#2F2F2F'
      />
      <circle cx='1290.5' cy='101.5' r='6.5' fill='#2F2F2F' stroke='#2F2F2F' />
      {/*  */}
    </svg>
  );
}
