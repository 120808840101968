import React from "react";
import { motion } from "framer-motion";
export default function LandingFrame() {
  const svgVariants = {
    initial: { opacity: 1 },
    animate: { opacity: 1 },
  };
  const pathVariants = {
    initial: { pathLength: 0 },
    animate: { pathLength: 1, transition: { duration: 1.5, ease: "easeInOut" } },
  };
  return (
    <motion.svg
      variants={svgVariants}
      initial='initial'
      animate='animate'
      style={{ width: "100%", zIndex: 10, }}
      height='950'
      viewBox='0 0 1320 761'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_1760_4024)'>
        <motion.path
          variants={pathVariants}
          d='M67.8057 5.21366L70.3255 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 12.7276L77.5642 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 20.2415L85.1145 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 27.4454L92.3533 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 34.9593L99.9036 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 42.4732L107.454 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 49.6771L114.693 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 57.191L122.243 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 64.7049L129.793 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 71.9088L134.201 6.15289'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 79.4227L134.201 13.3477'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 86.9366L134.201 20.8707'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 94.1405L134.201 28.3846'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.73145 300.819L2.98677 299.25'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.73145 308.333L6.76192 303.007'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.73145 315.537L10.5371 306.764'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.73145 323.051L14.3122 310.521'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.73145 330.564L17.7758 314.278'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.73145 337.768L21.551 318.044'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.73145 345.282L25.3261 321.482'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.73145 352.796L29.1013 325.239'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.73145 360L32.8856 328.996'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M5.19531 363.757L36.661 332.762'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M12.7461 363.757L40.1159 336.519'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M20.2959 363.757L43.8906 340.276'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M27.5352 363.757L47.6754 343.714'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M35.085 363.757L51.45 347.48'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M42.6357 363.757L55.2257 351.237'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M49.874 363.757L59.0003 354.994'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M57.4248 363.757L62.4644 358.751'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M64.9746 363.757L66.2391 362.198'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.73145 434.218L6.13884 429.832L67.8057 368.453'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.73145 441.422L13.3684 429.832L67.8057 375.657'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.73145 448.936L20.9279 429.832L67.8057 383.171'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.73145 456.45L28.1575 429.832L67.8057 390.375'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.73145 463.654L35.7169 429.832L67.8057 397.889'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.73145 471.168L43.2672 429.832L67.8057 405.412'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.73145 478.682L50.506 429.832L67.8057 412.607'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.73145 485.886L58.0563 429.832L67.8057 420.13'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.73145 493.399L65.6066 429.832L67.8057 427.644'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.73145 500.913L6.76192 495.597L67.8057 434.847'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.73145 508.117L14.0007 495.597L67.8057 442.361'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.73145 515.631L21.551 495.597L67.8057 449.875'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.73145 523.145L29.1013 495.597L67.8057 457.079'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.73145 530.349L36.34 495.597L67.8057 464.593'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.73145 537.863L43.8903 495.597L67.8057 472.107'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.73145 545.067L51.4498 495.597L67.8057 479.311'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.73145 552.581L58.6793 495.597L67.8057 486.825'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.73145 560.104L66.2388 495.597L67.8057 494.339'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.73145 634.003L8.02641 627.738L67.8057 568.238'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.73145 641.517L15.5767 627.738L67.8057 575.761'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.41992 649.031L22.8155 627.738L67.8057 582.956'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.41992 656.234L30.3658 627.738L67.8057 590.479'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.41992 663.748L37.9161 627.738L67.8057 597.992'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.41992 671.262L45.1548 627.738L67.8057 605.196'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.41992 678.466L52.7051 627.738L67.8057 612.71'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.41992 685.98L60.2554 627.738L67.8057 620.224'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.41992 693.503L67.4941 627.738L67.8057 627.428'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.73145 34.3297L2.04345 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M5.50684 37.777L9.28198 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M8.9707 41.534L16.8325 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M12.7461 45.2909L24.3831 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M16.5205 49.0479L31.6211 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M20.2959 52.8048L39.1716 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M24.0713 56.2518L46.4106 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M27.8467 60.0087L53.9612 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M31.3096 63.7657L61.5199 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M35.085 67.5226L67.806 34.9593'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M38.8604 71.2796L67.8062 42.4732'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M42.6357 75.0366L67.8064 49.6771'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M46.4102 78.4835L67.8057 57.191'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M50.1855 82.2404L67.806 64.7049'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M53.6494 85.9974L67.8062 71.9088'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M57.4248 89.7543L67.8065 79.4227'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M61.1992 93.5204L67.8057 86.9366'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M64.9746 96.9582L67.806 94.1405'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M2.36426 166.79L3.30804 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M135.777 34.0201L166.931 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M3.30762 165.851L67.8058 101.654'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M6.13965 170.547L10.8586 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M143.327 34.0201L174.481 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M10.8584 165.851L67.8063 109.177'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M9.91406 174.304L18.0966 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M150.878 34.0201L181.721 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M18.0967 165.851L67.8058 116.372'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M13.3691 178.061L25.6475 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M158.116 34.0201L189.27 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M25.6475 165.851L67.8063 123.895'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M17.1533 181.508L32.8862 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M165.667 34.0201L196.821 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M32.8857 165.851L67.8058 131.409'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M20.9287 185.265L40.4367 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M200.284 6.77298L204.371 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M40.4365 165.851L67.8063 138.613'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M172.905 34.0201L200.284 6.77298'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M24.7031 189.022L47.9862 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M200.284 13.9769L211.61 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M47.9863 165.851L67.8058 146.127'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M180.456 34.02L200.285 13.9768'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M28.4785 192.779L55.2252 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M200.284 21.4908L219.16 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M55.2256 165.851L67.8064 153.641'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M188.006 34.0201L200.284 21.4908'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M32.2539 196.545L62.7758 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M200.284 29.0047L226.71 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M62.7754 165.851L67.8059 160.845'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M195.245 34.0201L200.285 29.0047'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M35.7168 199.983L67.8055 168.359'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M202.795 34.0201L233.949 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M70.3262 165.851L134.201 102.284'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M39.4922 203.74L67.8058 175.873'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M210.354 34.0201L241.499 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M77.5645 165.851L134.201 109.798'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M43.2676 207.497L67.806 183.076'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M217.584 34.0201L249.05 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M85.1152 165.851L134.201 117.001'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M47.043 211.263L67.8063 190.59'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M225.144 34.0201L256.289 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M92.665 165.851L134.201 124.515'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M50.8174 215.02L67.8055 197.794'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M232.693 34.0201L263.838 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M99.9043 165.851L134.201 132.029'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M54.5928 218.458L67.8058 205.308'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M266.67 7.40217L270.766 3.33517'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M107.454 165.851L134.201 139.233'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M239.924 34.02L266.671 7.40216'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M58.0566 222.215L67.806 212.822'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M266.67 14.9161L278.005 3.33517'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M115.005 165.851L134.201 146.747'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M247.482 34.02L266.67 14.9161'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M61.832 225.981L67.8063 220.026'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M266.67 22.12L285.555 3.33517'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M122.243 165.851L134.201 154.27'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M255.033 34.02L266.67 22.12'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M65.6064 229.738L67.8056 227.54'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M266.67 29.6339L293.105 3.33517'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M129.794 165.851L134.201 161.465'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M262.271 34.02L266.67 29.6339'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M70.958 231.926L134.201 168.988'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M269.822 34.02L300.344 3.33517'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M78.5088 231.926L134.201 176.502'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M277.372 34.02L307.894 3.33517'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M85.7471 231.926L134.201 183.706'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M284.611 34.02L315.445 3.33517'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M93.2979 231.926L134.201 191.22'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M292.161 34.02L322.683 3.33517'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M100.536 231.926L134.201 198.734'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M299.4 34.02L330.234 3.33517'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M108.087 231.926L134.201 205.937'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M338.104 3.01602L333.065 8.03138L306.95 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M115.637 231.926L134.201 213.451'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M345.344 3.01602L333.065 15.5453L314.501 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M122.876 231.926L134.201 220.965'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M352.893 3.01602L333.065 22.7492L321.739 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M130.426 231.926L134.201 228.169'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M360.444 3.01602L333.065 30.2631L329.29 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 301.758L71.5808 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M336.84 34.0201L367.682 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M71.5811 297.682L134.201 235.683'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 308.962L79.1403 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M344.079 34.0201L375.233 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M79.1406 297.682L134.201 243.197'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 316.476L86.3699 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M351.629 34.0201L382.783 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M86.3701 297.682L134.201 250.401'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 323.99L93.9293 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M359.18 34.0201L390.022 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M93.9297 297.682L134.201 257.915'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 331.194L101.48 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M366.418 34.0201L397.572 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M101.479 297.682L134.2 265.429'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 338.708L108.718 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M399.148 8.65144L404.49 3.6452'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M108.719 297.682L134.201 272.633'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M373.969 34.0201L399.149 8.65146'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 346.222L116.269 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M399.148 16.1654L411.729 3.6452'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M116.269 297.682L133.889 280.146'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M381.528 34.02L399.149 16.1654'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 353.425L123.819 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M399.148 23.6884L419.279 3.6452'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M123.819 297.682L133.889 287.35'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M388.758 34.0201L399.149 23.6884'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 360.939L131.058 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M399.148 30.8832L426.839 3.6452'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M131.058 297.682L133.889 294.864'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M396.317 34.0201L399.149 30.8832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M403.867 34.02L434.068 3.6452'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M411.106 34.02L441.628 3.6452'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M418.656 34.02L449.178 3.6452'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M425.896 34.02L456.417 3.6452'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M433.445 34.02L463.967 3.6452'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M440.996 34.02L465.544 9.28064L471.206 3.6452'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M448.234 34.02L465.543 16.7946L478.756 3.6452'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M455.785 34.02L465.544 24.3085L486.307 3.6452'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M463.335 34.02L465.543 31.5124L493.545 3.6452'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M470.574 34.02L501.096 3.6452'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M478.124 34.02L508.646 3.6452'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M485.675 34.02L515.885 3.6452'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M492.913 34.02L523.435 3.6452'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M500.464 34.02L530.986 3.6452'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M508.014 34.0201L531.929 10.2199L538.856 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M515.253 34.0201L531.93 17.4238L546.407 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M522.803 34.0201L531.929 24.9377L553.645 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M530.362 34.0201L531.929 32.4516L561.196 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M537.592 34.0201L568.746 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M545.151 34.0201L575.985 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M552.381 34.0201L583.535 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M559.94 34.0201L591.085 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M567.49 34.0201L598.012 3.33518L598.324 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M574.729 34.0201L598.013 10.8491L605.874 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M582.279 34.0201L598.012 18.053L613.424 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M589.83 34.0201L598.013 25.5669L620.663 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M597.068 34.0201L598.012 33.0808L628.213 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M604.619 34.0201L635.773 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M612.169 34.0201L643.002 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M619.408 34.0201L650.562 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M626.958 34.0201L657.791 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M634.509 34.02L664.408 3.95526L665.031 3.33517'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M641.747 34.02L664.407 11.4692L672.581 3.33517'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M649.298 34.02L664.408 18.9922L679.82 3.33517'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M656.848 34.02L664.407 26.187L687.37 3.33517'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M664.087 34.02L664.408 33.71L694.929 3.33517'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M671.637 34.02L702.159 3.33517'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M678.876 34.02L709.719 3.33517'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M686.426 34.02L717.268 3.33517'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M693.985 34.02L724.507 3.33517'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M701.215 34.02L730.793 4.58445L732.057 3.33517'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M708.774 34.02L730.793 12.0984L739.608 3.33517'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M716.325 34.02L730.794 19.6123L746.847 3.33517'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M723.563 34.02L730.793 26.8162L754.397 3.33517'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M731.114 34.02L761.948 3.33517'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M738.664 34.02L769.186 3.33517'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M745.903 34.02L776.737 3.33517'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M753.453 34.02L783.975 3.33517'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M761.004 34.02L791.526 3.33517'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M768.242 34.0201L796.876 5.5237L799.396 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M775.793 34.0201L796.877 12.7276L806.626 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M783.343 34.0201L796.876 20.2415L814.185 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M790.582 34.0201L796.877 27.7554L821.736 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M798.132 34.0201L828.974 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M805.371 34.0201L836.525 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M812.921 34.0201L844.075 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M820.471 34.0201L851.313 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M827.71 34.0201L858.864 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M835.26 34.0201L863.271 6.1529L866.414 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M842.819 34.0201L863.271 13.3477L873.653 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M850.049 34.0201L863.271 20.8707L881.203 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M857.608 34.0201L863.271 28.3846L888.442 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M865.159 34.0201L895.993 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M872.397 34.0201L903.542 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M879.948 34.0201L910.782 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M887.498 34.0201L918.331 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M894.737 34.0201L925.882 3.01602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M902.287 34.02L929.657 6.77296L933.441 2.70596'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M909.838 34.02L929.657 14.2869L940.992 2.70596'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M917.076 34.02L929.657 21.4908L948.542 2.70596'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M924.627 34.02L929.657 29.0047L955.781 2.70596'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M932.177 34.02L963.331 2.70596'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M939.416 34.02L970.57 2.70596'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M946.966 34.02L978.12 2.70596'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M954.205 34.02L985.671 2.70596'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M961.755 34.02L992.909 2.70596'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M969.306 34.02L995.741 7.40216'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M976.544 34.02L995.74 14.9161'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M984.095 34.02L995.741 22.12'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M991.654 34.02L995.741 29.6339'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1034.45 6.15288L1037.59 2.70596'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1037.91 9.90984L1045.14 2.70596'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1041.68 13.6668L1052.7 2.70596'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1045.46 17.4238L1059.93 2.70596'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1049.23 21.1807L1062.14 8.03137L1066.85 3.33517'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1053.01 24.6276L1062.14 15.5453L1074.4 3.33517'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1056.47 28.3846L1062.14 23.0592L1081.64 3.33517'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1060.25 32.1416L1062.14 30.2631L1089.19 3.33517'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1065.91 34.02L1096.74 3.33517'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1073.46 34.02L1103.98 3.33517'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1080.7 34.02L1111.53 3.33517'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1088.25 34.02L1119.08 3.33517'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1095.8 34.02L1126.32 3.33517'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1103.04 34.02L1128.53 8.65145L1134.82 2.39594'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1110.59 34.02L1128.53 16.1654L1142.38 2.39594'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1118.14 34.02L1128.53 23.6884L1149.61 2.39594'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1125.38 34.02L1128.53 30.8832L1157.17 2.39594'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1132.93 34.02L1164.72 2.39594'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1140.49 34.02L1171.95 2.39594'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1147.72 34.02L1179.5 2.39594'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1155.28 34.02L1187.05 2.39594'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1162.83 34.02L1194.29 2.39594'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1170.06 34.0201L1194.6 9.5907'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1177.62 34.02L1194.6 16.7946'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1185.17 34.02L1194.6 24.3085'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1192.41 34.02L1194.6 31.8315'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1199.96 34.02L1231.42 2.39594'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1207.19 34.02L1238.97 2.39594'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1214.75 34.02L1246.52 2.39594'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1222.29 34.02L1253.76 2.39594'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1229.53 34.02L1261 2.70598L1261.31 2.39594'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1237.08 34.02L1261 10.2199L1268.86 2.39594'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1244.63 34.02L1261 17.7338L1276.1 2.39594'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1251.87 34.02L1261 24.9377L1283.65 2.39594'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1259.42 34.0201L1261 32.4516L1289.63 3.95526'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1266.97 34.02L1293.1 8.03137'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1274.21 34.02L1296.24 12.0984'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1281.76 34.02L1299.7 16.1654'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1289.31 34.02L1302.85 20.2415'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1296.55 34.02L1306.31 24.3085'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1304.1 34.0201L1309.45 28.3846'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1311.66 34.02L1312.92 32.7708'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.41992 234.434L3.93058 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.41992 241.638L11.4809 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.41992 249.152L18.7196 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.41992 256.665L26.2699 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.41992 263.869L33.8294 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.41992 271.383L41.059 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.41992 278.897L48.6184 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.41992 286.101L56.1687 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1.41992 293.615L63.4074 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M136.409 99.7759L200.284 36.2086'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M201.54 35.2693L202.795 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M143.96 99.7759L200.285 43.7225'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M205.314 38.7163L210.354 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M151.51 99.7759L200.284 51.2455'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M209.09 42.4732L217.584 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M158.749 99.7759L200.285 58.4403'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M212.865 46.2302L225.144 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M166.299 99.7759L200.284 65.9633'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M216.329 49.9871L232.694 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M173.85 99.7759L200.285 73.4772'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M220.104 53.7441L239.923 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M181.088 99.776L200.284 80.6811'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M223.879 57.501L247.483 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M188.639 99.776L200.285 88.195'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M227.654 60.948L255.033 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M196.188 99.776L200.284 95.709'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M231.43 64.7049L262.272 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M235.204 68.4619L266.67 37.1478'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M268.246 35.5885L269.822 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M238.668 72.2188L266.67 44.3517'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M272.021 39.3454L277.373 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M242.443 75.9758L266.67 51.8656'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M275.797 42.7832L284.612 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M246.219 79.7419L266.671 59.3795'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M279.26 46.5493L292.161 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M249.993 83.1797L266.67 66.5834'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M283.035 50.3063L299.4 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M253.769 86.9366L266.67 74.0973'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M286.811 54.0632L306.951 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M257.553 90.6936L266.67 81.6204'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M290.586 57.8202L314.501 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M261.008 94.4597L266.671 88.8151'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M294.36 61.2671L321.739 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M264.782 98.2166L266.67 96.3381'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M298.136 65.0241L329.29 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M301.6 68.781L333.065 37.777'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M305.375 72.538L333.066 44.9809'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M309.149 76.295L333.065 52.4948'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M312.925 80.0519L333.065 60.0087'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M316.709 83.4988L333.065 67.2126'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M320.164 87.2558L333.066 74.7265'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M323.938 91.0127L333.065 82.2404'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M327.714 94.7697L333.065 89.4443'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M331.498 98.5267L333.065 96.9582'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M337.473 99.7759L399.149 38.4062'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M401.348 36.2086L403.867 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M345.022 99.7759L399.148 45.9201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M405.123 39.9655L411.106 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M352.262 99.7759L399.149 53.124'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M408.897 43.7225L418.656 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M359.812 99.7759L399.148 60.6379'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M412.673 47.1694L425.895 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M367.051 99.776L399.149 68.1519'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M416.448 50.9264L433.446 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M374.601 99.7759L399.148 75.3557'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M420.232 54.6833L440.996 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M382.151 99.7759L399.149 82.8696'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M423.687 58.4403L448.234 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M389.39 99.7759L399.148 90.3835'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M427.462 62.2064L455.785 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M396.94 99.7759L399.149 97.5874'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M431.237 65.6442L463.335 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 434.847L70.3255 432.34'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M435.013 69.4011L465.544 39.0263'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 442.361L74.1006 436.097'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M438.797 73.1581L465.544 46.5493'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 449.875L77.8758 439.854'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M442.251 76.9241L465.543 53.7441'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 457.079L81.6509 443.611'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M446.026 80.6811L465.544 61.2671'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 464.593L85.1145 447.058'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M449.802 84.4381L465.544 68.781'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 472.107L88.8897 450.815'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M453.586 87.8759L465.544 75.9758'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 479.311L92.6648 454.572'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M457.36 91.642L465.543 83.4988'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 486.825L96.44 458.328'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M461.136 95.3989L465.543 91.0128'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 494.339L100.215 462.085'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M464.591 99.1559L465.544 98.2166'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 501.543L73.7891 495.597L103.679 465.532'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 509.056L81.0278 495.597L107.454 469.289'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 516.57L88.5781 495.597L111.229 473.046'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 523.774L95.8169 495.597L115.004 476.803'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 531.288L103.367 495.597L118.779 480.56'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 538.811L110.917 495.597L122.564 484.317'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 546.006L118.156 495.597L126.018 487.764'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 553.52L125.707 495.597L129.793 491.521'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 560.724L133.257 495.597L133.568 495.278'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 568.238L70.9577 565.11'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 575.761L74.7329 568.557'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 582.956L78.508 572.314'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 590.479L82.2832 576.071'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 597.993L85.7468 579.828'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 605.196L89.5219 583.585'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 612.71L93.2971 587.342'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 620.224L97.0722 590.789'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 627.428L100.847 594.546'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 634.942L75.0444 627.738L104.623 598.303'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 642.456L82.5947 627.738L108.086 602.059'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 649.66L89.8335 627.738L111.861 605.816'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 657.174L97.3838 627.738L115.636 609.573'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 664.688L104.623 627.738L119.412 613.02'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 671.891L112.173 627.738L123.187 616.777'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 679.405L119.723 627.738L126.962 620.534'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 686.919L126.962 627.738L130.425 624.291'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M69.0703 759.569L133.889 694.752'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M76.3096 759.569L133.89 702.266'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M83.8594 759.569L133.889 709.78'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M91.4102 759.569L133.89 716.984'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M98.6484 759.569L133.889 724.498'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M106.199 759.569L133.89 732.012'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M113.438 759.569L133.889 739.216'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M120.988 759.569L133.89 746.73'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M128.538 759.569L133.889 754.253'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M135.466 101.034L136.41 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M138.92 104.791L143.96 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M142.695 108.548L151.51 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M146.47 112.305L158.748 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M150.255 116.062L166.299 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M154.029 119.509L173.849 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M157.484 123.266L181.088 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M161.259 127.023L188.638 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M165.034 130.78L196.188 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M168.818 134.537L200.284 102.913L201.851 101.344'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M172.594 138.294L200.284 110.427L205.626 105.101'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M176.369 141.741L200.285 117.941L209.402 108.858'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M179.823 145.498L200.284 125.145L213.186 112.615'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M183.607 149.255L200.284 132.658L216.961 116.372'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M187.383 153.012L200.284 140.172L220.415 120.129'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M191.158 156.769L200.285 147.376L224.191 123.576'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M194.934 160.216L200.285 154.89L227.975 127.333'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M198.396 163.973L200.284 162.404L231.75 131.09'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M204.06 165.851L235.525 134.847'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M266.67 103.852L270.454 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M211.609 165.851L238.979 138.613'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M266.67 111.056L278.005 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M219.16 165.851L242.764 142.051'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M266.67 118.57L285.243 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M226.398 165.851L246.539 145.808'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M266.67 125.774L292.794 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M233.949 165.851L250.314 149.565'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M266.67 133.288L300.344 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M241.499 165.851L254.089 153.331'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M266.67 140.802L307.583 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M248.738 165.851L257.865 157.088'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M266.67 148.005L315.133 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M256.288 165.851L261.328 160.845'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M266.67 155.519L322.683 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M263.839 165.851L265.103 164.283'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M266.67 163.033L329.922 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M333.065 104.472L335.274 102.284'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M333.065 111.676L339.049 105.731'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M333.065 119.19L342.503 109.487'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M333.065 126.713L346.288 113.244'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M333.065 133.908L350.063 117.001'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M333.065 141.431L353.838 120.758'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M333.065 148.945L357.613 124.205'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M333.065 156.149L361.388 127.962'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M333.065 163.663L364.852 131.719'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M338.104 165.851L368.626 135.476'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M468.054 36.5277L470.574 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M345.655 165.851L372.402 139.233'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M471.829 40.2847L478.124 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M352.894 165.851L376.177 142.99'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M475.604 44.0416L485.675 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M360.444 165.851L379.952 146.437'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M479.38 47.4886L492.914 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M367.994 165.851L383.415 150.194'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M482.843 51.2455L500.463 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M375.233 165.851L387.191 153.951'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M486.618 55.0025L508.014 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M382.783 165.851L390.966 157.708'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M490.394 58.7594L515.253 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M390.334 165.851L394.741 161.465'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M494.169 62.5164L522.803 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M397.572 165.851L398.516 165.222'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M497.953 66.2734L530.363 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M405.123 165.851L465.544 105.731'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M501.728 69.7203L531.929 39.6555'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M412.673 165.851L465.543 113.244'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M505.183 73.4772L531.929 47.1694'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M419.912 165.851L465.544 120.448'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M508.957 77.2342L531.929 54.6833'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M427.462 165.851L465.543 127.962'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M512.742 80.9912L531.929 61.8872'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M434.701 165.851L465.544 135.476'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M516.517 84.7481L531.929 69.4011'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M442.251 165.851L465.543 142.68'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M520.292 88.195L531.929 76.9241'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M449.802 165.851L465.544 150.194'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M524.067 91.952L531.929 84.1189'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M457.04 165.851L465.543 157.708'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M527.531 95.7089L531.93 91.6419'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M464.591 165.851L465.544 164.912'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M531.306 99.4655L531.93 99.1559'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M472.149 165.851L531.929 106.36'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M535.081 103.223L538.224 99.7759L598.012 40.2847L601.476 37.1478'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M479.38 165.851L531.93 113.874'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M538.856 106.98L545.774 99.7759L598.013 47.7986L605.251 40.9048'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M486.938 165.851L531.929 121.078'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M542.632 110.427L553.325 99.7759L598.013 55.3125L608.706 44.6617'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M494.489 165.851L531.929 128.591'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M546.095 114.184L560.563 99.7759L598.012 62.5164L612.48 48.4278'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M501.728 165.851L531.929 136.105'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M549.87 117.941L568.114 99.7759L598.012 70.0303L616.265 51.8656'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M509.278 165.851L531.929 143.309'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M553.646 121.698L575.673 99.7759L598.013 77.5442L620.04 55.6226'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M516.828 165.851L531.929 150.823'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M557.421 125.455L582.903 99.7759L598.013 84.7481L623.816 59.3795'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M524.067 165.851L531.929 158.337'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M561.195 129.212L590.462 99.7759L598.012 92.262L627.27 63.1456'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M531.617 165.851L531.929 165.541'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M631.054 66.9026L598.012 99.7759L564.971 132.658'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M599.268 99.7759L598.956 99.1559L598.324 98.5267H597.692L597.069 99.1559L596.748 99.7759L597.069 100.405L597.692 101.034H598.324L598.956 100.405L599.268 99.7759Z'
          fill='black'
          stroke='#D6D6D6'
          strokeMiterlimit='10'
        />
        <motion.path
          variants={pathVariants}
          d='M568.435 136.415L598.013 106.98'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M667.862 37.467L671.637 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M601.788 103.533L605.252 99.7759L634.83 70.6595'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M572.21 140.172L598.013 114.494'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M671.637 41.2239L678.875 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M605.562 107.299L612.801 99.776L638.604 74.0973'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M575.984 143.929L598.012 122.017'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M675.412 44.9809L686.426 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M609.026 111.056L620.04 99.776L642.38 77.8634'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M579.76 147.686L598.012 129.212'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M679.196 48.7378L693.985 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M612.802 114.494L627.591 99.776L646.155 81.6204'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M583.535 151.452L598.013 136.735'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M682.972 52.1848L701.215 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M616.576 118.251L635.14 99.7759L649.618 85.3773'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M587.311 154.89L598.013 144.248'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M686.747 55.9417L708.775 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M620.352 122.017L642.379 99.776L653.393 89.1343'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M590.773 158.647L598.012 151.452'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M690.21 59.6987L716.324 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M624.127 125.774L649.93 99.7759L657.169 92.5812'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M594.549 162.404L598.012 158.966'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M693.985 63.4556L723.563 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M627.591 129.531L657.48 99.7759L660.944 96.3381'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M631.365 132.969L664.407 100.405'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.793 34.0201H731.114'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M664.719 100.095V99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M697.761 67.2126L730.793 34.3301'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M635.141 136.735L664.407 107.609'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M734.568 37.777L738.664 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M668.183 103.852L672.269 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M701.536 70.9695L730.794 41.844'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M638.916 140.492L664.407 115.123'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M738.353 41.534L745.903 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M671.958 107.609L679.82 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M705.311 74.4165L730.793 49.0479'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M642.691 144.249L664.408 122.637'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M742.128 45.2909L753.453 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M675.732 111.366L687.058 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M708.774 78.1734L730.793 56.5618'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M646.467 148.005L664.408 129.841'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M745.903 49.0479L761.004 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M679.508 114.813L694.608 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M712.55 81.9304L730.793 64.0848'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M649.93 151.762L664.407 137.355'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M749.357 52.8048L768.242 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M683.283 118.57L702.159 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M716.325 85.6874L730.794 71.2796'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M653.705 155.209L664.407 144.869'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M753.142 56.2518L775.792 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M687.059 122.327L709.398 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M720.1 89.4443L730.793 78.8026'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M657.48 158.966L664.408 152.072'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M756.917 60.0087L783.343 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M690.521 126.084L716.948 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M723.875 93.2013L730.793 86.3166'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M661.256 162.723L664.408 159.586'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M760.692 63.7657L790.582 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M694.297 129.841L724.507 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M727.65 96.6482L730.793 93.5204'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M698.072 133.598L730.793 101.034'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M764.467 67.5226L796.876 34.9593'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.793 101.034L731.737 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M701.848 137.045L730.793 108.548'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M768.242 71.2796L796.876 42.4732'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.793 108.548L739.296 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M705.622 140.802L730.793 115.752'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M771.706 75.0366L796.877 49.9871'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.793 115.752L746.526 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M709.397 144.559L730.793 123.266'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M775.481 78.4835L796.877 57.191'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.793 123.266L754.085 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M712.861 148.315L730.793 130.78'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M779.256 82.2404L796.876 64.7049'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.793 130.78L761.636 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M716.637 152.072L730.794 137.984'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M783.031 85.9974L796.876 72.2188'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.793 137.984L768.874 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M720.411 155.829L730.793 145.498'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M786.807 89.7543L796.877 79.4227'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.793 145.498L776.425 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M724.187 159.276L730.793 153.012'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M790.582 93.5204L796.877 86.9366'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.793 153.012L783.975 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M727.962 163.033L730.793 160.216'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M794.045 96.9582L796.876 94.4597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.793 160.216L791.214 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M798.764 99.776L863.271 35.5885'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M806.314 99.7759L863.272 43.1024'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M813.553 99.776L863.271 50.6163'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M821.104 99.7759L863.272 57.8202'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M828.653 99.7759L863.271 65.3341'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M835.893 99.7759L863.272 72.848'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M843.442 99.7759L863.271 80.0519'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M850.993 99.7759L863.272 87.5658'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M858.231 99.7759L863.271 95.0797'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M799.396 165.851L863.272 102.284'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M930.922 35.2693L932.177 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M863.271 102.284L864.527 101.034'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M865.782 99.7759L929.657 36.5277'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M806.946 165.851L863.271 109.798'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M934.386 39.0263L939.416 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M863.271 109.798L868.302 104.791'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M873.021 99.7759L929.657 43.7225'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M814.186 165.851L863.272 117.311'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M938.16 42.4732L946.966 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M863.271 117.311L872.077 108.548'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M880.571 99.7759L929.657 51.2455'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M821.735 165.851L863.271 124.515'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M941.936 46.2302L954.205 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M863.271 124.515L875.541 112.305'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M888.131 99.7759L929.657 58.7594'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M829.286 165.851L863.272 132.029'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M945.711 49.9871L961.755 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M863.271 132.029L879.316 116.062'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M895.36 99.7759L929.657 65.9633'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M836.524 165.851L863.271 139.552'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M949.486 53.7441L969.306 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M863.271 139.552L883.091 119.819'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M902.92 99.7759L929.657 73.4772'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M844.075 165.851L863.272 146.747'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M953.261 57.501L976.544 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M863.271 146.747L886.866 123.266'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M910.47 99.7759L929.657 80.9911'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M851.625 165.851L863.271 154.27'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M956.725 60.948L984.094 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M863.271 154.27L890.641 127.023'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M917.709 99.776L929.658 88.195'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M858.864 165.851L863.272 161.784'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M960.5 64.7049L991.654 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M863.271 161.784L894.105 130.78'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M925.259 99.776L929.657 95.709'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M866.414 165.851L897.88 134.537'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M997.316 35.5885L998.883 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M929.657 102.913L932.809 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M964.275 68.4619L995.741 37.1478'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M873.965 165.851L901.655 138.294'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1001.09 39.3454L1006.44 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M929.657 110.427L940.048 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M968.05 72.2188L995.74 44.3517'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M881.203 165.851L905.43 141.741'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1004.87 42.7832L1013.99 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M929.657 117.941L947.598 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M971.825 75.9758L995.741 51.8656'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M888.754 165.851L909.206 145.498'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1008.64 46.5493L1021.23 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M929.657 125.145L955.149 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M975.289 79.7419L995.741 59.3795'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M896.304 165.851L912.98 149.255'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1012.42 50.3063L1028.78 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M929.657 132.658L962.387 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M979.064 83.1797L995.741 66.5834'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M903.543 165.851L916.444 153.012'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1015.88 54.0632L1036.33 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M929.657 140.172L969.938 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M982.839 86.9366L995.74 74.0973'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M911.093 165.851L920.219 156.769'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1019.66 57.8202L1043.57 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M929.657 147.376L977.488 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M986.614 90.6936L995.741 81.6204'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M918.652 165.851L923.994 160.526'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1023.43 61.5772L1051.12 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M929.657 154.89L984.727 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M990.39 94.4597L995.741 88.8151'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M925.882 165.851L927.769 163.973'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1027.21 65.0241L1058.67 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M929.657 162.404L992.277 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M994.165 98.2166L995.741 96.3381'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M933.441 165.851L995.74 103.852'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1030.98 68.781L1062.14 37.777'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M995.74 103.852L999.515 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M940.671 165.851L995.74 111.056'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1034.76 72.538L1062.14 45.2909'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M995.74 111.056L1007.07 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M948.23 165.851L995.74 118.57'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1038.22 76.295L1062.14 52.4948'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M995.74 118.57L1014.62 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M955.781 165.851L995.741 126.084'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1042 80.0519L1062.14 60.0087'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M995.74 126.084L1021.85 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M963.02 165.851L995.74 133.288'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1045.77 83.8089L1062.14 67.5226'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M995.74 133.288L1029.4 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M970.57 165.851L995.741 140.802'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1049.55 87.2558L1062.14 74.7265'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M995.74 140.802L1036.96 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M978.12 165.851L995.741 148.315'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1053.32 91.0127L1062.14 82.2404'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M995.74 148.315L1044.19 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M985.359 165.851L995.741 155.519'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1057.1 94.7697L1062.14 89.7543'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M995.74 155.519L1051.75 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M992.909 165.851L995.741 163.033'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1060.56 98.5267L1062.14 96.9582'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M995.74 163.033L1059.3 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1000.46 165.851L1062.14 104.472'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1062.14 104.472L1066.54 99.776L1128.53 38.4062L1132.93 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1007.7 165.851L1062.14 111.676'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1062.14 111.676L1074.09 99.776L1128.53 45.9201L1140.49 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1015.25 165.851L1062.14 119.19'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1062.14 119.19L1081.64 99.776L1128.53 53.124L1147.72 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1022.8 165.851L1062.14 126.713'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1062.14 126.713L1088.88 99.776L1128.53 60.6379L1155.28 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1030.04 165.851L1062.14 133.908'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1062.14 133.908L1096.43 99.776L1128.53 68.1518L1162.83 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1037.59 165.851L1062.14 141.431'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1062.14 141.431L1103.98 99.776L1128.53 75.3557L1170.06 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1045.14 165.851L1062.14 148.945'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1062.14 148.945L1111.22 99.776L1128.53 82.8696L1177.62 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1052.38 165.851L1062.14 156.149'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1062.14 156.149L1118.77 99.776L1128.53 90.3836L1185.17 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1059.93 165.851L1062.14 163.662'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1062.14 163.662L1126.32 99.776L1128.53 97.5874L1192.41 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1067.17 165.851L1128.53 105.101'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.6 39.0263L1197.44 36.5277'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1131.04 102.594L1133.56 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1074.72 165.851L1128.53 112.615'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.6 46.5493L1200.9 40.2847'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1134.82 106.36L1141.11 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1082.28 165.851L1128.53 119.819'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.6 54.0632L1204.67 44.0416'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1138.59 109.798L1148.35 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1089.51 165.851L1128.53 127.333'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.6 61.2671L1208.45 47.7986'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1142.05 113.554L1155.9 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1097.06 165.851L1128.53 134.847'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.6 68.781L1212.22 51.2455'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1145.83 117.311L1163.45 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1104.62 165.851L1128.53 142.051'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.6 76.2949L1216 55.0025'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1149.61 121.078L1170.69 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1111.85 165.851L1128.53 149.565'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.6 83.4988L1219.78 58.7594'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1153.38 124.835L1178.24 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1119.4 165.851L1128.53 157.088'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.6 91.0127L1223.24 62.5164'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1157.17 128.591L1185.79 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1126.96 165.851L1128.53 164.283'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.6 98.5267L1227.01 66.2733'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1160.62 132.029L1193.03 99.7759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1164.39 135.795L1194.6 105.731'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 39.6555L1266.97 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1200.59 99.7759L1230.79 69.7203'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1168.17 139.552L1194.61 113.244'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 47.1694L1274.21 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1208.14 99.7759L1234.56 73.4772'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1171.95 143.309L1194.6 120.448'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 54.6833L1281.76 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1215.38 99.7759L1238.34 77.2342'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1175.73 147.066L1194.6 127.962'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 61.8872L1289.31 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1222.93 99.7759L1241.8 80.9911'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1179.5 150.513L1194.6 135.476'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 69.4011L1296.55 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1230.48 99.7759L1245.58 84.7481'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1182.96 154.27L1194.61 142.68'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 76.9242L1304.1 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1237.72 99.7759L1249.35 88.5051'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1186.74 158.027L1194.6 150.194'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 84.1189L1311.66 34.0201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1245.27 99.7759L1253.13 91.952'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1190.52 161.784L1194.6 157.708'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 91.6419L1294.04 58.7594'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1252.82 99.776L1256.9 95.709'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.29 165.54L1194.6 164.912'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 99.1559L1264.46 95.709'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1260.06 99.7755L1260.68 99.4659'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1201.21 165.851L1261 106.36'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1208.76 165.851L1261 113.874'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1216 165.851L1261 121.388'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1223.55 165.851L1261 128.591'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1231.11 165.851L1261 136.105'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1238.34 165.851L1261 143.619'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1245.9 165.851L1261 150.823'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1253.45 165.851L1261 158.337'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1260.68 165.851H1261'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M134.201 168.988L137.353 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M134.201 176.502L144.583 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M134.201 183.706L152.142 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M134.201 191.22L159.372 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M134.201 198.734L166.931 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M134.201 205.937L174.482 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M134.201 213.451L181.72 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M134.201 220.965L189.271 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M134.201 228.169L196.821 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M200.284 169.608L202.172 167.729'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M200.284 177.122L205.947 171.486'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M200.284 184.645L209.722 175.243'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M200.284 191.84L213.497 179'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M200.284 199.363L217.272 182.447'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M200.284 206.557L220.736 186.204'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M200.284 214.081L224.511 189.961'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M200.284 221.594L228.286 193.718'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M200.284 228.798L232.061 197.484'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M205.003 231.926L235.836 201.241'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M268.878 168.049L271.077 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M212.242 231.926L239.612 204.679'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M272.653 171.806L278.628 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M219.792 231.926L243.075 208.436'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M276.429 175.563L285.867 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M227.031 231.926L246.851 212.202'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M280.204 179.32L293.417 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M234.581 231.926L250.625 215.959'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M283.667 183.076L300.976 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M242.132 231.926L254.401 219.716'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M287.442 186.523L308.206 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M249.37 231.926L258.176 223.473'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M291.218 190.28L315.765 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M256.921 231.926L261.64 226.92'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M294.993 194.037L323.316 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M264.471 231.926L265.414 230.677'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M298.768 197.794L330.554 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M302.231 201.551L333.065 171.176'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M335.585 168.359L338.105 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M306.007 204.998L333.065 178.38'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M339.36 172.116L345.655 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M309.782 208.755L333.065 185.894'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M342.823 175.873L352.893 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M313.557 212.512L333.065 193.098'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M346.599 179.63L360.444 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M317.332 216.269L333.065 200.612'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M350.374 183.387L367.994 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M321.107 220.026L333.065 208.126'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M354.149 186.833L375.233 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M324.571 223.783L333.065 215.33'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M357.924 190.59L382.783 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M328.346 227.23L333.065 222.844'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M361.699 194.347L390.334 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M332.121 230.987L333.065 230.358'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M365.163 198.104L397.573 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M368.938 201.861L399.149 171.806L402.292 168.669'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M372.713 205.618L399.148 179L405.755 172.426'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M376.488 209.065L399.148 186.523L409.53 176.183'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M380.264 212.822L399.149 194.037L413.305 179.94'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M383.728 216.579L399.149 201.241L417.081 183.706'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M387.502 220.336L399.148 208.755L420.855 187.463'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M391.277 224.093L399.148 216.269L424.319 190.9'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M395.053 227.859L399.149 223.473L428.094 194.657'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M398.828 231.297L399.149 230.987L431.87 198.423'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M405.755 231.926L435.645 202.18'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M465.543 172.426L472.149 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M413.306 231.926L439.42 205.937'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M465.543 179.94L479.379 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M420.544 231.926L443.195 209.375'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M465.543 187.143L486.938 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M428.095 231.926L446.659 213.141'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M465.543 194.657L494.489 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M435.645 231.926L450.434 216.898'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M465.543 201.861L501.728 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M442.883 231.926L454.208 220.655'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M465.543 209.375L509.278 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M450.434 231.926L457.984 224.412'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M465.543 216.898L516.828 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M457.984 231.926L461.76 228.169'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M465.543 224.093L524.067 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M465.223 231.926L465.543 231.616L531.618 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M531.929 173.055L539.167 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M531.929 180.569L546.406 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M531.929 187.773L553.956 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M531.929 195.287L561.195 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M531.929 202.801L568.746 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M531.929 210.004L576.296 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M531.929 217.518L583.535 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M531.929 225.041L591.085 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 166.479L598.637 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 173.684L605.874 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 181.198L613.425 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 188.712L620.984 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 195.916L628.214 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 203.43L635.773 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 210.634L643.324 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 218.148L650.562 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 225.661L658.113 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M664.407 167.109L665.031 166.48'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M664.407 174.304L668.806 170.237'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M664.407 181.827L672.269 173.994'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M664.407 189.341L676.044 177.441'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M664.407 196.545L679.819 181.198'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M664.407 204.059L683.595 184.955'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M664.407 211.573L687.37 188.712'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M664.407 218.777L690.833 192.469'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M664.407 226.291L694.608 196.226'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M666.295 231.926L698.384 199.673'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.793 167.729L732.369 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M673.524 231.926L702.159 203.43'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.793 175.243L739.919 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M681.084 231.926L705.943 207.187'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.793 182.447L747.47 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M688.635 231.926L709.719 210.944'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.793 189.961L754.708 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M695.873 231.926L713.173 214.701'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.793 197.165L762.259 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M703.424 231.926L716.948 218.148'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.793 204.679L769.818 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M710.974 231.926L720.732 221.904'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.793 212.202L777.048 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M718.213 231.926L724.508 225.661'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.793 219.397L784.607 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M725.763 231.926L728.283 229.418'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.793 226.92L792.157 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M796.877 168.359L799.397 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M796.877 175.873L806.947 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M796.877 183.076L814.186 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M796.877 190.59L821.736 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M796.877 198.104L829.286 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M796.877 205.308L836.525 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M796.877 212.822L844.075 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M796.877 220.336L851.626 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M796.877 227.54L858.865 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M863.271 168.988L866.414 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M863.271 176.502L873.965 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M863.271 184.016L881.203 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M863.271 191.22L888.754 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M863.271 198.734L896.304 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M863.271 205.937L903.543 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M863.271 213.451L911.093 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M863.271 220.965L918.653 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M863.271 228.169L925.882 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M934.064 231.926L995.74 170.547'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M941.615 231.926L995.741 177.751'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M948.854 231.926L995.74 185.265'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M956.404 231.926L995.741 192.779'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M963.964 231.926L995.741 199.983'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M971.193 231.926L995.741 207.497'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M978.753 231.926L995.741 214.701'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M986.303 231.926L995.741 222.215'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M993.542 231.926L995.741 229.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1001.09 231.926L1062.14 171.176L1067.17 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1008.33 231.926L1062.14 178.38L1074.72 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1015.88 231.926L1062.14 185.894L1082.28 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1023.43 231.926L1062.14 193.408L1089.51 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1030.67 231.926L1062.14 200.612L1097.06 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1038.22 231.926L1062.14 208.126L1104.62 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1045.77 231.926L1062.14 215.64L1111.85 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1053.01 231.926L1062.14 222.844L1119.4 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1060.56 231.926L1062.14 230.358L1126.95 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1128.53 171.806L1134.19 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1128.53 179.32L1141.74 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1128.53 186.523L1149.29 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1128.53 194.037L1156.53 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1128.53 201.241L1164.08 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1128.53 208.755L1171.63 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1128.53 216.269L1178.87 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1128.53 223.473L1186.42 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1128.53 230.987L1193.66 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.6 172.426L1198.07 169.298'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.6 179.94L1201.84 172.745'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.6 187.143L1205.31 176.502'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.6 194.657L1209.08 180.259'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.6 202.18L1212.86 184.016'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.6 209.375L1216.63 187.773'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.6 216.898L1220.41 191.53'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.6 224.412L1223.87 194.977'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.6 231.616L1227.65 198.734'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1201.84 231.926L1231.42 202.49'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 173.055L1268.24 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1209.39 231.926L1235.2 206.247'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 180.569L1275.79 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1216.94 231.926L1238.97 210.004'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 188.083L1283.03 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1224.18 231.926L1242.75 213.761'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 195.287L1290.58 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1231.73 231.926L1246.21 217.208'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 202.801L1298.13 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1239.28 231.926L1249.99 220.965'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 210.004L1305.37 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1246.52 231.926L1253.76 224.722'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 217.518L1312.92 165.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1254.07 231.926L1257.54 228.479'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 225.041L1288.37 197.484'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M138.608 297.682L200.284 236.312'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M202.483 234.115L205.003 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M146.158 297.682L200.284 243.826'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M206.259 237.872L212.242 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M153.397 297.682L200.284 251.03'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M210.034 241.638L219.793 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M160.947 297.682L200.284 258.544'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M213.809 245.075L227.031 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M168.187 297.682L200.284 266.058'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M217.584 248.832L234.581 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M175.736 297.682L200.284 273.262'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M221.048 252.589L242.132 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M183.287 297.682L200.284 280.776'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M224.823 256.355L249.371 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M190.525 297.682L200.284 288.29'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M228.598 260.112L256.92 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M198.076 297.682L200.284 295.493'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M232.373 263.869L264.471 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M236.148 267.307L266.67 236.932L271.71 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M239.924 271.073L266.671 244.455L279.26 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M243.387 274.83L266.67 251.969L286.81 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M247.162 278.587L266.67 259.173L294.049 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M250.938 282.344L266.67 266.687L301.6 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M254.713 285.791L266.671 274.201L309.15 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M258.497 289.548L266.67 281.405L316.389 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M262.271 293.305L266.67 288.919L323.938 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M265.727 297.062L266.67 296.433L331.498 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M335.896 234.744L338.728 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M339.672 238.501L346.288 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M343.446 242.258L353.516 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M346.91 246.015L361.076 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M350.686 249.462L368.627 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M354.461 253.219L375.866 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M358.235 256.976L383.415 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M362.02 260.732L390.965 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M365.475 264.489L398.205 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M369.25 268.246L399.149 238.501'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M373.024 271.693L399.148 245.705'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M376.809 275.45L399.148 253.219'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M380.584 279.207L399.148 260.732'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M384.359 282.964L399.148 267.936'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M387.813 286.73L399.148 275.45'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M391.598 290.168L399.148 282.964'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M395.373 293.925L399.148 290.168'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M400.404 297.682L400.092 297.062L399.46 296.433H398.828L398.204 297.062L397.884 297.682L398.204 298.311L398.828 298.94H399.46L400.092 298.311L400.404 297.682Z'
          fill='black'
          stroke='#D6D6D6'
          strokeMiterlimit='10'
        />
        <motion.path
          variants={pathVariants}
          d='M406.387 297.682L465.543 239.13'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M413.938 297.682L465.543 246.334'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M421.176 297.682L465.543 253.848'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M428.727 297.682L465.543 261.362'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M436.276 297.682L465.543 268.566'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M443.516 297.682L465.543 276.079'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M451.065 297.682L465.543 283.593'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M458.616 297.682L465.543 290.797'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M465.854 297.682L531.929 232.236'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M473.405 297.682L531.929 239.75L535.704 235.683'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M480.955 297.682L531.929 247.273L539.479 239.44'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M488.194 297.682L531.929 254.468L543.264 243.197'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M495.744 297.682L531.929 261.991L547.038 246.954'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M503.295 297.682L531.929 269.505L550.493 250.711'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M510.533 297.682L531.929 276.709L554.268 254.158'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M518.084 297.682L531.618 284.223L558.053 257.915'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M525.644 297.682L531.618 291.426L561.828 261.672'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M532.873 297.682L565.603 265.429'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 232.865L598.637 232.555'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M540.433 297.682L569.378 269.186'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 240.379L602.42 235.993'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M547.662 297.682L572.842 272.952'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 247.893L606.195 239.75'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M555.212 297.682L576.617 276.389'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 255.097L609.65 243.516'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M562.771 297.682L580.392 280.146'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 262.611L613.425 247.273'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M570.001 297.682L584.167 283.903'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 270.134L617.209 251.03'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M577.561 297.682L587.942 287.67'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 277.329L620.984 254.787'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M585.111 297.682L591.406 291.426'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 284.852L624.759 258.234'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M592.35 297.682L595.181 295.183'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 292.366L628.535 261.991'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M599.9 297.682L631.998 265.748'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M607.45 297.682L635.773 269.505'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M614.689 297.682L639.549 273.262'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M622.239 297.682L643.323 277.019'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M629.79 297.682L647.099 280.466'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M637.028 297.682L650.874 284.223'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M644.579 297.682L654.338 287.98'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M652.129 297.682L658.112 291.737'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M659.368 297.682L661.888 295.493'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M666.918 297.682L730.793 234.434'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M674.469 297.682L730.794 241.638'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M681.707 297.682L730.793 249.152'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M689.258 297.682L730.794 256.665'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M696.496 297.682L730.793 263.869'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M704.047 297.682L730.794 271.383'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M711.605 297.682L730.793 278.897'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M718.836 297.682L730.482 286.101'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M726.395 297.682L730.481 293.615'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M733.945 297.682L796.877 235.054'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M741.184 297.682L796.876 242.577'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M748.734 297.682L796.877 249.772'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M756.284 297.682L796.876 257.295'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M763.523 297.682L796.877 264.809'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M771.073 297.682L796.876 272.012'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M778.624 297.682L796.877 279.526'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M785.862 297.682L796.876 286.73'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M793.413 297.682L796.877 294.244'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M800.963 297.682L863.271 235.683'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M865.159 233.805L867.047 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M808.202 297.682L863.272 243.197'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M868.935 237.562L874.597 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M815.752 297.682L863.271 250.401'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M872.709 241.318L881.835 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M822.991 297.682L863.272 257.915'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M876.173 244.765L889.386 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M830.541 297.682L863.271 265.429'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M879.948 248.522L896.936 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M838.092 297.682L863.272 272.633'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M883.724 252.279L904.175 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M845.33 297.682L863.271 280.146'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M887.498 256.036L911.725 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M852.881 297.682L863.272 287.67'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M891.273 259.793L919.275 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M860.44 297.682L863.272 294.864'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M895.049 263.55L926.515 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M898.513 266.997L929.658 236.312'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M931.865 234.115L934.064 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M902.287 270.754L929.657 243.826'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M935.641 237.872L941.615 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M906.062 274.511L929.657 251.34'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M939.104 241.638L948.854 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M909.838 278.268L929.657 258.544'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M942.88 245.075L956.404 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M913.613 282.025L929.658 266.058'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M946.654 248.832L963.963 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M917.388 285.472L929.657 273.572'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M950.43 252.589L971.193 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M920.852 289.229L929.346 280.776'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M954.205 256.355L978.753 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M924.627 292.986L929.346 288.29'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M957.98 260.112L986.303 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M928.402 296.743L929.346 295.493'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M961.443 263.869L993.541 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M965.219 267.307L995.741 236.932'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M968.994 271.073L995.741 244.455'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M972.77 274.83L995.741 251.969'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M976.544 278.587L995.74 259.173'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M980.008 282.344L995.741 266.687'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M983.783 286.101L995.741 274.201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M987.559 289.548L995.741 281.405'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M991.333 293.305L995.74 288.919'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M995.108 297.061L995.742 296.433'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1001.71 297.682L1062.14 237.872'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1064.97 234.744L1068.11 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1009.27 297.682L1062.14 245.075'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1068.74 238.501L1075.35 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1016.5 297.682L1062.14 252.589'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1072.52 242.258L1082.9 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1024.06 297.682L1062.14 260.112'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1076.29 246.015L1090.45 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1031.61 297.682L1062.14 267.307'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1080.07 249.462L1097.69 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1038.85 297.682L1062.14 274.83'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1083.53 253.219L1105.24 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1046.4 297.682L1062.14 282.344'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1087.31 256.976L1112.8 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1053.95 297.682L1062.14 289.548'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1091.08 260.732L1120.03 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1061.19 297.682L1062.14 297.062'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1094.86 264.489L1127.59 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1098.63 268.246L1128.53 238.501'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1131.67 235.054L1134.82 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1102.1 271.693L1128.53 245.705'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1135.45 238.811L1142.38 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1105.87 275.45L1128.53 253.219'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1139.22 242.577L1149.93 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1109.65 279.207L1128.53 260.732'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1142.69 246.334L1157.17 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1113.42 282.964L1128.53 267.936'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1146.46 250.091L1164.72 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1117.2 286.73L1128.53 275.45'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1150.24 253.529L1172.27 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1120.97 290.487L1128.21 282.964'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1154.01 257.295L1179.5 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1124.43 293.925L1128.21 290.168'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1157.79 261.052L1187.05 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1129.47 297.682L1129.15 297.062L1128.53 296.433H1127.9L1127.27 297.062L1126.96 297.682L1127.27 298.311L1127.9 298.94H1128.53L1129.15 298.311L1129.47 297.682Z'
          fill='black'
          stroke='#D6D6D6'
          strokeMiterlimit='10'
        />
        <motion.path
          variants={pathVariants}
          d='M1161.56 264.809L1194.61 231.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1165.03 268.566L1194.6 239.13'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1168.8 272.322L1194.6 246.644'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1172.58 275.769L1194.6 253.848'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1176.35 279.526L1194.6 261.362'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1180.13 283.283L1194.6 268.876'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1183.9 287.04L1194.6 276.079'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1187.37 290.797L1194.6 283.593'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1191.14 294.244L1194.6 290.797'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1195.24 297.682L1261 232.236'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1202.48 297.682L1261 239.75'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1210.03 297.682L1261 247.273'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1217.58 297.682L1261 254.468'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1224.81 297.682L1261 261.991'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1232.37 297.682L1261 269.505'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1239.92 297.682L1261 276.709'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1247.15 297.682L1261 284.223'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1254.71 297.682L1261 291.737'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 232.236H1261.31'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 239.75L1264.46 236.312'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 247.273L1267.92 240.379'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 254.468L1271.07 244.455'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 261.991L1274.52 248.522'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 269.505L1277.68 252.589'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 276.709L1281.14 256.976'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 284.223L1284.28 261.052'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 291.736L1287.75 265.119'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1262.25 297.682L1290.89 269.186'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1269.49 297.682L1294.35 273.262'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1277.04 297.682L1297.5 277.329'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1284.59 297.682L1300.96 281.405'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1291.83 297.682L1304.1 285.791'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1299.38 297.682L1307.57 289.858'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1306.94 297.682L1310.72 293.925'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M139.24 363.757L200.284 303.007L205.626 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M146.791 363.757L200.284 310.521L212.865 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M154.029 363.757L200.284 317.725L220.415 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M161.58 363.757L200.284 325.239L227.975 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M169.13 363.757L200.284 332.762L235.204 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M176.369 363.757L200.285 339.957L242.764 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M183.919 363.757L200.284 347.48L250.314 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M191.47 363.757L200.285 354.994L257.553 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M198.708 363.757L200.284 362.198L265.103 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M266.67 303.637L272.653 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M266.67 311.15L279.892 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M266.67 318.354L287.442 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M266.67 325.868L294.681 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M266.67 333.382L302.231 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M266.67 340.586L309.782 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M266.67 348.1L317.02 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M266.67 355.614L324.571 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M266.67 362.818L332.121 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M273.286 363.757L332.754 304.266L339.36 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M280.516 363.757L332.754 311.78L346.911 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M288.075 363.757L332.754 319.294L354.461 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M295.625 363.757L332.753 326.497L361.699 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M302.864 363.757L332.754 334.011L369.25 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M310.414 363.757L332.753 341.525L376.809 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M317.965 363.757L332.754 348.729L384.039 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M325.203 363.757L332.753 356.243L391.598 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M332.754 363.757L399.149 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M402.924 301.448L406.387 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M406.387 305.205L413.937 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M410.162 308.962L421.176 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M413.938 312.4L428.727 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M417.713 316.166L436.277 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M421.487 319.923L443.515 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M425.263 323.68L451.066 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M428.727 327.437L458.616 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M432.502 331.194L465.544 298.311'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M436.276 334.641L465.543 305.825'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M440.052 338.398L465.543 313.029'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M443.827 342.155L465.543 320.543'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M447.603 345.911L465.544 328.057'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M451.065 349.668L465.543 335.261'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M454.841 353.115L465.543 342.775'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M458.616 356.872L465.543 350.298'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M462.392 360.629L465.544 357.492'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M466.799 363.757L531.618 298.94L532.873 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M474.037 363.757L531.617 306.454L540.432 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M481.588 363.757L531.618 313.658L547.662 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M488.826 363.757L531.617 321.172L555.212 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M496.377 363.757L531.618 328.686L562.772 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M503.927 363.757L531.617 335.89L570.001 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M511.166 363.757L531.618 343.404L577.561 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M518.716 363.757L531.617 350.918L585.111 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M526.267 363.757L531.618 358.122L592.35 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 299.57L599.9 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 307.083L607.451 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 314.597L614.689 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 321.801L622.24 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 329.315L629.79 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 336.829L637.029 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 344.033L644.579 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 351.547L652.129 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 359.061L659.368 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M600.532 363.757L664.407 300.19L665.663 298.94'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M608.083 363.757L664.408 307.704L669.438 302.697'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M615.321 363.757L664.407 315.227L672.901 306.454'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M622.872 363.757L664.408 322.421L676.677 310.211'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M630.422 363.757L664.407 329.935L680.452 313.968'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M637.66 363.757L664.407 337.458L684.226 317.725'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M645.211 363.757L664.407 344.653L688.002 321.172'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M652.762 363.757L664.408 352.176L691.778 324.929'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M660 363.757L664.407 359.69L695.241 328.686'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M667.551 363.757L699.016 332.443'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M732.369 299.57L733.945 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M675.101 363.757L702.791 336.2'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M735.833 303.007L741.184 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M682.34 363.757L706.567 339.957'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M739.608 306.764L748.735 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M689.89 363.757L710.341 343.404'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M743.383 310.521L756.284 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M697.44 363.757L714.117 347.161'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M747.158 314.278L763.523 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M704.679 363.757L717.58 350.918'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M750.934 318.044L771.074 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M712.229 363.757L721.355 354.675'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M754.709 321.482L778.624 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M719.779 363.757L725.13 358.432'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M758.172 325.239L785.862 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M727.018 363.757L728.905 361.879'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M761.947 328.996L793.413 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M765.723 332.762L796.877 301.758'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M769.498 336.519L796.877 308.962'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M773.272 340.276L796.876 316.476'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M776.736 343.714L796.877 323.99'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M780.512 347.48L796.877 331.194'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M784.287 351.237L796.877 338.708'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M788.062 354.994L796.876 346.222'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M791.837 358.751L796.877 353.425'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M795.621 362.508L796.876 360.939'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M798.764 299.88L800.963 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M802.539 303.327L808.202 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M806.314 307.083L815.752 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M810.09 310.84L822.991 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M813.865 314.597L830.542 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M817.328 318.354L838.091 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M821.104 322.111L845.33 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M824.879 325.558L852.881 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M828.653 329.315L860.44 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M832.429 333.072L863.271 302.387'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M865.471 300.19L867.67 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M836.204 336.829L863.272 309.901'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M869.246 303.947L875.229 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M839.668 340.586L863.272 317.105'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M873.021 307.393L882.779 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M843.442 344.343L863.271 324.619'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M876.796 311.15L890.018 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M847.218 347.79L863.271 332.133'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M880.26 314.907L897.569 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M850.993 351.547L863.272 339.337'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M884.035 318.664L905.119 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M854.769 355.304L863.272 346.851'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M887.81 322.421L912.357 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M858.231 359.061L863.271 354.365'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M891.585 325.868L919.908 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M862.007 362.818L863.271 361.569'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M895.36 329.625L927.458 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M898.824 333.382L929.346 303.007'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M932.177 300.509L934.697 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M902.599 337.139L929.345 310.521'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M935.952 304.266L942.247 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M906.374 340.896L929.346 317.725'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M939.416 307.704L949.486 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M910.149 344.653L929.346 325.239'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M943.191 311.461L957.037 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M913.925 348.1L929.346 332.762'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M946.966 315.227L964.586 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M917.709 351.857L929.346 339.957'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M950.741 318.984L971.825 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M921.163 355.614L929.346 347.48'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M954.517 322.741L979.376 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M924.938 359.371L929.346 354.994'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M958.291 326.497L986.925 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M928.714 363.137L929.348 362.198'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M961.755 329.935L994.164 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M965.53 333.701L995.741 303.637L1001.71 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M969.306 337.458L995.741 311.15L1009.27 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M973.08 341.215L995.74 318.664L1016.5 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M976.865 344.972L995.741 325.868L1024.06 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M980.64 348.729L995.74 333.382L1031.61 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M984.095 352.176L995.741 340.896L1038.85 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M987.869 355.933L995.74 348.1L1046.4 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M991.654 359.69L995.741 355.614L1053.95 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M995.429 363.447L995.74 363.137L1061.19 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1002.35 363.757L1062.14 304.266'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1065.28 301.129L1068.74 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1009.9 363.757L1062.14 311.78'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1069.05 304.886L1075.98 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1017.14 363.757L1062.14 319.294'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1072.83 308.643L1083.53 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1024.69 363.757L1062.14 326.497'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1076.6 312.09L1091.08 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1032.24 363.757L1062.14 334.011'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1080.39 315.847L1098.32 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1039.48 363.757L1062.14 341.525'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1083.84 319.604L1105.87 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1047.03 363.757L1062.14 348.729'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1087.62 323.361L1113.42 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1054.58 363.757L1062.14 356.243'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1091.39 327.118L1120.66 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1061.81 363.757H1062.14'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1095.17 330.884L1128.21 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1098.95 334.321L1128.21 305.205'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1131.98 301.448L1135.76 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1102.73 338.078L1128.21 312.4'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1135.76 305.205L1143 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1106.18 341.835L1128.21 319.923'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1139.54 308.962L1150.55 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1109.96 345.601L1128.21 327.437'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1143.32 312.719L1158.11 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1113.74 349.358L1128.21 334.641'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1146.77 316.166L1165.34 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1117.52 353.115L1128.21 342.155'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1150.55 319.923L1172.9 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1121.29 356.553L1128.21 349.668'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1154.32 323.68L1180.45 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1124.75 360.319L1128.21 356.872'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1158.11 327.437L1187.69 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1161.88 331.194L1194.6 298.311L1195.24 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1165.34 334.641L1194.6 305.825L1202.48 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1169.11 338.398L1194.6 313.029L1210.03 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1172.9 342.155L1194.6 320.543L1217.58 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1176.67 345.911L1194.6 328.057L1224.82 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1180.45 349.668L1194.6 335.261L1232.37 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1184.22 353.425L1194.6 342.775L1239.92 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1187.69 356.872L1194.6 350.298L1247.15 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1191.46 360.629L1194.6 357.492L1254.7 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1195.86 363.757L1261 298.94'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1203.42 363.757L1261 306.454'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1210.65 363.757L1261 313.968'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1218.21 363.757L1261 321.172'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1225.76 363.757L1261 328.686'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1233 363.757L1261 336.2'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1240.55 363.757L1261 343.404'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1248.1 363.757L1261 350.918'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1255.34 363.757L1261 358.432'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261.63 298.621L1262.26 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1264.77 302.697L1269.49 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1268.24 306.764L1277.04 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1271.38 310.84L1284.59 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1274.84 314.907L1291.83 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1277.99 318.984L1299.38 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1281.45 323.051L1306.94 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1284.59 327.437L1314.17 297.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1288.06 331.504L1318.58 300.819'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1291.21 335.58L1318.58 308.333'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1294.66 339.647L1318.58 315.537'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1297.82 343.714L1318.58 323.051'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1301.27 347.79L1318.58 330.564'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1304.42 352.176L1318.58 337.768'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1307.89 356.243L1318.58 345.282'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1311.03 360.319L1318.58 352.796'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1314.8 363.757L1318.58 360'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 368.453L70.0139 365.955'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 375.657L73.7891 369.712'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 383.171L77.5642 373.469'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 390.375L81.0278 377.226'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 397.889L84.803 380.983'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 405.412L88.5781 384.429'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 412.607L92.3533 388.186'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 420.13L96.1284 391.943'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M67.8057 427.644L99.9036 395.7'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M72.8457 429.832L103.368 399.457'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M136.721 366.265L139.241 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M80.3955 429.832L107.142 403.214'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M140.496 370.022L146.791 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M87.9463 429.832L110.918 406.661'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M143.96 373.779L154.03 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M95.1846 429.832L114.693 410.418'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M147.734 377.536L161.58 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M102.735 429.832L118.468 414.175'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M151.51 381.293L169.13 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M110.285 429.832L122.243 417.932'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M155.285 384.739L176.369 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M117.524 429.832L125.707 421.689'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M159.061 388.496L183.92 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M125.074 429.832L129.482 425.136'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M162.523 392.253L191.469 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M132.625 429.832L133.259 428.893'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M166.299 396.01L198.708 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M170.074 399.767L200.285 369.712'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M203.115 366.575L206.258 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M173.85 403.524L200.285 376.916'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M206.891 370.332L213.809 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M177.624 406.971L200.284 384.429'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M210.666 374.089L221.048 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M181.399 410.728L200.284 391.943'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M214.441 377.855L228.598 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M184.863 414.485L200.285 399.147'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M218.216 381.612L235.836 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M188.639 418.242L200.285 406.661'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M221.991 385.369L243.387 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M192.413 422.008L200.284 414.175'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M225.455 388.806L250.937 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M196.188 425.765L200.284 421.379'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M229.23 392.573L258.176 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M199.964 429.203L200.285 428.893'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M233.005 396.329L265.726 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M236.78 400.086L266.67 370.332L273.286 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M240.556 403.843L266.67 377.855L280.515 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M244.331 407.6L266.67 385.049L288.075 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M247.794 411.047L266.67 392.573L295.625 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M251.569 414.804L266.67 400.086L302.864 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M255.345 418.561L266.67 407.29L310.414 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M259.12 422.318L266.67 414.804L317.965 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M262.895 426.075L266.67 422.318L325.203 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M266.358 429.832L266.67 429.522L332.753 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M332.754 370.961L340.304 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M332.754 378.475L347.543 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M332.754 385.679L355.093 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M332.754 393.193L362.332 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M332.754 400.707L369.882 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M332.754 407.91L377.433 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M332.754 415.424L384.671 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M332.754 422.947L392.222 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M333.386 429.832L399.148 364.386'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M399.46 364.076L399.772 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M340.936 429.832L399.148 371.59'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M403.235 367.833L407.01 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M348.175 429.832L399.148 379.104'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M407.01 371.59L414.56 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M355.725 429.832L399.148 386.618'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M410.474 375.037L422.12 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M363.275 429.832L399.148 393.822'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M414.249 378.794L429.35 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M370.514 429.832L399.148 401.336'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M418.024 382.551L436.909 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M378.064 429.832L399.148 408.85'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M421.799 386.308L444.459 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M385.614 429.832L399.148 416.054'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M425.574 390.065L451.698 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M392.854 429.832L399.148 423.567'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M429.038 393.512L459.248 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M432.813 397.269L465.544 365.015'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M466.166 364.385L466.799 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M436.588 401.026L465.543 372.529'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M469.63 368.143L474.037 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M440.363 404.783L465.543 379.733'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M473.405 371.9L481.588 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M444.139 408.54L465.543 387.247'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M477.181 375.347L488.827 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M447.914 412.297L465.544 394.451'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M480.955 379.104L496.376 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M451.377 415.744L465.543 401.965'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M484.73 382.861L503.927 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M455.152 419.5L465.543 409.479'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M488.506 386.618L511.166 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M458.928 423.257L465.543 416.683'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M491.97 390.375L518.716 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M462.703 427.014L465.544 424.197'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M495.744 394.132L526.266 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M499.52 397.579L531.617 365.636L532.561 364.696'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M503.295 401.336L531.618 373.149L536.337 368.453'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M507.07 405.093L531.618 380.353L540.112 372.21'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M510.854 408.85L531.618 387.867L543.887 375.976'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M514.309 412.607L531.617 395.39L547.662 379.414'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M518.084 416.364L531.618 402.585L551.437 383.171'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M521.859 419.81L531.618 410.108L554.901 386.928'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M525.644 423.567L531.618 417.622L558.676 390.694'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M529.419 427.324L531.618 424.826L562.451 394.451'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M534.449 429.832L566.226 397.889'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 366.265L599.268 365.015'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M541.688 429.832L570.001 401.646'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 373.779L603.043 368.772'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M549.238 429.832L573.465 405.412'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 380.983L606.818 372.529'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M556.477 429.832L577.24 409.169'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 388.496L610.593 376.286'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M564.027 429.832L581.016 412.926'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 396.01L614.057 379.733'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M571.577 429.832L584.79 416.683'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 403.214L617.832 383.49'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M578.816 429.832L588.575 420.13'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 410.728L621.607 387.247'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M586.366 429.832L592.35 423.887'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 418.242L625.383 391.004'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M593.917 429.832L595.805 427.644'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 425.446L629.158 394.761'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M601.155 429.832L632.942 398.518'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M664.407 366.894L665.974 365.325'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M608.706 429.832L636.397 401.965'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M664.407 374.408L669.749 369.082'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M616.266 429.832L640.172 405.722'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M664.407 381.922L673.524 372.839'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M623.495 429.832L643.947 409.479'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M664.407 389.126L676.988 376.596'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M631.055 429.832L647.731 413.236'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M664.407 396.64L680.763 380.353'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M638.604 429.832L651.506 416.993'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M664.407 404.153L684.538 383.8'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M645.843 429.832L654.96 420.75'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M664.407 411.357L688.313 387.557'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M653.394 429.832L658.736 424.197'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M664.407 418.871L692.098 391.314'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M660.943 429.832L662.519 427.954'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M664.407 426.385L695.552 395.071'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M668.183 429.832L699.328 398.828'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.481 367.833L734.568 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M675.732 429.832L703.102 402.275'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.481 375.037L741.807 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M682.972 429.832L706.887 406.032'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.481 382.551L749.357 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M690.521 429.832L710.662 409.789'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.481 389.755L756.917 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M698.072 429.832L714.437 413.546'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.481 397.269L764.146 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M705.311 429.832L717.891 417.303'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.481 404.783L771.706 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M712.861 429.832L721.676 421.069'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.481 411.987L779.256 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M720.411 429.832L725.451 424.507'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.481 419.5L786.495 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M727.65 429.832L729.226 428.264'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.481 427.014L794.045 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M799.076 365.955L801.596 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M802.851 369.712L808.834 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M806.626 373.469L816.385 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M810.41 377.226L823.935 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M814.186 380.983L831.174 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M817.961 384.739L838.724 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M821.415 388.186L846.274 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M825.199 391.943L853.513 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M828.975 395.7L861.063 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M832.75 399.457L863.272 369.082'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M865.782 366.265L868.614 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M836.524 403.214L863.271 376.596'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M869.558 370.022L875.853 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M839.979 406.661L863.272 383.8'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M873.342 373.779L883.403 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M843.764 410.418L863.272 391.314'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M877.117 377.536L890.642 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M847.539 414.175L863.272 398.518'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M880.571 381.293L898.192 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M851.313 417.932L863.271 406.032'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M884.347 385.049L905.751 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M855.089 421.689L863.271 413.546'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M888.131 388.496L912.981 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M858.864 425.446L863.272 420.75'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M891.906 392.253L920.541 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M862.328 428.893L863.272 428.264'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M895.681 396.01L928.09 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M899.456 399.767L929.346 369.712'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M932.498 366.894L935.329 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M902.92 403.524L929.346 377.226'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M936.272 370.332L942.879 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M906.695 407.29L929.346 384.429'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M940.048 374.089L950.429 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M910.47 410.728L929.345 391.943'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M943.502 377.855L957.668 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M914.245 414.485L929.346 399.457'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M947.287 381.612L965.219 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M918.021 418.242L929.346 406.661'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M951.062 385.369L972.769 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M921.484 422.008L929.346 414.175'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M954.837 389.126L980.008 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M925.259 425.765L929.345 421.689'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M958.612 392.573L987.558 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M929.034 429.521L929.346 428.893'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M962.076 396.329L995.109 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M965.851 400.086L995.74 370.332'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M999.204 367.204L1002.35 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M969.626 403.843L995.74 377.855'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1002.66 370.651L1009.9 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M973.401 407.6L995.741 385.049'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1006.44 374.408L1017.14 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M977.177 411.047L995.741 392.573'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1010.22 378.165L1024.69 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M980.951 414.804L995.74 400.086'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1013.99 381.922L1032.24 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M984.415 418.561L995.74 407.29'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1017.77 385.679L1039.48 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M988.19 422.318L995.741 414.804'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1021.54 389.436L1047.03 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M991.966 426.075L995.741 422.318'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1025.01 392.883L1054.58 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M995.74 429.832V429.522'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1028.78 396.64L1061.81 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1032.56 400.397L1062.14 370.961'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1065.6 367.514L1069.37 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1036.33 404.153L1062.14 378.475'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1069.37 371.271L1076.92 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1040.11 407.91L1062.14 385.989'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1073.15 374.718L1084.16 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1043.57 411.667L1062.14 393.193'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1076.92 378.475L1091.71 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1047.35 415.114L1062.14 400.707'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1080.7 382.232L1099.26 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1051.12 418.871L1062.14 408.23'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1084.16 385.989L1106.5 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1054.9 422.628L1062.14 415.424'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1087.94 389.755L1114.05 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1058.67 426.385L1062.14 422.947'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1091.71 393.512L1121.6 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1095.49 396.95L1128.21 364.386L1128.53 364.076'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1099.26 400.707L1128.21 371.9L1132.31 367.833'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1103.04 404.464L1128.21 379.104L1136.08 371.59'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1106.5 408.23L1128.21 386.618L1139.86 375.037'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1110.28 411.987L1128.21 393.822L1143.63 378.794'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1114.05 415.424L1128.21 401.336L1147.09 382.551'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1117.83 419.181L1128.21 408.85L1150.87 386.308'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1121.6 422.947L1128.21 416.054L1154.65 390.065'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1125.38 426.704L1128.21 423.567L1158.42 393.822'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1129.47 429.832L1162.2 397.269'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1195.24 364.385L1195.86 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1137.02 429.832L1165.97 401.026'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1199.01 368.143L1203.42 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1144.58 429.832L1169.43 404.783'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1202.79 371.9L1210.65 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1151.81 429.832L1173.21 408.54'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1206.56 375.657L1218.21 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1159.36 429.832L1176.98 412.297'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1210.03 379.104L1225.76 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1166.91 429.832L1180.76 416.054'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1213.8 382.861L1233 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1174.15 429.832L1184.53 419.5'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1217.58 386.618L1240.55 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1181.7 429.832L1188 423.257'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1221.35 390.375L1248.1 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1189.25 429.832L1191.77 427.014'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1225.13 394.132L1255.34 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1228.59 397.889L1261 365.636'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261.63 365.015L1262.89 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1232.37 401.336L1261 373.149'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1265.09 369.082L1270.13 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1236.14 405.093L1261 380.672'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1268.24 373.149L1277.68 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1239.92 408.85L1261 387.867'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1271.69 377.226L1285.23 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1243.69 412.607L1261 395.39'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1274.84 381.293L1292.47 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1247.47 416.364L1261 402.585'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1278.31 385.369L1300.02 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1250.93 419.81L1261 410.108'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1281.45 389.436L1307.57 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1254.71 423.567L1261 417.622'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1284.92 393.822L1314.8 363.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1258.48 427.324L1261 424.826'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1288.06 397.889L1318.58 367.514'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1291.52 401.965L1318.58 374.718'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1294.66 406.032L1318.58 382.232'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1298.13 410.108L1318.58 389.755'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1301.59 414.175L1318.58 396.95'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1304.73 418.242L1318.58 404.464'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1308.2 422.628L1318.58 411.987'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1311.34 426.704L1318.58 419.181'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1315.75 429.832L1318.58 426.704'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M133.89 435.787L139.864 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M133.89 442.981L147.414 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M133.89 450.504L154.974 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M133.89 458.018L162.203 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M133.89 465.222L169.763 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M133.89 472.736L176.992 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M133.89 479.94L184.552 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M133.89 487.454L192.102 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M133.89 494.968L199.341 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M200.284 436.407L203.739 432.96'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M200.284 443.611L207.202 436.726'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M200.284 451.125L210.977 440.483'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M200.284 458.638L214.753 444.24'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M200.284 465.842L218.528 447.997'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M200.284 473.356L222.303 451.444'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M200.284 480.879L225.766 455.201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M200.284 488.074L229.542 458.958'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M200.284 495.597L233.317 462.715'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M207.514 495.597L237.092 466.472'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M266.67 437.036L273.909 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M215.073 495.597L240.876 470.229'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M266.67 444.55L281.459 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M222.303 495.597L244.651 473.675'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M266.67 451.754L288.698 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M229.862 495.597L248.106 477.432'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M266.67 459.268L296.248 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M237.413 495.597L251.881 481.189'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M266.67 466.782L303.487 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M244.651 495.597L255.665 484.946'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M266.67 473.985L311.037 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M252.202 495.597L259.441 488.703'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M266.67 481.499L318.597 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M259.752 495.597L263.216 492.15'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M266.67 489.013L325.826 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M333.065 430.142L333.387 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M336.84 433.589L340.936 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M340.615 437.346L348.175 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M344.391 441.103L355.725 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M348.175 444.86L363.275 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M351.629 448.617L370.514 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M355.404 452.383L378.064 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M359.18 455.821L385.615 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M362.964 459.578L392.853 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M366.739 463.335L399.149 431.081L400.404 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M370.193 467.101L399.148 438.285L407.954 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M373.969 470.858L399.149 445.799L415.193 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M377.753 474.296L399.148 453.322L422.743 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M381.528 478.052L399.149 460.517L429.982 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M385.303 481.819L399.148 468.04L437.532 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M389.078 485.575L399.148 475.235L445.082 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M392.542 489.332L399.148 482.758L452.321 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M396.317 493.089L399.149 490.272L459.872 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M401.036 495.597L465.543 431.711L466.487 430.771'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M408.586 495.597L465.543 438.914L470.262 434.528'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M415.825 495.597L465.544 446.428L473.717 437.975'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M423.375 495.597L465.543 453.942L477.492 441.732'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M430.926 495.597L465.544 461.146L481.276 445.489'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M438.164 495.597L465.543 468.66L485.051 449.246'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M445.715 495.597L465.544 476.174L488.827 453.003'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M453.265 495.597L465.543 483.378L492.281 456.76'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M460.504 495.597L465.544 490.892L496.065 460.207'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M468.054 495.597L499.84 463.964'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M531.617 432.34L532.873 431.081'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M475.604 495.597L503.616 467.721'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M531.617 439.854L536.648 434.847'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M482.843 495.597L507.39 471.478'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M531.617 447.058L540.432 438.285'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M490.394 495.597L511.166 475.235'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M531.617 454.572L544.207 442.042'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M497.632 495.597L514.629 478.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M531.617 462.085L547.982 445.799'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M505.183 495.597L518.405 482.439'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M531.617 469.289L551.757 449.565'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M512.742 495.597L522.18 486.196'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M531.617 476.803L555.212 453.322'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M519.972 495.597L525.955 489.953'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M531.617 484.007L558.996 457.079'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M527.531 495.597L529.73 493.71'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M531.617 491.521L562.771 460.517'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M535.081 495.597L566.547 464.283'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 432.96L599.58 431.401'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M542.32 495.597L570.322 468.04'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 440.483L603.364 435.158'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M549.87 495.597L574.097 471.797'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 447.678L607.139 438.914'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M557.421 495.597L577.561 475.554'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 455.201L610.914 442.361'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M564.659 495.597L581.336 479.311'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 462.715L614.369 446.118'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M572.21 495.597L585.111 482.758'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 469.918L618.153 449.875'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M579.76 495.597L588.886 486.515'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 477.432L621.928 453.632'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M586.999 495.597L592.662 490.272'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 484.946L625.703 457.389'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M594.549 495.597L596.125 494.029'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 492.15L629.478 461.146'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M602.1 495.597L633.254 464.593'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M664.407 433.589L666.295 431.711'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M609.338 495.597L636.717 468.35'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M664.407 441.103L670.07 435.468'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M616.889 495.597L640.493 472.107'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M664.407 448.617L673.845 439.224'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M624.127 495.597L644.267 475.864'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M664.407 455.821L677.309 442.671'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M631.678 495.597L648.043 479.621'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M664.407 463.335L681.084 446.428'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M639.228 495.597L651.817 483.068'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M664.407 470.539L684.859 450.185'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M646.467 495.597L655.593 486.825'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M664.407 478.052L688.634 453.942'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M654.017 495.597L659.056 490.582'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M664.407 485.576L692.409 457.699'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M661.576 495.597L662.831 494.339'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M664.407 492.77L696.184 461.456'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M668.806 495.597L699.648 464.903'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.481 434.218L733.001 432.021'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M676.365 495.597L703.423 468.66'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.481 441.732L736.776 435.787'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M683.915 495.597L707.198 472.417'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.481 449.246L740.24 439.544'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M691.154 495.597L710.974 476.174'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.481 456.45L744.015 443.301'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M698.704 495.597L714.748 479.94'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.481 463.964L747.79 446.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M706.255 495.597L718.213 483.697'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.481 471.478L751.565 450.504'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M713.493 495.597L721.987 487.135'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.481 478.682L755.341 454.261'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M721.044 495.597L725.763 490.892'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.481 486.196L758.804 458.018'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M728.594 495.597L729.538 494.658'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.481 493.71L762.579 461.775'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M735.833 495.597L766.355 465.532'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M799.396 432.34L802.228 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M743.383 495.597L770.13 468.979'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M803.172 436.097L809.458 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M750.622 495.597L773.905 472.736'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M806.946 439.854L817.016 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M758.172 495.597L777.68 476.493'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M810.722 443.611L824.567 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M765.723 495.597L781.144 480.25'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M814.497 447.058L831.806 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M772.961 495.597L784.919 484.007'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M818.272 450.815L839.356 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M780.512 495.597L788.694 487.454'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M821.735 454.571L846.906 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M788.062 495.597L792.469 491.211'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M825.511 458.328L854.145 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M795.301 495.597L796.245 494.968'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M829.286 462.085L861.696 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M833.062 465.842L863.272 435.787'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M866.103 432.65L869.245 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M836.836 469.289L863.271 442.981'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M869.878 436.407L876.484 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M840.3 473.046L863.271 450.504'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M873.653 440.164L884.035 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M844.075 476.803L863.272 458.018'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M877.429 443.921L891.586 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M847.851 480.56L863.272 465.222'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M880.892 447.678L898.824 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M851.625 484.317L863.271 472.736'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M884.667 451.125L906.374 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M855.4 488.074L863.271 480.25'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M888.442 454.882L913.925 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M859.176 491.521L863.272 487.454'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M892.217 458.638L921.163 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M862.64 495.278L863.273 494.968'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M895.992 462.395L928.713 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M899.768 466.162L929.346 436.407L932.809 432.96'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M903.231 469.918L929.346 443.921L936.585 436.726'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M907.006 473.356L929.345 451.125L940.359 440.483'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M910.781 477.113L929.345 458.638L943.823 444.24'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M914.557 480.879L929.346 466.162L947.598 447.997'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M918.332 484.636L929.346 473.356L951.374 451.444'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M922.107 488.393L929.346 480.879L955.149 455.201'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M925.57 491.831L929.345 488.074L958.924 458.958'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M929.346 495.597L962.699 462.715'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M936.896 495.597L966.163 466.472'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M995.74 437.036L999.515 433.279'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M944.135 495.597L969.938 470.229'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M995.74 444.55L1003.29 437.036'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M951.685 495.597L973.712 473.675'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M995.74 451.754L1006.75 440.793'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M959.235 495.597L977.488 477.432'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M995.74 459.268L1010.53 444.55'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M966.475 495.597L981.264 481.189'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M995.74 466.782L1014.3 448.307'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M974.024 495.597L984.727 484.946'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M995.74 473.986L1018.08 452.064'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M981.584 495.597L988.502 488.703'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M995.74 481.499L1021.85 455.511'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M988.813 495.597L992.277 492.46'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M995.74 489.013L1025.32 459.268'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M996.373 495.597L1029.09 463.025'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1062.45 430.142L1062.76 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1003.6 495.597L1032.87 466.782'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1065.91 433.899L1070 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1011.16 495.597L1036.64 470.539'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1069.69 437.346L1077.55 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1018.71 495.597L1040.42 474.296'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1073.46 441.103L1084.79 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1025.95 495.597L1044.19 477.742'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1077.24 444.86L1092.34 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1033.5 495.597L1047.66 481.499'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1081.01 448.617L1099.9 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1041.05 495.597L1051.43 485.256'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1084.79 452.383L1107.13 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1048.29 495.597L1055.21 489.013'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1088.25 455.821L1114.69 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1055.84 495.597L1058.98 492.77'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1092.03 459.578L1122.24 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1095.8 463.335L1128.21 431.081L1128.84 430.461'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1099.58 467.101L1128.21 438.285L1132.62 434.218'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1103.35 470.858L1128.21 445.799L1136.39 437.665'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1106.81 474.615L1128.21 453.322L1140.17 441.422'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1110.59 478.052L1128.21 460.517L1143.94 445.179'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1114.36 481.819L1128.21 468.04L1147.41 448.936'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1118.14 485.576L1128.21 475.554L1151.18 452.693'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1121.92 489.332L1128.21 482.758L1154.96 456.45'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1125.7 493.089L1128.21 490.272L1158.73 459.897'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1130.1 495.597L1162.51 463.654'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1137.65 495.597L1166.28 467.411'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1145.21 495.597L1169.75 471.168'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1152.44 495.597L1173.52 474.925'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1160 495.597L1177.3 478.372'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1167.55 495.597L1181.07 482.129'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1174.79 495.597L1184.85 485.886'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1182.34 495.597L1188.63 489.643'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1189.89 495.597L1192.08 493.399'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1197.12 495.597L1261 432.34'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1204.67 495.597L1261 439.854'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1212.22 495.597L1261 447.058'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1219.46 495.597L1261 454.572'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1227.01 495.597L1261 462.085'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1234.56 495.597L1261 469.289'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1241.8 495.597L1261 476.803'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1249.35 495.597L1261 484.317'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1256.9 495.597L1261 491.521'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1307.25 438.285L1315.75 429.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1277.37 475.235L1318.58 434.218'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1264.14 495.597L1318.58 441.422'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1271.69 495.597L1318.58 448.936'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1278.93 495.597L1318.58 456.45'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1286.48 495.597L1318.58 463.654'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1294.04 495.597L1318.58 471.168'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1301.27 495.597L1318.58 478.682'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1308.83 495.597L1318.58 485.886'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1316.38 495.597L1318.58 493.399'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M141.439 561.663L200.284 503.111L207.514 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M148.679 561.663L200.285 510.315L215.074 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M156.229 561.663L200.284 517.829L222.303 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M163.468 561.663L200.285 525.343L229.863 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M171.018 561.663L200.284 532.547L237.413 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M178.568 561.663L200.285 540.061L244.652 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M185.807 561.663L200.284 547.574L252.202 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M193.357 561.663L200.285 554.778L259.752 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M266.67 496.216L266.991 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M266.67 503.731L274.541 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M266.67 510.935L282.091 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M266.67 518.449L289.33 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M266.67 525.972L296.88 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M266.67 533.167L304.431 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M266.67 540.69L311.669 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M266.67 548.204L319.22 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M266.67 555.408L326.77 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M334.641 561.663L399.148 497.476L401.036 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M342.191 561.663L399.148 504.99L408.586 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M349.741 561.663L399.148 512.503L415.825 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M356.98 561.663L399.148 519.707L423.375 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M364.53 561.663L399.148 527.221L430.925 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M372.081 561.663L399.148 534.735L438.164 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M379.319 561.663L399.148 541.939L445.714 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M386.87 561.663L399.149 549.453L453.265 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M394.43 561.663L399.149 556.967L460.504 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M401.659 561.663L465.543 498.415'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M409.219 561.663L465.544 505.61'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M416.448 561.663L465.543 513.133'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M424.008 561.663L465.544 520.647'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M431.558 561.663L465.543 527.85'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M438.797 561.663L465.544 535.364'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M446.347 561.663L465.543 542.878'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M453.897 561.663L465.544 550.082'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M461.136 561.663L465.543 557.596'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M468.687 561.663L531.618 499.035'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M533.505 497.476L535.081 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M476.236 561.663L531.617 506.239'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M536.969 500.913L542.32 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M483.476 561.663L531.618 513.753'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M540.744 504.67L549.87 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M491.025 561.663L531.617 521.267'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M544.52 508.427L557.421 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M498.576 561.663L531.618 528.471'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M548.294 512.193L564.659 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M505.814 561.663L531.617 535.984'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M552.069 515.95L572.21 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M513.365 561.663L531.618 543.507'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M555.533 519.707L579.76 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M520.915 561.663L531.617 550.702'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M559.309 523.145L586.999 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M528.154 561.663L531.618 558.225'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M563.083 526.911L594.549 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M566.858 530.668L598.013 499.664L602.099 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M570.634 534.425L598.013 507.178L609.338 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M574.408 538.182L598.012 514.382L616.888 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M577.872 541.939L598.012 521.896L624.127 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M581.647 545.386L598.013 529.41L631.677 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M585.423 549.143L598.013 536.614L639.228 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M589.197 552.9L598.012 544.128L646.466 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M592.973 556.657L598.012 551.641L654.016 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M596.437 560.414L598.013 558.845L661.576 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M602.731 561.663L664.407 500.293'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M666.606 498.096L668.806 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M609.97 561.663L664.407 507.807'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M670.382 501.853L676.365 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M617.521 561.663L664.407 515.011'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M674.157 505.3L683.916 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M625.07 561.663L664.407 522.525'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M677.62 509.057L691.154 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M632.31 561.663L664.407 530.039'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M681.396 512.813L698.704 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M639.859 561.663L664.407 537.243'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M685.171 516.57L706.255 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M647.41 561.663L664.407 544.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M688.946 520.327L713.494 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M654.648 561.663L664.407 552.271'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M692.721 524.093L721.043 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M662.199 561.663L664.407 559.475'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M696.496 527.531L728.594 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M699.96 531.288L730.482 500.913L735.833 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M703.735 535.045L730.482 508.427L743.384 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M707.51 538.811L730.481 515.95L750.622 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M711.285 542.568L730.482 523.145L758.172 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M715.061 546.006L730.482 530.668L765.723 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M718.836 549.763L730.482 538.182L772.962 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M722.299 553.52L730.481 545.386L780.511 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M726.074 557.286L730.482 552.9L788.062 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M729.85 561.043L730.482 560.414L795.301 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M796.877 501.543L802.851 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M796.877 509.057L810.411 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M796.877 516.57L817.64 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M796.877 523.774L825.2 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M796.877 531.288L832.75 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M796.877 538.811L839.98 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M796.877 546.006L847.539 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M796.877 553.52L855.089 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M796.877 561.043L862.328 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M863.271 502.482L869.878 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M863.271 509.686L877.117 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M863.271 517.2L884.667 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M863.271 524.714L892.217 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M863.271 531.917L899.456 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M863.271 539.431L907.006 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M863.271 546.945L914.557 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M863.271 554.149L921.795 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M863.271 561.663L929.346 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M929.978 561.663L995.74 496.217'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M996.052 495.907L996.373 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M937.528 561.663L995.741 503.731'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M999.827 499.664L1003.6 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M944.767 561.663L995.74 511.254'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1003.6 503.421L1011.16 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M952.317 561.663L995.741 518.449'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1007.07 507.178L1018.71 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M959.867 561.663L995.74 525.972'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1010.84 510.935L1025.95 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M967.106 561.663L995.741 533.486'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1014.62 514.382L1033.5 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M974.656 561.663L995.74 540.69'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1018.39 518.139L1041.05 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M982.207 561.663L995.741 548.204'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1022.18 521.896L1048.29 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M989.445 561.663L995.74 555.718'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1025.95 525.653L1055.84 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1029.41 529.41L1062.14 496.846L1063.39 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1033.18 533.167L1062.14 504.36L1070.63 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1036.96 536.614L1062.14 511.874L1078.18 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1040.74 540.371L1062.14 519.078L1085.73 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1044.52 544.128L1062.14 526.592L1092.97 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1047.97 547.885L1062.14 534.106L1100.52 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1051.75 551.641L1062.14 541.31L1108.07 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1055.53 555.408L1062.14 548.824L1115.31 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1059.3 558.845L1062.14 556.347L1122.86 495.597'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1064.02 561.663L1128.21 497.786L1129.15 496.846'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1071.26 561.663L1128.21 504.99L1132.93 500.293'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1078.81 561.663L1128.21 512.503L1136.7 504.05'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1086.36 561.663L1128.21 520.017L1140.49 507.807'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1093.6 561.663L1128.21 527.221L1144.26 511.564'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1101.15 561.663L1128.21 534.735L1148.04 515.321'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1108.7 561.663L1128.21 542.249L1151.49 518.768'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1115.94 561.663L1128.21 549.453L1155.28 522.525'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1123.49 561.663L1128.21 556.967L1159.05 526.282'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1131.04 561.663L1162.83 530.039'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.6 498.415L1195.86 497.156'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1138.28 561.663L1166.6 533.796'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.6 505.61L1199.63 500.603'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1145.83 561.663L1170.06 537.553'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.6 513.133L1203.42 504.36'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1153.38 561.663L1173.84 541'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.6 520.647L1207.19 508.117'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1160.62 561.663L1177.62 544.757'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.6 527.85L1210.65 511.874'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1168.17 561.663L1181.39 548.514'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.6 535.364L1214.42 515.631'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1175.73 561.663L1185.17 552.271'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.6 542.878L1218.21 519.388'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1182.96 561.663L1188.94 556.028'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.6 550.082L1221.98 522.835'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1190.52 561.663L1192.41 559.785'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.6 557.596L1225.76 526.592'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1197.75 561.663L1229.53 530.349'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1205.31 561.663L1233 534.106'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1212.86 561.663L1236.77 537.863'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1220.1 561.663L1240.55 541.629'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1227.65 561.663L1244.32 545.067'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1235.2 561.663L1248.1 548.824'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1242.44 561.663L1251.56 552.581'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1249.99 561.663L1255.34 556.347'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1257.54 561.663L1259.11 560.104'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 499.035L1262.26 497.476'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 506.549L1265.72 501.543'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 513.753L1268.86 505.929'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 521.267L1272.33 509.996'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 528.79L1275.47 514.072'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 535.984L1278.93 518.139'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 543.507L1282.08 522.206'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 551.021L1285.54 526.282'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 558.225L1288.69 530.349'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1264.77 561.663L1292.15 534.735'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1272.33 561.663L1295.3 538.811'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1279.88 561.663L1298.76 542.878'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1287.11 561.663L1301.9 546.945'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1294.66 561.663L1305.37 551.021'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1302.21 561.663L1308.51 555.088'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1309.45 561.663L1311.97 559.164'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M134.513 627.738L200.285 562.292'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M142.072 627.738L200.285 569.496'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M149.302 627.738L200.285 577.01'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M156.861 627.738L200.285 584.524'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M164.411 627.738L200.284 591.728'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M171.65 627.738L200.285 599.242'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M179.2 627.738L200.284 606.756'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M186.751 627.738L200.285 613.96'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M193.989 627.738L200.284 621.473'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M201.54 627.738L266.67 562.921'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M209.09 627.738L266.67 570.435'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M216.329 627.738L266.67 577.639'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M223.879 627.738L266.67 585.153'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M231.118 627.738L266.671 592.667'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M238.668 627.738L266.67 599.871'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M246.219 627.738L266.359 607.385'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M253.457 627.738L266.359 614.899'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M261.008 627.738L266.359 622.103'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M268.558 627.738L332.753 563.542L333.697 562.602'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M275.797 627.738L332.754 571.065L337.473 566.359'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M283.347 627.738L332.753 578.259L341.248 570.125'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M290.906 627.738L332.754 585.782L345.023 573.882'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M298.136 627.738L332.753 593.296L348.798 577.32'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M305.695 627.738L332.754 600.5L352.262 581.077'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M313.245 627.738L332.753 608.014L356.036 584.843'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M320.484 627.738L332.754 615.528L359.812 588.6'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M328.034 627.738L332.753 622.732L363.587 592.357'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M335.585 627.738L367.362 596.114'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M342.823 627.738L371.137 599.561'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M350.374 627.738L374.601 603.318'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M357.612 627.738L378.376 607.075'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M365.163 627.738L382.151 610.832'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M372.713 627.738L385.926 614.589'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M379.952 627.738L389.702 618.346'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M387.502 627.738L393.165 621.793'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M395.053 627.738L396.94 625.55'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M402.291 627.738L465.543 564.8'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M409.842 627.738L465.544 572.314'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M417.392 627.738L465.543 579.828'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M424.631 627.738L465.544 587.032'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M432.181 627.738L465.543 594.546'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M439.74 627.738L465.543 602.059'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M446.97 627.738L465.543 609.263'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M454.529 627.738L465.543 616.777'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M462.08 627.738L465.544 624.291'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M469.318 627.738L531.617 565.739L535.704 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M476.869 627.738L531.618 572.943L543.264 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M484.107 627.738L531.617 580.457L550.493 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M491.658 627.738L531.618 587.971L558.053 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M499.208 627.738L531.617 595.175L565.282 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M506.447 627.738L531.618 602.689L572.842 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M513.997 627.738L531.617 610.203L580.392 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M521.548 627.738L531.618 617.406L587.631 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M528.786 627.738L531.617 624.92L595.181 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 566.359L602.732 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 573.563L609.97 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 581.077L617.521 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 588.6L625.071 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 595.795L632.31 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 603.318L639.86 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 610.832L647.41 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 618.036L654.649 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 625.55L662.199 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M664.407 566.988L669.749 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M664.407 574.502L676.988 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M664.407 581.706L684.538 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M664.407 589.22L691.777 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M664.407 596.734L699.327 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M664.407 603.938L706.887 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M664.407 611.452L714.116 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M664.407 618.975L721.676 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M664.407 626.17L729.226 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.481 567.618L733.624 564.8'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.481 575.132L737.409 568.238'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.481 582.335L740.863 572.004'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.481 589.849L744.638 575.761'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.481 597.363L748.413 579.518'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.481 604.567L752.198 583.275'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.481 612.081L755.973 587.032'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.481 619.595L759.748 590.479'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.481 626.799L763.202 594.236'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M737.409 627.738L766.987 597.992'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M796.877 568.238L800.341 565.11'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M744.639 627.738L770.762 601.749'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M796.877 575.761L803.795 568.867'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M752.198 627.738L774.538 605.506'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M796.877 583.275L807.57 572.314'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M759.428 627.738L778.313 609.263'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M796.877 590.479L811.354 576.071'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M766.987 627.738L782.088 612.71'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M796.877 597.993L815.13 579.828'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M774.537 627.738L785.551 616.467'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M796.877 605.506L818.905 583.585'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M781.776 627.738L789.327 620.224'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M796.877 612.71L822.359 587.342'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M789.326 627.738L793.101 623.981'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M796.877 620.224L826.144 590.789'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M796.877 627.738L829.919 594.546'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M804.115 627.738L833.693 598.303'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M863.271 568.867L866.726 565.42'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M811.666 627.738L837.469 602.059'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M863.271 576.381L870.51 569.186'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M819.216 627.738L841.244 605.816'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M863.271 583.904L874.285 572.624'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M826.455 627.738L844.708 609.573'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M863.271 591.099L878.061 576.381'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M834.005 627.738L848.482 613.02'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M863.271 598.622L881.836 580.138'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M841.556 627.738L852.258 616.777'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M863.271 606.136L885.299 583.904'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M848.794 627.738L856.033 620.534'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M863.271 613.33L889.074 587.661'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M856.344 627.738L859.807 624.291'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M863.271 620.853L892.85 591.418'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M863.895 627.738L896.625 594.856'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M871.134 627.738L900.4 598.622'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M878.684 627.738L904.175 602.379'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M885.922 627.738L907.638 606.136'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M893.473 627.738L911.414 609.893'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M901.032 627.738L915.189 613.65'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M908.262 627.738L918.964 617.096'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M915.821 627.738L922.739 620.853'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M923.371 627.738L926.202 624.61'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M133.89 628.367L134.202 628.048'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M133.89 635.571L137.976 631.495'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M133.89 643.085L141.751 635.252'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M133.89 650.289L145.527 639.009'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M133.89 657.803L148.99 642.766'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M133.89 665.317L152.765 646.532'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M133.89 672.521L156.541 650.289'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M133.89 680.035L160.316 653.727'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M133.89 687.549L164.091 657.484'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M135.145 693.503L167.875 661.25'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M142.695 693.503L171.33 665.007'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M150.255 693.503L175.105 668.764'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M157.484 693.503L178.88 672.521'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M165.034 693.503L182.664 675.968'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M172.273 693.503L186.439 679.725'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M179.823 693.503L189.893 683.482'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M187.383 693.503L193.669 687.238'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M194.612 693.503L197.453 690.995'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M202.172 693.503L266.359 629.617L268.558 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M209.723 693.503L266.359 637.131L275.797 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M216.961 693.503L266.359 644.334L283.347 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M224.512 693.503L266.359 651.848L290.907 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M232.062 693.503L266.359 659.371L298.136 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M239.301 693.503L266.359 666.566L305.696 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M246.851 693.503L266.359 674.089L313.246 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M254.401 693.503L266.359 681.603L320.485 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M261.64 693.503L266.359 688.807L328.035 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M269.189 693.503L332.753 630.246L334.008 628.997'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M276.74 693.503L332.754 637.76L337.784 632.754'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M283.979 693.503L332.753 644.964L341.559 636.51'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M291.529 693.503L332.754 652.478L345.343 639.948'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M298.768 693.503L332.753 659.991L349.118 643.714'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M306.318 693.503L332.754 667.195L352.894 647.471'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M313.868 693.503L332.753 674.709L356.348 651.228'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M321.107 693.503L332.754 682.223L360.123 654.985'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M328.657 693.503L332.753 689.427L363.907 658.432'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M336.217 693.503L367.682 662.189'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M400.715 629.307L402.291 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M343.446 693.503L371.458 665.946'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M404.49 633.064L409.841 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M351.006 693.503L374.912 669.703'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M408.274 636.821L417.392 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M358.557 693.503L378.697 673.46'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M412.05 640.267L424.631 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M365.795 693.503L382.472 677.217'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M415.504 644.024L432.181 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M373.346 693.503L386.247 680.664'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M419.279 647.781L439.74 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M380.896 693.503L390.022 684.421'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M423.063 651.538L446.97 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M388.135 693.503L393.797 688.178'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M426.839 655.295L454.529 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M395.685 693.503L397.261 691.935'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M430.614 659.052L462.08 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M434.39 662.499L465.544 631.495'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M437.853 666.256L465.543 639.009'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M441.628 670.013L465.543 646.532'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M445.403 673.77L465.544 653.727'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M449.179 677.527L465.544 661.25'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M452.953 681.284L465.543 668.764'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M456.417 684.731L465.543 675.968'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M460.192 688.488L465.544 683.482'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M463.968 692.245L465.544 690.995'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M469.941 693.503L531.617 632.434'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M477.492 693.503L531.618 639.638'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M485.052 693.503L531.618 647.152'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M492.281 693.503L531.618 654.356'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M499.841 693.503L531.618 661.87'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M507.391 693.503L531.618 669.384'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M514.629 693.503L531.617 676.588'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M522.18 693.503L531.618 684.102'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M529.73 693.503L531.618 691.625'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M536.969 693.503L598.012 633.064'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M600.532 630.246L603.364 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M544.52 693.503L598.013 640.267'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M604.308 634.003L610.593 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M551.758 693.503L598.013 647.781'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M608.083 637.76L618.153 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M559.309 693.503L598.013 655.295'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M611.857 641.517L625.703 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M566.858 693.503L598.013 662.499'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M615.633 645.274L632.942 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M574.097 693.503L598.012 670.013'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M619.097 648.721L640.492 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M581.647 693.503L598.013 677.527'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M622.872 652.478L648.043 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M589.197 693.503L598.012 684.731'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M626.646 656.235L655.281 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M596.437 693.503L598.013 692.245'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M630.422 659.991L662.831 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M634.197 663.748L664.408 633.693L670.382 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M637.973 667.195L664.408 641.207L677.621 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M641.436 670.952L664.407 648.411L685.17 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M645.211 674.709L664.407 655.924L692.721 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M648.986 678.466L664.408 663.128L699.96 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M652.762 682.223L664.408 670.642L707.511 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M656.536 685.98L664.407 678.156L715.06 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M660.312 689.427L664.407 685.36L722.299 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M663.775 693.184L664.408 692.874L729.85 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M671.014 693.503L730.481 634.313L737.409 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M678.253 693.503L730.482 641.827L744.639 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M685.803 693.503L730.481 649.031L752.198 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M693.354 693.503L730.482 656.545L759.428 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M700.592 693.503L730.481 664.068L766.987 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M708.143 693.503L730.482 671.262L774.537 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M715.692 693.503L730.481 678.785L781.776 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M722.932 693.503L730.482 686.299L789.327 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M730.481 693.503L796.876 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M738.032 693.503L796.877 634.942L804.116 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M745.271 693.503L796.876 642.456L811.665 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M752.821 693.503L796.877 649.97L819.216 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M760.371 693.503L796.876 657.174L826.454 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M767.61 693.503L796.877 664.688L834.005 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M775.16 693.503L796.876 671.892L841.555 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M782.72 693.503L796.877 679.405L848.794 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M789.949 693.503L796.876 686.919L856.344 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M863.583 628.048L863.895 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M867.047 631.814L871.134 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M870.822 635.252L878.684 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M874.597 639.009L885.922 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M878.372 642.766L893.473 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M882.147 646.532L901.032 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M885.61 650.289L908.261 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M889.386 654.046L915.821 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M893.161 657.484L923.371 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M896.937 661.25L929.346 628.997'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M996.996 561.663L995.741 562.921L930.61 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M900.711 665.007L929.345 636.51'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1004.55 561.663L995.74 570.435L938.16 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M904.486 668.764L929.345 643.714'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1011.79 561.663L995.741 577.639L945.711 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M907.95 672.521L929.346 651.228'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1019.33 561.663L995.74 585.153L952.949 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M911.726 675.968L929.346 658.432'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1026.89 561.663L995.741 592.667L960.5 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M915.5 679.725L929.345 665.946'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1034.12 561.663L995.74 599.871L968.05 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M919.275 683.482L929.345 673.46'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1041.68 561.663L995.741 607.385L975.289 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M923.051 687.238L929.346 680.664'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1049.23 561.663L995.74 614.899L982.839 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M926.826 690.995L929.346 688.178'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1056.47 561.663L995.741 622.103L990.39 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M931.554 693.503L995.74 629.617'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1062.14 563.542L1064.02 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M995.74 629.617L997.628 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M938.783 693.503L995.74 637.131'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1062.14 571.065L1071.26 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M995.74 637.131L1005.18 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M946.343 693.503L995.74 644.334'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1062.14 578.578L1078.81 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M995.74 644.334L1012.42 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M953.572 693.503L995.74 651.848'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1062.14 585.782L1086.36 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M995.74 651.848L1019.97 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M961.132 693.503L995.74 659.371'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1062.14 593.296L1093.6 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M995.74 659.371L1027.52 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M968.683 693.503L995.741 666.566'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1062.14 600.81L1101.15 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M995.74 666.566L1034.76 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M975.921 693.503L995.74 674.089'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1062.14 608.014L1108.7 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M995.74 674.089L1042.31 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M983.472 693.503L995.741 681.603'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1062.14 615.528L1115.94 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M995.74 681.603L1049.86 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M991.021 693.503L995.74 688.807'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1062.14 623.042L1123.49 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M995.74 688.807L1057.1 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1063.39 628.997L1064.65 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1128.21 564.171L1131.04 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1067.17 632.754L1072.21 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1128.21 571.685L1138.28 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1070.63 636.511L1079.44 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1128.21 579.199L1145.83 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1074.41 639.948L1087 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1128.21 586.402L1153.38 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1078.18 643.714L1094.54 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1128.21 593.916L1160.62 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1081.96 647.471L1101.78 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1128.21 601.439L1168.17 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1085.73 651.228L1109.33 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1128.21 608.634L1175.73 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1089.51 654.985L1116.88 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1128.21 616.157L1182.96 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1092.97 658.742L1124.12 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1128.21 623.671L1190.52 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1096.74 662.189L1128.21 630.875L1131.67 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1100.52 665.946L1128.21 638.389L1138.91 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1104.29 669.703L1128.21 645.903L1146.46 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1108.07 673.46L1128.21 653.107L1154.01 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1111.53 677.217L1128.21 660.621L1161.25 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1115.31 680.974L1128.21 668.135L1168.8 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1119.08 684.421L1128.21 675.339L1176.35 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1122.86 688.178L1128.21 682.852L1183.59 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1126.64 691.935L1128.21 690.366L1191.14 627.738'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1132.31 693.503L1194.6 631.814'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 565.739L1264.78 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.6 631.814L1196.49 629.617'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1198.69 627.738L1261 565.739'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1139.86 693.503L1194.6 639.009'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 572.943L1272.33 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.6 639.009L1200.27 633.374'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1205.93 627.738L1261 572.943'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1147.09 693.503L1194.6 646.532'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 580.457L1279.88 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.6 646.532L1204.04 637.131'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1213.48 627.738L1261 580.457'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1154.64 693.503L1194.6 654.046'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 587.971L1287.11 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.6 654.046L1207.82 640.887'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1221.04 627.738L1261 587.971'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1162.2 693.503L1194.6 661.25'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 595.175L1294.66 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.6 661.25L1211.59 644.334'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1228.27 627.738L1261 595.175'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1169.43 693.503L1194.6 668.764'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 602.689L1302.22 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.6 668.764L1215.06 648.091'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1235.83 627.738L1261 602.689'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1176.98 693.503L1194.6 675.968'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 610.203L1309.45 561.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.6 675.968L1218.83 651.848'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1243.38 627.738L1261 610.203'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1184.53 693.503L1194.6 683.482'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 617.406L1301.59 577.01'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.6 683.482L1222.61 655.605'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1250.62 627.738L1261 617.406'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1191.77 693.503L1194.6 690.995'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 624.92L1271.69 613.96'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.6 690.995L1226.38 659.371'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1258.17 627.738L1261 624.92'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1199.32 693.503L1230.17 663.128'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1206.56 693.503L1233.94 666.566'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1214.11 693.503L1237.4 670.323'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1221.66 693.503L1241.17 674.089'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1228.9 693.503L1244.95 677.846'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1236.45 693.503L1248.73 681.603'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1244 693.503L1252.51 685.36'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1251.24 693.503L1255.96 688.807'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1258.79 693.503L1259.73 692.564'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 632.434L1262.89 630.246'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 639.638L1266.35 634.313'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 647.152L1269.49 638.389'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 654.666L1272.96 642.456'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 661.87L1276.1 646.842'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 669.384L1279.56 650.909'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 676.907L1282.71 654.985'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 684.102L1286.17 659.052'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1261 691.625L1289.31 663.128'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1266.35 693.503L1292.78 667.195'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1273.58 693.503L1295.93 671.262'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1281.14 693.503L1299.38 675.648'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1288.69 693.503L1302.54 679.725'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1295.93 693.503L1306 683.792'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1303.48 693.503L1309.14 687.859'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1311.03 693.503L1312.61 691.935'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M135.777 759.569L200.285 695.692L201.228 694.442'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M143.327 759.569L200.284 702.896L205.003 698.199'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M150.878 759.569L200.285 710.409L208.779 701.956'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M158.116 759.569L200.284 717.923L212.242 705.713'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M165.667 759.569L200.285 725.127L216.018 709.47'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M173.217 759.569L200.284 732.641L219.792 713.227'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M180.456 759.569L200.285 740.155L223.568 716.674'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M188.006 759.569L200.284 747.359L227.343 720.431'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M195.565 759.569L200.284 754.873L231.118 724.188'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M202.795 759.569L234.581 727.945'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M267.935 695.062L269.19 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M210.354 759.569L238.357 731.702'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M271.398 698.509L276.74 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M217.904 759.569L242.131 735.459'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M275.174 702.266L283.979 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M225.144 759.569L245.907 738.906'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M278.948 706.023L291.529 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M232.693 759.569L249.682 742.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M282.724 709.78L298.768 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M239.924 759.569L253.146 746.42'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M286.499 713.537L306.319 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M247.482 759.569L256.92 750.177'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M290.274 717.294L313.869 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M255.033 759.569L260.696 753.934'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M293.737 720.741L321.107 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M262.271 759.569L264.471 757.691'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M297.513 724.498L328.658 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M301.288 728.255L332.754 696.941'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M305.062 732.012L332.753 704.464'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M308.838 735.778L332.753 711.659'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M312.613 739.216L332.753 719.182'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M316.077 742.973L332.754 726.696'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M319.852 746.73L332.753 733.9'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M323.627 750.496L332.753 741.413'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M327.402 754.253L332.754 748.617'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M331.178 758.01L332.754 756.131'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M336.84 759.569L399.148 697.57L403.235 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M344.391 759.569L399.149 705.084L410.474 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M351.629 759.569L399.148 712.288L418.024 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M359.18 759.569L399.149 719.802L425.263 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M366.418 759.569L399.148 727.316L432.813 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M373.969 759.569L399.149 734.52L440.364 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M381.528 759.569L399.149 742.034L447.603 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M388.758 759.569L399.149 749.557L455.153 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M396.317 759.569L399.149 756.751L462.703 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M465.543 698.199L467.742 696.002'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M465.543 705.713L471.517 699.759'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M465.543 713.227L475.292 703.206'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M465.543 720.431L478.756 706.963'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M465.223 727.945L482.532 710.72'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M465.223 735.459L486.307 714.476'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M465.223 742.663L490.082 718.243'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M465.223 750.177L493.857 722'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M465.223 757.381L497.632 725.437'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M470.886 759.569L501.096 729.194'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M531.617 698.819L534.449 696.321'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M478.124 759.569L504.871 732.96'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M531.617 706.342L538.224 700.078'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M485.675 759.569L508.646 736.717'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M531.617 713.856L541.687 703.835'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M492.913 759.569L512.421 740.474'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M531.617 721.06L545.462 707.282'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M500.464 759.569L516.197 744.231'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M531.617 728.574L549.238 711.039'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M508.014 759.569L519.66 747.669'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M531.617 736.088L553.013 714.796'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M515.253 759.569L523.435 751.435'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M531.617 743.292L556.788 718.553'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M522.803 759.569L527.21 755.192'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M531.617 750.806L560.251 722.31'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M530.362 759.568L530.986 758.949'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M531.617 758.32L564.027 726.066'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M537.592 759.569L567.802 729.513'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 699.759L603.987 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M545.151 759.569L571.577 733.27'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 706.963L611.537 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M552.701 759.569L575.352 737.027'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 714.476L618.776 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M559.94 759.569L579.128 740.784'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 721.999L626.326 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M567.49 759.569L582.591 744.541'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 729.194L633.886 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M575.041 759.569L586.366 747.988'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 736.717L641.115 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M582.279 759.569L590.141 751.745'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 743.912L648.675 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M589.83 759.569L593.917 755.502'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 751.435L656.225 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M597.38 759.569L597.692 759.259'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M598.013 758.949L663.464 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M671.637 759.569L730.481 701.017L738.032 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M679.196 759.569L730.481 708.531L745.271 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M686.426 759.569L730.481 715.735L752.821 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M693.985 759.569L730.482 723.249L760.371 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M701.536 759.569L730.482 730.763L767.61 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M708.774 759.569L730.482 737.967L775.16 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M716.325 759.569L730.482 745.48L782.72 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M723.875 759.569L730.482 752.684L789.949 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M796.877 694.122L797.51 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M796.877 701.637L805.059 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M796.877 709.16L812.298 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M796.877 716.355L819.849 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M796.877 723.878L827.087 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M796.877 731.392L834.638 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M796.877 738.596L842.188 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M796.877 746.11L849.427 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M796.877 753.624L856.977 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M863.271 695.062L864.527 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M863.271 702.266L871.766 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M863.271 709.78L879.316 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M863.271 717.294L886.866 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M863.271 724.498L894.105 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M862.951 732.012L901.656 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M862.951 739.535L909.206 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M862.951 746.73L916.445 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M862.951 754.253L923.995 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M929.346 695.692L930.29 694.752'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M929.346 702.896L934.065 698.199'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M929.346 710.409L937.84 701.956'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M929.346 717.923L941.615 705.713'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M929.346 725.127L945.399 709.47'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M929.346 732.641L948.854 713.227'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M929.346 740.155L952.629 716.984'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M929.346 747.359L956.404 720.431'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M929.346 754.873L960.188 724.188'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M932.177 759.569L963.963 727.945'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M995.74 696.321L996.996 695.062'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M939.416 759.569L967.739 731.702'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M995.74 703.835L1000.77 698.819'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M946.966 759.569L971.193 735.459'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M995.74 711.039L1004.55 702.266'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M954.517 759.569L974.978 738.906'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M995.74 718.553L1008.33 706.023'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M961.755 759.569L978.752 742.663'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M995.74 726.067L1011.78 709.78'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M969.306 759.569L982.528 746.42'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M995.74 733.27L1015.56 713.537'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M976.865 759.569L986.303 750.177'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M995.74 740.784L1019.33 717.294'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M984.095 759.569L990.078 753.934'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M995.74 747.988L1023.12 720.741'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M991.654 759.569L993.542 757.691'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M995.74 755.502L1026.89 724.498'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M999.204 759.569L1030.35 728.255'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1062.14 696.941L1065.28 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1006.44 759.569L1034.12 732.012'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1062.14 704.464L1072.83 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1013.99 759.569L1037.91 735.778'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1062.14 711.659L1080.07 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1021.23 759.569L1041.68 739.535'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1062.14 719.182L1087.62 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1028.78 759.569L1045.46 742.973'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1061.81 726.696L1095.17 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1036.33 759.569L1049.23 746.73'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1061.81 733.9L1102.41 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1043.57 759.569L1052.7 750.496'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1061.81 741.413L1109.96 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1051.12 759.569L1056.47 754.253'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1061.81 748.927L1117.52 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1058.67 759.569L1060.25 758.01'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1061.81 756.131L1124.75 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1128.21 697.57L1132.31 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1128.21 705.084L1139.86 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1128.21 712.598L1147.09 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1128.21 719.802L1154.65 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1128.21 727.316L1162.2 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1128.21 734.839L1169.43 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1128.21 742.034L1176.98 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1128.21 749.557L1184.53 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1128.21 756.751L1191.77 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.6 698.199L1199.32 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.6 705.713L1206.56 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.6 713.227L1214.11 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.6 720.431L1221.66 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.6 727.945L1228.9 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.6 735.459L1236.45 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.6 742.663L1244 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.6 750.177L1251.24 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <motion.path
          variants={pathVariants}
          d='M1194.6 757.691L1258.79 693.503'
          stroke='#D6D6D6'
          strokeWidth='1'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_1760_4024'>
          <rect width='1320' height='760' fill='white' transform='translate(0 0.982513)' />
        </clipPath>
      </defs>
    </motion.svg>
  );
}
