const navlinks = [
  { text: "Work", link: "/work" },
  { text: "Practice", link: "/practice" },
  { text: "News", link: "/news" },
  { text: "Stories", link: "/stories" },
  { text: "Contact", link: "/contact" },
];
const slideData = [
  {
    title: "Suchitra J Y",
    content:
      "  Working with Deepa Suriyaprakash and the Betweenlines team has been a joyful home-creation experience for me. Right from my first meeting with Deepa, when I was exploring options for two home interior projects, I got excellent vibes and instinctively I knew that I had got the right person. Deepa gets the essence of what you are trying to achieve with every single corner of the house and executes it beautifully. No idea that you throw at her is discarded without being given due consideration. However outlandish our scheme, she always heard us out and gave us her view on it with details of technical and financial feasability, and practical pros and cons. I would gladly take another journey with this team should the opportunity arise in the future. Deepa came up with excellent ideas on how to integrate many of our existing pieces into a new domain, and thanks to that we are happily in a new place that will always be reminiscent of our past.",
    subText: "House of Relics, Bengaluru",
  },
  {
    title: "Suchitra J Y",
    content:
      "  Working with Deepa Suriyaprakash and the Betweenlines team has been a joyful home-creation experience for me. Right from my first meeting with Deepa, when I was exploring options for two home interior projects, I got excellent vibes and instinctively I knew that I had got the right person. Deepa gets the essence of what you are trying to achieve with every single corner of the house and executes it beautifully. No idea that you throw at her is discarded without being given due consideration. However outlandish our scheme, she always heard us out and gave us her view on it with details of technical and financial feasability, and practical pros and cons. I would gladly take another journey with this team should the opportunity arise in the future. Deepa came up with excellent ideas on how to integrate many of our existing pieces into a new domain, and thanks to that we are happily in a new place that will always be reminiscent of our past.",
    subText: "House of Relics, Bengaluru",
  },
  {
    title: "Suchitra J Y",
    content:
      "  Working with Deepa Suriyaprakash and the Betweenlines team has been a joyful home-creation experience for me. Right from my first meeting with Deepa, when I was exploring options for two home interior projects, I got excellent vibes and instinctively I knew that I had got the right person. Deepa gets the essence of what you are trying to achieve with every single corner of the house and executes it beautifully. No idea that you throw at her is discarded without being given due consideration. However outlandish our scheme, she always heard us out and gave us her view on it with details of technical and financial feasability, and practical pros and cons. I would gladly take another journey with this team should the opportunity arise in the future. Deepa came up with excellent ideas on how to integrate many of our existing pieces into a new domain, and thanks to that we are happily in a new place that will always be reminiscent of our past.",
    subText: "House of Relics, Bengaluru",
  },
  {
    title: "Suchitra J Y",
    content:
      "  Working with Deepa Suriyaprakash and the Betweenlines team has been a joyful home-creation experience for me. Right from my first meeting with Deepa, when I was exploring options for two home interior projects, I got excellent vibes and instinctively I knew that I had got the right person. Deepa gets the essence of what you are trying to achieve with every single corner of the house and executes it beautifully. No idea that you throw at her is discarded without being given due consideration. However outlandish our scheme, she always heard us out and gave us her view on it with details of technical and financial feasability, and practical pros and cons. I would gladly take another journey with this team should the opportunity arise in the future. Deepa came up with excellent ideas on how to integrate many of our existing pieces into a new domain, and thanks to that we are happily in a new place that will always be reminiscent of our past.",
    subText: "House of Relics, Bengaluru",
  },
  {
    title: "Suchitra J Y",
    content:
      "  Working with Deepa Suriyaprakash and the Betweenlines team has been a joyful home-creation experience for me. Right from my first meeting with Deepa, when I was exploring options for two home interior projects, I got excellent vibes and instinctively I knew that I had got the right person. Deepa gets the essence of what you are trying to achieve with every single corner of the house and executes it beautifully. No idea that you throw at her is discarded without being given due consideration. However outlandish our scheme, she always heard us out and gave us her view on it with details of technical and financial feasability, and practical pros and cons. I would gladly take another journey with this team should the opportunity arise in the future. Deepa came up with excellent ideas on how to integrate many of our existing pieces into a new domain, and thanks to that we are happily in a new place that will always be reminiscent of our past.",
    subText: "House of Relics, Bengaluru",
  },
];

const runningText = [
  "Nalli",
  "Raasi Builders",
  "Co-optex",
  "TNHDC",
  "Navgati",
  "Nandakumar Law Associates",
  "Hands of India",
  "HSBC",
  "Techno Orbit",
  "Rang De",
];

const naviOptions = [
  { id: 1, name: "All Projects" },
  { id: 2, name: "Architecture" },
  { id: 3, name: "Interiors" },
  { id: 4, name: "Urban" },
  { id: 5, name: "Products" },
  { id: 6, name: "Others" },
];
const newsNavi = [
  { id: 1, name: "Awards" },
  { id: 2, name: "Publications" },
  { id: 3, name: "Events" },
];

const secondaryFilters = [
  "Residential",
  "Commercial",
  "Hospitality",
  "Institutional",
  "Workspace",
  "Culture",
  "Housing",
  "Sports",
  "Industrial",
];

const secondaryNaviOptions = ["products", "urban", "others"];
const secondaryFiltersExtra = {
  products: ["Fixtures", "Furnitures"],
  urban: ["Planning", "Infrastructure", "Design", "Initiatives"],
  others: [],
};
const excludedFilters = ["all projects"];

// animation constants

const animationProps = {
  initial: "hidden",
  whileInView: "visible",
  viewport: { once: true },
};
const appearVariant = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};
const imageShutterVariant = {
  visible: { clipPath: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)" },
  hidden: { clipPath: "polygon(0 0, 100% 0, 100% 0%, 0% 0%)" },
};
const textShutterVariant = {
  visible: { clipPath: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)" },
  hidden: { clipPath: "polygon(0 100%, 100% 100%, 100% 100%, 0% 100%)" },
};

const parentStagVariants = {
  initial: {},
  animate: {
    transition: {
      staggerChildren: 0.16,
    },
  },
};

const childStagVariants = {
  initial: { opacity: 0, y: 0 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -20 },
};
const imageShutterDuration = 0.8;
const appearDuration = 1.3;
const textShutterDuration = 0.6;

export {
  parentStagVariants,
  childStagVariants,
  animationProps,
  appearVariant,
  imageShutterVariant,
  textShutterVariant,
  imageShutterDuration,
  appearDuration,
  textShutterDuration,
};

export {
  newsNavi,
  naviOptions,
  secondaryNaviOptions,
  secondaryFiltersExtra,
  navlinks,
  slideData,
  runningText,
  secondaryFilters,
  excludedFilters,
};
