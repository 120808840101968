import React from "react";
import styles from "./styles.module.css";
import { useWindowSize } from "@uidotdev/usehooks";
import { motion } from "framer-motion";
import {
  textShutterDuration,
  textShutterVariant,
  appearDuration,
  appearVariant,
  animationProps,
} from "../../../utils/constants";

export default function Contact() {
  const size = useWindowSize();
  return (
    <div className={styles.root}>
      <div className={styles.upperDiv}>
        <div className={styles.sideContent}>
          <motion.p
            {...animationProps}
            transition={{ duration: textShutterDuration }}
            variants={textShutterVariant}
            className={styles.upperContent}
          >
            We’d love to hear from you.
          </motion.p>
        </div>
        <motion.form
          {...animationProps}
          transition={{ duration: appearDuration }}
          variants={appearVariant}
          action='https://formspree.io/f/xdoqweol'
          method='POST'
          id='myForm'
          className={styles.formContent}
        >
          <p className={styles.email}>contact@betweenlines.in</p>
          <p className={styles.or}>OR</p>
          <div className={styles.formOuter}>
            <div className={styles.form}>
              <aside className={styles.aside}>
                <input type='text' name='name' placeholder='Name' className={styles.formInput} required />
                <input type='text' name='phone' placeholder='Phone Number (optional)' className={styles.formInput} />
              </aside>
              <aside className={styles.aside}>
                <input type='text' name='subject' className={styles.formInput} placeholder='Subject' required />{" "}
                <input type='email' name='email' placeholder='Email ID' className={styles.formInput} required />
              </aside>
            </div>
            <div>
              <aside className={styles.asideTextarea}>
                <textarea
                  name='message'
                  id=''
                  cols=''
                  rows='10'
                  placeholder='Type your message here'
                  className={styles.textArea}
                  required
                ></textarea>
                <button type='submit' className={styles.sendBtn}>
                  Send
                </button>
              </aside>
            </div>
          </div>
        </motion.form>
      </div>
    </div>
  );
}
