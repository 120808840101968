import React from "react";

function Grid({ fill }) {
  return (
    <svg width='20' height='16' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='Group 11'>
        <rect id='Rectangle 8' width='8.20503' height='6.48105' fill={fill} />
        <rect id='Rectangle 10' x='11.7949' width='8.20503' height='6.48105' fill={fill} />
        <rect id='Rectangle 9' y='9.51904' width='8.20503' height='6.48105' fill={fill} />
        <rect id='Rectangle 11' x='11.7949' y='9.51904' width='8.20503' height='6.48105' fill={fill} />
      </g>
    </svg>
  );
}

export default Grid;
