import React, { createContext, useState } from "react";

export const DataUpdateContext = createContext({
  miscData: {
    news: [],
    practice: [],
    stories: [],
    homepage: [],
  },
  entries: [],
  isLoading: false,
  setIsLoading: () => undefined,
  setEntries: () => undefined,
  setMiscData: () => undefined,
});

export const DataUpdateProvider = ({ children }) => {
  const [entries, setEntries] = useState([]);
  const [miscData, setMiscData] = useState({
    news: [],
    practice: [],
    stories: [],
    homepage: [],
  });
  const [isLoading, setIsLoading] = useState(true);

  return (
    <DataUpdateContext.Provider
      value={{
        entries,
        miscData,
        isLoading,
        setMiscData,
        setEntries,
        setIsLoading,
      }}
    >
      {children}
    </DataUpdateContext.Provider>
  );
};

export const useDataUpdate = () => React.useContext(DataUpdateContext);
