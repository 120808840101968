import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import {
  naviOptions,
  secondaryFilters,
  excludedFilters,
  secondaryFiltersExtra,
  secondaryNaviOptions,
  parentStagVariants,
  childStagVariants,
} from "../../../utils/constants";
import Grid from "../../../assets/svg/Grid";
import List from "../../../assets/svg/List";
import { useNavigate } from "react-router-dom";

import { useWindowSize } from "@uidotdev/usehooks";
import { AnimatePresence, motion } from "framer-motion";
import Down from "../../../assets/svg/Down";
import Up from "../../../assets/svg/Up";
import { getLocalStorageItem, setLocalStorageItem } from "../../../utils/helpers";
import { useDataUpdate } from "../../../contexts/DataUpdateContext";

function FilterComponent({ setFilteredData, size }) {
  const { entries } = useDataUpdate();
  const [selectedFilter, setSelectedFilter] = useState("all projects");
  const [secondaryFilter, setSecondaryFilter] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    const obj = getLocalStorageItem("persist");
    setSelectedFilter(obj?.primary?.toLowerCase());
    setSecondaryFilter(obj?.secondary?.toLowerCase());
  }, []);

  useEffect(() => {
    const filteredData = entries.filter((item) => {
      const { field } = item.fields;

      if (selectedFilter === "all projects") return true;

      return field?.primaryType.some((type) => {
        if (selectedFilter.includes(type.toLowerCase())) {
          if (secondaryFilter === null || secondaryFilter === undefined) return true;

          return field?.secondaryType.some((sType) => {
            return sType.toLowerCase().includes(secondaryFilter);
          });
        }
        return false;
      });
    });
    setFilteredData(filteredData);
  }, [selectedFilter, secondaryFilter, setFilteredData]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <motion.div className={styles.filtersRoot}>
      <motion.div variants={parentStagVariants} initial='initial' animate='animate' className={styles.filters}>
        {size.width > 1024 &&
          naviOptions.map((option) => (
            <motion.button
              variants={childStagVariants}
              key={option.id}
              onClick={() => {
                setSelectedFilter(option.name.toLowerCase());
                setSecondaryFilter(null);
                setLocalStorageItem("persist", {
                  primary: option.name.toLowerCase(),
                  secondary: null,
                });
              }}
              className={`${selectedFilter === option.name.toLowerCase() && styles.activeSelectedFilter} ${
                styles.selectedFilter
              }`}
            >
              {option.name}
            </motion.button>
          ))}

        {size.width <= 1024 && ( // Check screen width for mobile view
          <div className={styles.dropDown}>
            <button onClick={toggleDropdown} className={styles.dropDownTitle}>
              {selectedFilter
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")}

              {isDropdownOpen ? <Up /> : <Down />}
            </button>
            <AnimatePresence>
              {isDropdownOpen && (
                <motion.div
                  initial={{ opacity: 0, y: -50 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -50 }}
                  transition={{ duration: 0.1, ease: "easeOut" }}
                >
                  <div className={styles.dropDownContent}>
                    {naviOptions.map((option) => (
                      <button
                        key={option.id}
                        onClick={() => {
                          setSelectedFilter(option.name.toLowerCase());
                          setSecondaryFilter(null);
                          setLocalStorageItem("persist", {
                            primary: option.name.toLowerCase(),
                            secondary: null,
                          });
                          toggleDropdown();
                        }}
                        className={`${
                          selectedFilter.includes(option.name.toLowerCase()) && styles.activeSelectedFilter
                        } ${styles.dropDownButtons}`}
                      >
                        {option.name}
                      </button>
                    ))}
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        )}
      </motion.div>

      {!excludedFilters.includes(selectedFilter) && (
        <div
          className={styles.filters}
          style={{
            width: "100%",
          }}
        >
          <AnimatePresence>
            {(secondaryNaviOptions.includes(selectedFilter)
              ? secondaryFiltersExtra[selectedFilter]
              : secondaryFilters
            ).map((filter, idx) => (
              <motion.button
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -50 }}
                transition={{ duration: 0.1, ease: "easeOut" }}
                key={idx}
                onClick={() => {
                  setSecondaryFilter(filter.toLowerCase());
                  setLocalStorageItem("persist", {
                    primary: selectedFilter,
                    secondary: filter.toLowerCase(),
                  });
                }}
                className={`${secondaryFilter === filter.toLowerCase() && styles.activeSecondaryFilter} ${
                  styles.secondaryFilter
                }`}
              >
                {filter}
              </motion.button>
            ))}
          </AnimatePresence>
        </div>
      )}
    </motion.div>
  );
}

function ListView({ filteredData, size, handleProjectClick }) {
  const [state, setState] = useState({
    id: null,
    hover: false,
  });

  const vari = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: {
        duration: 0.3,
        ease: "easeInOut",
      },
    },
    exit: {
      opacity: 0,
    },
  };

  return (
    <motion.div variants={parentStagVariants} initial='initial' animate='animate' className={styles.listView}>
      {filteredData
        .sort((a, b) => parseInt(b.fields.field.year) - parseInt(a.fields.field.year))
        .map((data, idx) => {
          const { field, carouselImages } = data.fields;

          return (
            <motion.div
              key={idx}
              className={styles.listItem}
              onClick={() => handleProjectClick(field.name)}
              onMouseLeave={() => {
                setState({
                  id: null,
                  hover: false,
                });
              }}
              onMouseEnter={() => {
                setState({
                  id: idx,
                  hover: true,
                });
              }}
              variants={childStagVariants}
            >
              <div className={styles.content_year}>{field.year}</div>
              <span className={styles.content}>
                {field.name}
                {", "}
                {field?.location}
              </span>
              <AnimatePresence>
                {state.id === idx && size.width > 1024 && (
                  <motion.div
                    className={styles.hoverImage}
                    initial='initial'
                    animate='animate'
                    exit='exit'
                    variants={vari}
                  >
                    <img src={carouselImages[0]?.fields?.file?.url} alt='' />
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          );
        })}
    </motion.div>
  );
}
function GridView({ filteredData, handleProjectClick }) {
  return (
    <motion.div variants={parentStagVariants} initial='initial' animate='animate' className={styles.gridView}>
      {filteredData
        .sort((a, b) => parseInt(b.fields.field.year) - parseInt(a.fields.field.year))
        .map((data, idx) => {
          const { field, carouselImages } = data.fields;

          return (
            <motion.div
              variants={childStagVariants}
              key={idx}
              className={styles.gridItem}
              onClick={() => handleProjectClick(field.name)}
            >
              <div className={styles.image}>
                <img className={styles.imageCover} src={carouselImages[0]?.fields?.file?.url} alt='image' />
              </div>
              <span className={styles.content}>
                <p>{field.name}</p>
                <p>{field.year}</p>
              </span>
            </motion.div>
          );
        })}
    </motion.div>
  );
}

export default function Work() {
  const { entries } = useDataUpdate();
  const size = useWindowSize();
  const [filteredData, setFilteredData] = useState(entries);
  const [activeView, setActiveView] = useState("grid");

  const navigate = useNavigate(); // Initialize useNavigate hook

  const handleProjectClick = (projectName) => {
    const projectNameLowerCase = projectName.toLowerCase();
    navigate(`/project?name=${encodeURIComponent(projectNameLowerCase)}`);
  };

  return (
    <div className={styles.root}>
      <FilterComponent setFilteredData={setFilteredData} size={size} />

      <div>
        <div className={styles.switchView}>
          <span onClick={() => setActiveView("list")}>
            <p>
              <List fill={activeView === "list" ? "#9B1C1F" : "#4F4F4F"} />
            </p>
            <p>List</p>
          </span>
          <span onClick={() => setActiveView("grid")}>
            <p>
              <Grid fill={activeView === "grid" ? "#9B1C1F" : "#4F4F4F"} />
            </p>
            <p>Grid</p>
          </span>
        </div>
        {activeView === "list" && (
          <ListView size={size} filteredData={filteredData} handleProjectClick={handleProjectClick} />
        )}
        {activeView === "grid" && <GridView filteredData={filteredData} handleProjectClick={handleProjectClick} />}
      </div>
    </div>
  );
}
