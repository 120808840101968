import React from "react";

function ArrowRight({ fill, height = "13", width = "17" }) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 17 13'>
      <path
        d='M1.39244 5.75024C0.978224 5.75024 0.642437 6.08603 0.642437 6.50024C0.642437 6.91446 0.978224 7.25024 1.39244 7.25024V5.75024ZM15.1074 5.75024H1.39244V7.25024H15.1074V5.75024Z'
        fill={fill}
      />
      <path
        d='M10.153 12.3116C10.0933 12.252 10.0459 12.1812 10.0136 12.1032C9.98128 12.0253 9.96465 11.9417 9.96465 11.8573C9.96465 11.7729 9.98128 11.6894 10.0136 11.6114C10.0459 11.5335 10.0933 11.4626 10.153 11.403L15.0553 6.49991L10.153 1.5968C10.0934 1.53714 10.046 1.46631 10.0137 1.38836C9.98146 1.31041 9.96484 1.22686 9.96484 1.14249C9.96484 1.05812 9.98146 0.97457 10.0137 0.896619C10.046 0.818669 10.0934 0.747841 10.153 0.68818C10.2127 0.62852 10.2835 0.581195 10.3615 0.548907C10.4394 0.516618 10.523 0.5 10.6073 0.5C10.6917 0.5 10.7752 0.516618 10.8532 0.548907C10.9311 0.581195 11.002 0.62852 11.0616 0.68818L16.4191 6.0456C16.4788 6.10523 16.5261 6.17604 16.5585 6.254C16.5908 6.33195 16.6074 6.41552 16.6074 6.49991C16.6074 6.5843 16.5908 6.66786 16.5585 6.74581C16.5261 6.82377 16.4788 6.89458 16.4191 6.95421L11.0616 12.3116C11.002 12.3713 10.9312 12.4187 10.8532 12.451C10.7753 12.4834 10.6917 12.5 10.6073 12.5C10.5229 12.5 10.4394 12.4834 10.3614 12.451C10.2835 12.4187 10.2126 12.3713 10.153 12.3116Z'
        fill={fill}
      />
    </svg>
  );
}

export default ArrowRight;
