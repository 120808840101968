import React from "react";

function Linkedin() {
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='inherit' stroke='inherit' xmlns='http://www.w3.org/2000/svg'>
      <g id='ri:linkedin-fill'>
        <path
          id='Vector'
          d='M6.93994 5.48489C6.93968 6.01532 6.72871 6.52392 6.35345 6.89881C5.97819 7.27369 5.46937 7.48415 4.93894 7.48389C4.40851 7.48362 3.89991 7.27265 3.52502 6.89739C3.15014 6.52213 2.93968 6.01332 2.93994 5.48289C2.94021 4.95245 3.15117 4.44385 3.52644 4.06897C3.9017 3.69408 4.41051 3.48362 4.94094 3.48389C5.47137 3.48415 5.97998 3.69512 6.35486 4.07038C6.72975 4.44564 6.94021 4.95445 6.93994 5.48489ZM6.99994 8.96489H2.99994V21.4849H6.99994V8.96489ZM13.3199 8.96489H9.33994V21.4849H13.2799V14.9149C13.2799 11.2549 18.0499 10.9149 18.0499 14.9149V21.4849H21.9999V13.5549C21.9999 7.38489 14.9399 7.61489 13.2799 10.6449L13.3199 8.96489Z'
          fill='inherit'
          stroke='inherit'  
        />
      </g>
    </svg>
  );
}

export default Linkedin;
