import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";

import { FreeMode } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";

import { useWindowSize } from "@uidotdev/usehooks";
import { useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import CrossProject from "../../../assets/svg/CrossProject";
import ArrowRight from "../../../assets/svg/ArrowRight";
import { useSearchParams } from "react-router-dom";
import { useDataUpdate } from "../../../contexts/DataUpdateContext";
import { calculateWidth, extractYoutubeKey } from "../../../utils/helpers";
import YoutubeEmbed from "../../../components/YoutubeEmbed";

const SwiperComponent = ({ imageArr, size }) => {
  if (size.width > 1024)
    return (
      <Swiper slidesPerView={"auto"} freeMode={true} modules={[FreeMode]} className='mySwiper'>
        {imageArr?.map((item, idx) => {
          return (
            <SwiperSlide
              key={idx}
              spaceBetween={10}
              style={{ cursor: "url(cursorWord.png), auto", width: "auto", margin: "0 5px" }}
            >
              <img
                style={{
                  height: "65vh",
                  width: "auto",
                  objectFit: "cover",
                }}
                src={`https:${item?.fields.file.url}`}
                alt=''
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    );
  else {
    return (
      <div className={styles.mobileSwiper}>
        {imageArr?.map((item, idc) => (
          <div key={idc} className={styles.slideImage}>
            <img src={`https:${item?.fields.file.url}`} alt='' className={styles.sliderImage} />
          </div>
        ))}
      </div>
    );
  }
};
export default function Project({ toggleDetails, setToggleDetails }) {
  const size = useWindowSize();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const { entries } = useDataUpdate();

  const [showMore, setShowMore] = useState(false);
  const [detailsBarVisible, setDetailsBarVisible] = useState(false);

  let lastScrollY = window.scrollY;

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY <= 100) {
        setDetailsBarVisible(true);
      } else {
        setDetailsBarVisible(currentScrollY < lastScrollY);
      }
      lastScrollY = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const variants = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { duration: 0.5 } },
    exit: { opacity: 0, transition: { duration: 0.5 } },
  };

  const variantDown = {
    hidden: { opacity: 0, y: -60 },
    show: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 20,
      },
    },
    exit: { opacity: 0, y: -60 },
  };
  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const projectName = searchParams.get("name");

  const entry = entries.filter((entri) => {
    return entri?.fields.entryName.toLowerCase() === projectName.toLowerCase();
  })[0];
  const projectDetail = entry?.fields;
  const youtube = extractYoutubeKey(projectDetail);

  return (
    <div className={styles.root}>
      <div className={`${styles.upperRoot} ${pathname.includes("project") && styles.bgOffWhite}`}>
        <div className={styles.projectTitleDetails}>
          <div className={styles.projectTitleLocation}>
            <p className={styles.projectTitleDetailsFirstChild}>
              {projectDetail?.field?.name}{" "}
              <span className={styles.projectTitleDetailsSecondChild}>{projectDetail?.field?.location}</span>
            </p>
          </div>
          {size.width > 1024 && (
            <Link to='/work'>
              <div className={styles.projectTitleCategory}>
                {projectDetail.field.primaryType.map((type, index) => (
                  <>
                    <span key={index} className={styles.primaryTypeNames}>
                      {type}
                      {index !== projectDetail.field.primaryType.length - 1}
                    </span>
                    {index !== projectDetail.field.primaryType.length - 1 && <div className={styles.dotsRed}></div>}
                  </>
                ))}
                <div>{" / "}</div>
                {projectDetail.field.secondaryType.map((type, index) => (
                  <>
                    <span key={index} className={styles.secondaryTypeNames}>
                      {type}
                      {index !== projectDetail.field.secondaryType.length - 1}
                    </span>
                    {index !== projectDetail.field.secondaryType.length - 1 && <div className={styles.dotsBlack}></div>}
                  </>
                ))}
              </div>
            </Link>
          )}
        </div>
        <AnimatePresence>
          {!toggleDetails && (
            <motion.div
              initial='initial'
              animate='animate'
              exit='exit'
              variants={variants}
              className={styles.swiperContainer}
              style={{ cursor: "url(cursorNoWord.png), auto" }}
            >
              <SwiperComponent imageArr={projectDetail.carouselImages} size={size} />
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <AnimatePresence>
        {(!toggleDetails || (toggleDetails && detailsBarVisible)) && (
          <motion.div
            variants={variantDown}
            initial='hidden'
            animate='show'
            exit='hidden'
            className={`${styles.stickyBar} ${pathname.includes("project") && styles.bgOffWhite}`}
            onMouseEnter={() => setDetailsBarVisible(true)}
            onMouseLeave={() => setDetailsBarVisible(false)}
          >
            <div onClick={() => setToggleDetails((prev) => !prev)} className={styles.aboutButton}>
              Details
              {toggleDetails ? (
                size.width > 1024 && <div id='chevron-arrow-down'></div>
              ) : (
                <div id='chevron-arrow-up'></div>
              )}
            </div>
            {toggleDetails ? (
              size.width > 1024 ? (
                <div className={styles.crossIcon} onClick={() => setToggleDetails(false)}>
                  <CrossProject />
                </div>
              ) : (
                <div onClick={() => setToggleDetails((prev) => !prev)} id='chevron-arrow-down'></div>
              )
            ) : (
              size.width > 1024 && (
                <Link to='/work'>
                  <button
                    style={{
                      color: "black",
                    }}
                    className={styles.showAll}
                    id='socialArrow'
                  >
                    Show all projects <ArrowRight height='12' width='18' />
                  </button>
                </Link>
              )
            )}
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {toggleDetails && (
          <motion.div initial='initial' animate='animate' exit='exit' variants={variants} className={styles.lowerRoot}>
            <div className={styles.upperSection}>
              <div className={styles.projectDetailsOne} dangerouslySetInnerHTML={{ __html: projectDetail.about }} />
              <div className={styles.projectDetailsTwo}>
                <div className={styles.projectDetailsFirst}>
                  <div>
                    <p className={styles.commonDetailHeading}>Status</p>
                    <p className={styles.commonDetailContent}>{projectDetail?.field?.status}</p>
                  </div>
                  <div>
                    <p className={styles.commonDetailHeading}>Location</p>
                    <p className={styles.commonDetailContent}>{projectDetail?.field?.location}</p>
                  </div>
                </div>
                {projectDetail?.field?.awards && (
                  <div>
                    <p className={styles.commonDetailHeading}>Awards</p>
                    {projectDetail.field.awards.map((award) => {
                      return <p className={styles.commonDetailContent}>{award}</p>;
                    })}
                  </div>
                )}
                {projectDetail?.field?.clients && (
                  <div>
                    <p className={styles.commonDetailHeading}>Client</p>
                    {projectDetail.field.clients.map((client) => {
                      return <p className={styles.commonDetailContent}>{client}</p>;
                    })}
                  </div>
                )}

                {(showMore || size.width > 1024) && (
                  <>
                    {projectDetail?.field?.designTeam && (
                      <div>
                        <p className={styles.commonDetailHeading}>Design Team</p>
                        {projectDetail.field.designTeam.map((item) => {
                          return <p className={styles.commonDetailContent}>{item}</p>;
                        })}
                      </div>
                    )}
                    {projectDetail?.field?.engineers && (
                      <div>
                        <p className={styles.commonDetailHeading}>Engineers</p>
                        {projectDetail.field.engineers.map((item) => {
                          return <p className={styles.commonDetailContent}>{item}</p>;
                        })}
                      </div>
                    )}
                    {projectDetail?.field?.contractors && (
                      <div>
                        <p className={styles.commonDetailHeading}>Contractors</p>
                        {projectDetail.field.contractors.map((item) => {
                          return <p className={styles.commonDetailContent}>{item}</p>;
                        })}
                      </div>
                    )}
                    <div>
                      <p className={styles.commonDetailHeading}>Current project cost</p>
                      <p className={styles.commonDetailContent}>{projectDetail?.field?.currentProjectCost}</p>
                    </div>
                    <div>
                      <p className={styles.commonDetailHeading}>Current project area</p>
                      <p className={styles.commonDetailContent}>{projectDetail?.field?.currentProjectArea}</p>
                    </div>
                    <div>
                      <p className={styles.commonDetailHeading}>Photographs</p>
                      <p className={styles.commonDetailContent}>
                        {Array.isArray(projectDetail?.field?.photographs)
                          ? projectDetail.field.photographs.map((photo, index) => <p key={index}>{photo}</p>)
                          : projectDetail?.field?.photographs}
                      </p>
                    </div>
                  </>
                )}
                {size.width < 1024 && (
                  <button onClick={toggleShowMore} className={styles.showMore}>
                    {showMore ? "View Less" : "View More"}
                  </button>
                )}
              </div>
            </div>
            <div id='lowerSection' className={styles.lowerSection}>
              <p className={styles.projectTitleDetailsFirstChild}></p>

              {projectDetail.constructionDetailsImages.map((imageq, index) => {
                if (youtube && index === youtube.placement) {
                  return (
                    <div
                      style={{
                        width: size.width >= 1024 ? "75%" : "100%",
                      }}
                      className='youtubeEmbed'
                    >
                      <YoutubeEmbed
                        height={size.width >= 1024 ? 480 : size.width >= 576 ? 450 : 250}
                        embedId={youtube.embedId}
                      />
                    </div>
                  );
                }
                return (
                  <>
                    <div className={styles.constructionDetails}>
                      <img
                        src={`https:${imageq?.fields.file.url}`}
                        alt='Example'
                        className={styles.constructionImage}
                      />
                      <p className={styles.constructionContent}>{imageq?.fields.title}</p>
                    </div>

                    {imageq?.fields.description && (
                      <p className={styles.projectDetailsOneSection}>{imageq?.fields.description}</p>
                    )}
                  </>
                );
              })}

              {/* <div className={styles.constructionDetails}>
                <img src={Example5} alt='Example' className={styles.constructionImage} />
                <p className={styles.constructionContent}>
                  Torem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a,
                  mattis tellus.
                </p>
              </div> */}

              <Link
                to='/work'
                style={{
                  color: "black",
                }}
                className={styles.showAll}
                id='socialArrow'
              >
                Show all projects <ArrowRight height='12' width='18' />
              </Link>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
