import { useState } from "react";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "./styles.module.css"; // Import your modular CSS
import { useWindowSize } from "@uidotdev/usehooks";
import { motion } from "framer-motion";
import Cross from "../../assets/svg/Cross";
import Quote from "../../assets/svg/Quote";
const Slide = ({ isActive, data, size, openModal, closeModal, setDatas }) => {
  const variants = {
    active: { height: "100%", fontSize: "20px", transition: { duration: 0.2 } },
    inactive: { height: "100%", fontSize: "15px", transition: { duration: 0.2 } },
  };

  const initial = isActive?.isActive ? "active" : "inactive";

  return (
    <motion.div
      className={styles.slide}
      variants={variants}
      initial={initial}
      animate={isActive?.isActive ? "active" : "inactive"}
    >
      <p className={styles.slideTitle}>{data.client.length > 40 ? `${data.client.slice(0, 40)}...` : data.client}</p>

      <div style={{ position: "relative", textAlign: "justify" }}>
        <span className={styles.quote}>
          <Quote></Quote>
        </span>
        <span className={styles.slideContent}>
          {size.width < 768 && data.content.length > 123 ? (
            <>
              {data.content.slice(0, 123)}
              <p
                onClick={() => {
                  setDatas(data);
                  openModal();
                }}
                className={styles.readMore}
              >
                Read More
              </p>
            </>
          ) : (
            data.content
          )}
        </span>
      </div>
      <p className={styles.slideSub}>{data.project}</p>
    </motion.div>
  );
};

const SwiperComponent = ({ data }) => {
  const size = useWindowSize();
  const [isExpanded, setIsExpanded] = useState(false);
  const [datas, setDatas] = useState();

  const openModal = () => {
    setIsExpanded(true);
    document.body.style.backgroundColor = "#4f4f4f"; // Set background color
    document.body.style.overflow = "hidden"; // Prevent scrolling
  };

  const closeModal = () => {
    setIsExpanded(false);
    document.body.style.backgroundColor = ""; // Reset background color
    document.body.style.overflow = ""; // Reset overflow
  };
  return (
    <>
      {isExpanded && (
        <div className={styles.modalOverlay} onClick={closeModal}>
          <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
            <div className={styles.modal}>
              <p className={styles.slideTitle}>{datas.client}</p>
              <span className={styles.slideContent}>{datas.content}</span>
            </div>
            <p className={styles.slideSub}>{datas.project}</p>

            <button onClick={closeModal} className={styles.closeButton}>
              <Cross fill={"#000"} />
            </button>
            {/* <div className={styles.modalContent}>
              
            </div> */}
          </div>
        </div>
      )}
      <div className={styles.root}>
        <Swiper
          loop={true}
          centeredSlides
          navigation={true}
          modules={[Navigation]}
          wrapperClass={styles.customWrapper}
          spaceBetween={size.width <= 1024 ? 20 : 100}
          slidesPerView={size.width <= 1024 ? 1 : 1.7}
        >
          {data.map((item, idx) => {
            return (
              <SwiperSlide key={idx}>
                {(isActive) => (
                  <>
                    <Slide
                      setDatas={setDatas}
                      openModal={openModal}
                      closeModal={closeModal}
                      isActive={isActive}
                      data={item}
                      size={size}
                    />
                  </>
                )}
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
};

export default SwiperComponent;
