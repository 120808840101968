import React, { useEffect, useState } from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "./Layout";
import Landing from "../Landing";
import Home from "../Pages/Home";
import Work from "../Pages/Work";
import Project from "../Pages/Project";
import News from "../Pages/News";
import Contact from "../Pages/Contact";
import Stories from "../Pages/Stories";
import Practice from "../Pages/Practice";
import ScrollToTop from "../../components/ScrollToTop";
import { useDataUpdate } from "../../contexts/DataUpdateContext";
import fetchEntriesByModel from "../../apis";
import Loading from "../Loading";

export default function Main() {
  const { setEntries, setIsLoading, isLoading, setMiscData } = useDataUpdate();
  const [toggleDetailsForProject, setToggleDetailsForProject] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      setTimeout(() => {
        setIsLoading(false);
      }, 5000);

      const dataProject = await fetchEntriesByModel("project");
      const dataNews = await fetchEntriesByModel("news");
      const dataPractice = await fetchEntriesByModel("practice");
      const dataStories = await fetchEntriesByModel("stories");
      const dataHomepage = await fetchEntriesByModel("homepage");

      setEntries(dataProject);
      setMiscData({
        news: dataNews,
        practice: dataPractice,
        stories: dataStories,
        homepage: dataHomepage,
      });
      // setIsLoading(false);
    })();
  }, []);

  return (
    <Router basename='/'>
      {isLoading ? (
        <Loading />
      ) : (
        <Layout setToggleDetails={setToggleDetailsForProject} toggleDetails={toggleDetailsForProject}>
          <ScrollToTop />
          <Routes>
            <Route
              path='/'
              element={
                <>
                  <Landing />
                  <Home />
                </>
              }
            />
            <Route path='/work' element={<Work />} />
            <Route
              path='/project'
              element={
                <Project setToggleDetails={setToggleDetailsForProject} toggleDetails={toggleDetailsForProject} />
              }
            />
            <Route path='/news' element={<News />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/stories' element={<Stories />} />
            <Route path='/practice' element={<Practice />} />
          </Routes>
        </Layout>
      )}
    </Router>
  );
}
