import React, { useState } from "react";
import styles from "./styles.module.css";
import { motion, AnimatePresence } from "framer-motion";
import ArrowRight from "../../../assets/svg/ArrowRight";
import { FreeMode } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { useWindowSize } from "@uidotdev/usehooks";
import { useDataUpdate } from "../../../contexts/DataUpdateContext";
import Image from "../../../assets/female.webp";
import Image2 from "../../../assets/male.webp";
import {
  imageShutterVariant,
  imageShutterDuration,
  animationProps,
  textShutterDuration,
  textShutterVariant,
} from "../../../utils/constants";
const variants = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { duration: 0.5 } },
  exit: { opacity: 0, transition: { duration: 0.5 } },
};
const contentEntries = [
  {
    heading: "Our practice is a continuous learning process",
    content:
      "With each built / unbuilt project, we gather experience and bolster our philosophy. We have much to know and remain as receptors of new technology and changes. We are also closely involved in academia to continue our learning process. We look at each project as a new opportunity to learn, unlearn, and start afresh. Hence our aesthetics are not informed by any predictable style: but rather by the call of the site, the craft of our masons, and the client’s desire for a particular atmosphere.",
  },
  {
    heading: "At the core, we are interested in our materials",
    content:
      "We believe that each material calls for specific attention to detailing and guides the character of the built space. So, there are the material-driven projects in our oeuvre, where the design thinking starts at the module of the base material size. We are pretty much possessed by it then, constantly exploring ways to work with it. Sometimes we end up with a formal solution and other times with a very tactile, experiential outcome. The whole process of understanding the character, sourcing, and working with strengths and limitations of materials leaves us highly enriched and we are happy to trade our time for it.",
  },
  {
    heading: "We are also interested in the diversity of projects",
    content:
      "Because, the process behind the design problem is similar, irrespective of the scale of operation, we lap up projects close to our heart, of all types and sizes. Our Principal Architects, bring their expertise at the Urban and Architectural scale and also are highly research-inclined. Researching is an integral part of our process and a discipline of work we pursue. We also get to collaborate with creative professionals from other fields, when we embrace diversity of projects. So, you'll find us working on the most autonomous, Interior Design project and an intensely public, Urban project with equal zest.",
  },
  {
    heading: "We like to build just enough",
    content:
      "Our definition of sustainability remains in the zone of building sensibly, drawing from and respecting the local conditions; and just enough. We think the very act of building is an antithesis of the much-abused term 'Sustainable Architecture'. We look at sustainability from aspects like energy, local economy, culture, flora and fauna, and tread carefully. It means that we don't just nod to the standards established by certification bodies but look for wisdom in and around traditional building practices. For the same reason, we are attuned to our sites and consider it an inseparable part of design.",
  },
];
const peopleData = [
  {
    name: "Guru Prasanna. C",
    img: Image2,
    designation: "Founder, Urban Designer and Architect",
    social: "http://example.com",
    isHero: true,
    content: [
      "Guru Prasanna, after completion of his architectural education from National Institute of Technology, Tiruchirapalli and professional training from reputed firms like DAAT (Delhi), Venkatraman Associates (Bangalore) and Brand new Day (Auroville), started his own practice under the name of Gargoyle Architecture and Interiors and worked on many residential, commercial and planning projects. After five years of field experience; in 2006 he adjourned to travel and pursue his education in Urban Design and Urban Development (MUDD) at the University of New South Wales, Sydney.",
      "Since his return in 2007, he continues to work as the principal of his current firm BETWEENLINES. His interest includes, travel, photography, product design, urban theory and economics, architectural conservation, sustainability and automobile engineering etc. Guru also teaches urban design to the masters program students at RV College of Architecture. When not at work, he is at work. Ideas come in dozens to him and has the energy of a 5 year old. Treasure of talents, people’s person, technology addict and an inexplicable business venture enthusiast.",
    ],
  },
  {
    name: "Deepa Suriyaprakash",
    designation: "Founding Partner and Architect",
    img: Image,
    social: "http://example.com",
    isHero: true,
    content: [
      "Deepa completed her architectural education from National Institute of Technology, Tiruchirapalli and professional training at Brand New Day (Auroville) and MOAD (Chennai); she pursued her education in Theory of Architecture and Design (M.Arch) from The Ohio State University, USA. Upon completing the masters program which included expansive travelling opportunities, she worked with Skidmore, Owings and Merill LLP at Chicago, USA, where she gained experience working on macro scale projects. Since 2008 she has been leading BETWEENLINES with her partner Mr.Guru Prasanna.",
      "Her interests includes, travel, teaching, narratives, critical theory, graphic design, pattern and material studies etc. Apart from her practice she also teaches architecture and interior design at, RV College of Architecture. Her two year stint as Head of Research at CnT Architects, grounded her perspective and realigned her philosophy with the locale. When not working as an architect, she is busy composting and helping people keep their waste off, landfills.",
    ],
  },
];
function filterAndRemoveHeroes(array) {
  const heroes = [];
  const nonHeroes = [];

  array.forEach((item) => {
    if (item.fields.field.isHero) {
      heroes.push(item);
    } else {
      nonHeroes.push(item);
    }
  });

  return { heroes, nonHeroes };
}

const SwiperComponent = ({ size }) => {
  const { miscData } = useDataUpdate();
  const slug = miscData.practice;

  const carouselImageObj = slug.filter((item) => {
    return item.fields.carouselImages;
  });

  if (size.width > 1024)
    return (
      <Swiper
        loop={true}
        slidesPerView={"auto"}
        spaceBetween={10}
        freeMode={true}
        modules={[FreeMode]}
        className='mySwiper'
        style={{ cursor: "url(cursorWord.png), auto" }}
      >
        {carouselImageObj[0]?.fields?.carouselImages?.map((item, idx) => {
          return (
            <SwiperSlide key={idx} style={{ cursor: "url(cursorWord.png), auto", width: "auto" }}>
              <img
                src={`https://${item?.fields?.file.url}`}
                alt=''
                className={styles.sliderImage}
                style={{
                  height: "80vh",
                  width: "auto",
                }}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    );
  else {
    return (
      <div className={styles.mobileSwiper}>
        {carouselImageObj[0]?.fields?.carouselImages?.map((item, idx) => (
          <div key={item} className={styles.slideImage}>
            <img src={`https://${item?.fields?.file.url}`} alt='' className={styles.sliderImage} />
          </div>
        ))}
      </div>
    );
  }
};

const PeopleContentHero = ({ size, name, designation, social, content, img, isHero }) => {
  const [toggleMorePeople, setToggleMorePeople] = useState(false);

  return (
    <div className={styles.peopleContentHeroItems}>
      <div className={styles.heroItemsFirst}>
        <motion.div
          {...animationProps}
          variants={imageShutterVariant}
          transition={{ duration: imageShutterDuration }}
          className={styles.heroItemsImg}
        >
          <img src={`https://${img?.fields?.file.url}`} alt=' ' />
        </motion.div>
        <div>
          <motion.p
            {...animationProps}
            transition={{ duration: textShutterDuration }}
            variants={textShutterVariant}
            className={styles.heroItemsFirstHeading}
          >
            {name}
          </motion.p>
          <div className={styles[isHero ? "heroItemsArrowDivHero" : "heroItemsArrowDiv"]}>
            <motion.p
              {...animationProps}
              transition={{ duration: textShutterDuration }}
              variants={textShutterVariant}
              className={styles.heroItemsFirstSubHeading}
            >
              {designation}
            </motion.p>
            <a href={social} target='_blank' rel='noreferrer' className={styles.socialArrow} id='socialArrow'>
              <ArrowRight fill={"black"} />
            </a>
          </div>

          {!isHero && (
            <p className={styles.heroItemsSecond} style={{ marginTop: !isHero ? "1.25rem" : "0" }}>
              {content}
            </p>
          )}
        </div>
      </div>
      <div className={styles.heroItemsSecond}>
        {isHero &&
          content.map((item, idx) => {
            if (idx === 0) return <p key={idx}>{item}</p>;
            else {
              return toggleMorePeople && <p key={idx}>{item}</p>;
            }
          })}
      </div>
      {isHero && (
        <div onClick={() => setToggleMorePeople((prev) => !prev)} className={styles.viewMore}>
          <p>{toggleMorePeople ? "View Less" : "View More"}</p>
        </div>
      )}
    </div>
  );
};
export default function Practice() {
  // const [toggleMore, setToggleMore] = useState(false);
  const [active, setActive] = useState("firm");

  const size = useWindowSize();
  const { miscData } = useDataUpdate();
  const slug = miscData.practice;

  const { heroes, nonHeroes } = filterAndRemoveHeroes(slug);

  return (
    <div className={styles.root}>
      <div className={styles.filters}>
        <button
          onClick={() => {
            setActive("firm");
            document.getElementById("firmDiv").scrollIntoView({ behavior: "smooth" });
          }}
          className={`${active === "firm" && styles.activeSelectedFilter} ${styles.selectedFilter}`}
        >
          Philosophy
        </button>
        <button
          onClick={() => {
            setActive("people");
            document.getElementById("peopleDiv").scrollIntoView({ behavior: "smooth" });
          }}
          className={`${active === "people" && styles.activeSelectedFilter} ${styles.selectedFilter}`}
        >
          People
        </button>
      </div>

      <motion.div
        initial='initial'
        animate='animate'
        exit='exit'
        variants={variants}
        className={styles.swiperContainer}
      >
        <SwiperComponent size={size} />
      </motion.div>
      <div className={styles.asideContent}>
        BETWEENLINES is an award winning Urban, Architectural and Interior Design firm. Founded in 2003 by Guru
        Prasanna.C and Deepa Suriyaprakash, the firm is now seasoned through a spectrum of experiences, achieved through
        travel, academia, research and thought development.
      </div>

      <AnimatePresence>
        {
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            transition={{ duration: 0.1, ease: "easeOut" }}
            className={styles.firmContent}
            id='firmDiv'
          >
            {contentEntries.map((entry, index) => (
              <div
                key={index}
                className={styles.firmContentItem}
                style={size.width > 1024 ? { flexDirection: index % 2 ? "row-reverse" : "row" } : null}
              >
                <p className={styles.firmContentItemHeading}>{entry.heading}</p>
                <p className={styles.firmContentItemContent}>{entry.content}</p>
              </div>
            ))}
          </motion.div>
        }
      </AnimatePresence>

      <div
        id='peopleDiv'
        className={styles.peopleContent}
        style={{
          scrollMarginTop: "100px",
        }}
      >
        <div className={styles.peopleContentUpper}>
          {heroes.map((item, index) => {
            const { name, designation, social, content, isHero } = item.fields.field;

            return (
              <PeopleContentHero
                size={size}
                key={index}
                name={name}
                designation={designation}
                social={social}
                content={content}
                img={item.fields.profile}
                isHero={isHero}
              />
            );
          })}
        </div>

        <div className={styles.peopleContentLower}>
          {nonHeroes.map((item, index) => {
            const { field, profile } = item.fields;

            return (
              <div className={styles.contentLowerItem}>
                <PeopleContentHero
                  size={size}
                  key={field.name}
                  name={field.name}
                  designation={field.designation}
                  social={field.social}
                  content={field.about}
                  img={profile}
                  isHero={field.isHero}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
