export const getLocalStorageItem = (key) => {
  const item = localStorage.getItem(key);
  return item ? JSON.parse(item) : null;
};
export const setLocalStorageItem = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export function extractYoutubeKey(obj) {
  try {
    if (obj.field && obj.field.youtube) {
      return obj.field.youtube;
    }
  } catch (error) {
    return null;
  }
}
