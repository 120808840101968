import React from "react";

function CrossProject({ fill }) {
  return (
    <svg width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        id='Vector'
        d='M1 1.5L11 11.5M1 11.5L11 1.5'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export default CrossProject;
