import React from "react";

function Facebook() {
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='inherit' stroke='inherit' xmlns='http://www.w3.org/2000/svg'>
      <g id='ic:baseline-facebook'>
        <path
          id='Vector'
          d='M22 12.4849C22 6.96486 17.52 2.48486 12 2.48486C6.48 2.48486 2 6.96486 2 12.4849C2 17.3249 5.44 21.3549 10 22.2849V15.4849H8V12.4849H10V9.98486C10 8.05486 11.57 6.48486 13.5 6.48486H16V9.48486H14C13.45 9.48486 13 9.93486 13 10.4849V12.4849H16V15.4849H13V22.4349C18.05 21.9349 22 17.6749 22 12.4849Z'
          fill='inherit'
          stroke='inherit'
        />
      </g>
    </svg>
  );
}

export default Facebook;
