import React from "react";

function Cross({ fill }) {
  return (
    <svg width='18' height='18' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='menu'>
        <path
          id='Vector'
          d='M20 2.01429L17.9857 0L10 7.98571L2.01429 0L0 2.01429L7.98571 10L0 17.9857L2.01429 20L10 12.0143L17.9857 20L20 17.9857L12.0143 10L20 2.01429Z'
          fill={fill}
        />
      </g>
    </svg>
  );
}

export default Cross;
