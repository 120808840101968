import * as contentful from "contentful";

const client = contentful.createClient({
  space: "2n3ebem2rirm",
  accessToken: "u66VWn3-UeLjGJRaUs9WVzxJpHm3Vb2wdE25jAv25F8",
});

async function fetchEntriesByModel(modelId) {
  try {
    const entries = await client.getEntries({
      content_type: modelId,
    });

    return entries.items;
  } catch (error) {
    console.error("Error occurred while fetching entries:", error);
    return [];
  }
}

export default fetchEntriesByModel;
