import React from "react";
import styles from "./styles.module.css";
import SwiperComponent from "../../../components/Swiper";

import Marquee from "react-fast-marquee";

import { useDataUpdate } from "../../../contexts/DataUpdateContext";
import ArrowRight from "../../../assets/svg/ArrowRight";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import {
  animationProps,
  appearDuration,
  appearVariant,
  textShutterDuration,
  textShutterVariant,
  imageShutterDuration,
  imageShutterVariant,
} from "../../../utils/constants";

export default function Home() {
  const { miscData } = useDataUpdate();
  const slug = miscData.homepage;
  const { homepagePosts, testimonialsAndClients } = slug[0].fields;
  const navigate = useNavigate();

  const handleProjectClick = (projectName) => {
    const projectNameLowerCase = projectName.toLowerCase();
    navigate(`/project?name=${encodeURIComponent(projectNameLowerCase)}`);
  };
  const handleReadMore = () => {
    navigate(`/practice`);
  };
  return (
    <div className={styles.root}>
      {homepagePosts.map((item, index) => {
        const { aboutHomepage, carouselImages, field } = item.fields;

        return (
          <section key={index} className={styles.section}>
            <motion.div
              transition={{ duration: appearDuration }}
              {...animationProps}
              variants={appearVariant}
              className={styles.image}
            >
              <motion.img
                {...animationProps}
                transition={{ duration: imageShutterDuration }}
                variants={imageShutterVariant}
                src={`https://${carouselImages?.[0]?.fields?.file?.url}`}
                alt=''
              />
            </motion.div>
            <div className={styles.aside}>
              <motion.p
                transition={{ duration: appearDuration }}
                {...animationProps}
                variants={appearVariant}
                className={styles.asideTitle}
              >
                {field.name + ", " + field.location}
              </motion.p>
              <div className={styles.asideHeadingContainer}>
                <motion.div
                  transition={{ duration: appearDuration }}
                  {...animationProps}
                  variants={appearVariant}
                  className={styles.asideHeading}
                  dangerouslySetInnerHTML={{ __html: aboutHomepage }}
                />
                <motion.a
                  {...animationProps}
                  transition={{ duration: appearDuration }}
                  variants={appearVariant}
                  href={item.link}
                  className={styles.asideLink}
                  onClick={() => handleProjectClick(field.name)}
                  id='socialArrow'
                >
                  <p>Read more</p>
                  <ArrowRight fill={"black"} />
                </motion.a>
              </div>
            </div>
          </section>
        );
      })}

      <section className={styles.dividerContainer}>
        <motion.p
          transition={{ duration: textShutterDuration }}
          {...animationProps}
          variants={textShutterVariant}
          className={styles.dividerHeading}
        >
          Crafting immersive spaces through material-driven design, diverse projects, and sustainable sensibilities.
        </motion.p>
        <motion.a
          {...animationProps}
          transition={{ duration: appearDuration }}
          variants={appearVariant}
          href='#/practice'
          className={styles.asideLink}
          id='socialArrow'
          onClick={() => handleReadMore()}
        >
          Read more about us
          <ArrowRight fill={"black"} />
        </motion.a>
      </section>

      <>
        <p className={styles.carouselHeading}>What our clients say about us?</p>
        <SwiperComponent data={testimonialsAndClients.testimonials} />
      </>

      <div className={styles.runningTextContainer}>
        <p className={styles.runningTextHeading}>Select clients</p>

        <Marquee speed={150}>
          {testimonialsAndClients.client.map((item, index) => (
            <span key={index} className={styles.marqueeText}>
              {item}
            </span>
          ))}
        </Marquee>
      </div>
    </div>
  );
}
