import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { newsNavi } from "../../../utils/constants";
import ArrowRight from "../../../assets/svg/ArrowRight";
import { useDataUpdate } from "../../../contexts/DataUpdateContext";
import { motion } from "framer-motion";
import { childStagVariants, parentStagVariants } from "../../../utils/constants";
import { AnimatePresence } from "framer-motion";
const NewsItem = ({ date, content, url, imageUrl, setImage, title }) => {
  return (
    <motion.div
      variants={childStagVariants}
      className={styles.newsItem}
      onMouseLeave={() => {
        setImage("");
      }}
      onMouseEnter={() => {
        setImage(imageUrl);
      }}
    >
      <p className={styles.miscDetails}>{date}</p>
      <div>
        <p className={styles.newsContent} style={{ color: "#9B1C1F" }}>
          {title}
        </p>
        <p className={styles.newsContent}>{content}</p>
        {url && (
          <span className={styles.readMore} id='socialArrow'>
            <a href={url === "-" ? "/" : url} className={styles.readMore} target='_blank' rel='noreferrer noopener'>
              Read more
            </a>
            <ArrowRight fill={"#4f4f4f"} height='10' width='18' />
          </span>
        )}
      </div>
    </motion.div>
  );
};
export default function News() {
  const { miscData } = useDataUpdate();

  const [selectedFilter, setSelectedFilter] = useState("awards");
  const [data, setData] = useState(miscData.news);
  const [image, setImage] = useState("");

  const imageStyle = {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: 2,
  };
  const imageDivStyle = {
    position: "relative",
    width: "100%",
    minHeight: "320px",
  };

  useEffect(() => {
    const filteredData = miscData.news?.filter((item) => {
      return item?.fields?.field?.type?.toLowerCase() === selectedFilter;
    });

    setData(filteredData);
  }, [selectedFilter]);

  return (
    <div>
      <div className={styles.toggleSection}>
        {newsNavi.map((option) => (
          <button
            key={option.id}
            onClick={() => {
              setSelectedFilter(option.name.toLowerCase());
            }}
            className={`${selectedFilter === option.name.toLowerCase() && styles.activeSelectedFilter} ${
              styles.selectedFilter
            }`}
          >
            {option.name}
          </button>
        ))}
      </div>
      <div className={styles.mainContent}>
        <AnimatePresence>
          <motion.div variants={parentStagVariants} initial='initial' animate='animate' className={styles.leftSection}>
            {data?.map((item, idx) => {
              const { hoverImage, field } = item.fields;
              return (
                <NewsItem
                  key={idx}
                  date={field?.date}
                  content={field.description}
                  title={field?.title}
                  url={field.readMoreURL}
                  imageUrl={`https://${hoverImage?.fields.file.url}`}
                  setImage={setImage}
                />
              );
            })}
          </motion.div>
        </AnimatePresence>
        <div className={styles.rightSection}>
          {image && (
            <div style={imageDivStyle}>
              <img src={image} alt=' ' style={imageStyle} />
              {/* <div style={imageDivBeforeStyle}></div> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
