import React from "react";
import styles from "./style.module.css"; // Import modular CSS

import LoadingBlack from "../../assets/svg/LoadingBlack";
import LoadingWhite from "../../assets/svg/LoadingWhite";
import { useLocation } from "react-router-dom";

const Loading = () => {
  const { pathname } = useLocation();

  return (
    <div className={pathname === "/" || pathname === "contact" ? styles.loadingScreenBlack : styles.loadingScreenWhite}>
      <div className={styles.loader}>
        {pathname === "/" || pathname === "contact" ? <LoadingWhite /> : <LoadingBlack />}
      </div>
    </div>
  );
};

export default Loading;
