import React from "react";
import { useLocation } from "react-router-dom";

function Hamburger({ scrolled }) {
  const { pathname } = useLocation();
  return (
    <svg width='22' height='22' viewBox='0 0 24 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16.6672 19.332H3.33471V16.6656H16.6672V19.332ZM16.6672 11.3326H8.66769V8.66609H16.6672V11.3326ZM16.6672 0.666622V3.33311H0.668221V0.666622H16.6672ZM22.0001 1.11213e-07C22.2628 8.76478e-05 22.523 0.0519194 22.7657 0.152537C23.0083 0.253154 23.2288 0.400586 23.4145 0.586414C23.6002 0.772243 23.7475 0.992829 23.848 1.23558C23.9484 1.47833 24.0001 1.73849 24 2.0012C23.9999 2.26391 23.9481 2.52404 23.8475 2.76672C23.7468 3.0094 23.5994 3.22989 23.4136 3.4156C23.2278 3.6013 23.0072 3.74858 22.7644 3.84904C22.5217 3.9495 22.2615 4.00115 21.9988 4.00107C21.4682 4.00089 20.9595 3.78995 20.5844 3.41465C20.2094 3.03935 19.9988 2.53044 19.9989 1.99987C19.9991 1.46929 20.21 0.960519 20.5853 0.585471C20.9606 0.210424 21.4696 -0.000176677 22.0001 1.11213e-07ZM22.0001 7.99947C22.2628 7.99955 22.523 8.05139 22.7657 8.152C23.0083 8.25262 23.2288 8.40005 23.4145 8.58588C23.6002 8.77171 23.7475 8.9923 23.848 9.23504C23.9484 9.47779 24.0001 9.73795 24 10.0007C23.9999 10.2634 23.9481 10.5235 23.8475 10.7662C23.7468 11.0089 23.5994 11.2294 23.4136 11.4151C23.2278 11.6008 23.0072 11.7481 22.7644 11.8485C22.5217 11.949 22.2615 12.0006 21.9988 12.0005C21.4682 12.0004 20.9595 11.7894 20.5844 11.4141C20.2094 11.0388 19.9988 10.5299 19.9989 9.99933C19.9991 9.46876 20.21 8.95999 20.5853 8.58494C20.9606 8.20989 21.4696 7.99929 22.0001 7.99947ZM22.0001 15.9989C22.2628 15.999 22.523 16.0509 22.7657 16.1515C23.0083 16.2521 23.2288 16.3995 23.4145 16.5853C23.6002 16.7712 23.7475 16.9918 23.848 17.2345C23.9484 17.4773 24.0001 17.7374 24 18.0001C23.9999 18.2628 23.9481 18.523 23.8475 18.7657C23.7468 19.0083 23.5994 19.2288 23.4136 19.4145C23.2278 19.6002 23.0072 19.7475 22.7644 19.848C22.5217 19.9484 22.2615 20.0001 21.9988 20C21.4682 19.9998 20.9595 19.7889 20.5844 19.4136C20.2094 19.0383 19.9988 18.5294 19.9989 17.9988C19.9991 17.4682 20.21 16.9595 20.5853 16.5844C20.9606 16.2094 21.4696 15.9988 22.0001 15.9989Z'
        fill={
          pathname === "/" || pathname === "/contact" ? (pathname === "/" && scrolled ? "black" : "white") : "black"
        }
      />
    </svg>
  );
}

export default Hamburger;
