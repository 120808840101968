import React from "react";
import "./styles.module.css";

const YoutubeEmbed = ({ embedId, height }) => (
  <div className='video-responsive'>
    <iframe
      width='100%'
      height={height}
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder='0'
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      allowFullScreen
      title='Embedded youtube'
    />
  </div>
);

export default YoutubeEmbed;
