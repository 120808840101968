import React from "react";

function List({ fill }) {
  return (
    <svg width='20' height='16' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='Group 10'>
        <rect id='Rectangle 4' width='20' height='6.4' fill={fill} />
        <rect id='Rectangle 5' y='9.6001' width='20' height='6.4' fill={fill} />
      </g>
    </svg>
  );
}

export default List;
