import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Cross from "../../../assets/svg/Cross";
import styles from "./styles.module.css";
import { useWindowSize } from "@uidotdev/usehooks";
import { useDataUpdate } from "../../../contexts/DataUpdateContext";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

const ModalComponent = ({ isOpen, toggleModal, image }) => {
  const modalVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.3,
      },
    },
  };
  const src = `https:${image?.fields?.file?.url}`;

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className={styles.modalBackground}
          initial='hidden'
          animate='visible'
          exit='hidden'
          variants={modalVariants}
        >
          <div className={styles.modalContent}>
            <div className={styles.modalBody}>
              <img className={styles.modalImage} src={src} alt='Modal' />
            </div>

            <div className={styles.modalText}>
              {`${
                image?.fields?.description?.length > 230
                  ? image?.fields?.description?.substring(0, 230) + "..."
                  : image?.fields?.description || ""
              }`}
            </div>
            <div className={styles.modalHeader}>
              <button className={styles.closeButton} onClick={toggleModal}>
                <Cross fill={"#000"} />
              </button>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default function Stories() {
  const size = useWindowSize();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [image, setImage] = useState("");
  const [scrolledUnits, setScrolledUnits] = useState(0);
  const divisor = 3;
  const toggleModal = (imgSrc) => {
    setIsModalOpen(!isModalOpen);
    setImage(imgSrc);
  };
  const { miscData } = useDataUpdate();
  const { storiesImages } = miscData?.stories[0]?.fields;
  const [renderData, setRenderData] = useState(storiesImages);
  console.log(storiesImages, "these are the stoeis");

  useEffect(() => {
    const handleScroll = () => {
      const scrolledUnitsY = window.scrollY;

      if (scrolledUnitsY - scrolledUnits >= 300 && scrolledUnitsY > scrolledUnits) {
        setScrolledUnits(scrolledUnitsY);
        setRenderData((prevData) => [...prevData, ...storiesImages]);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrolledUnits]);

  return (
    <ResponsiveMasonry
      columnsCountBreakPoints={{ 350: 1, 550: 2, 900: 4 }}
      style={{
        padding: size.width > 750 ? "60px" : "30px",
      }}
    >
      <div className={styles.stories}>Stories</div>
      <Masonry gutter='20px'>
        {renderData.map((image, i) => {
          const src = `https:${image?.fields?.file?.url}`;
          if (i % divisor === 0 || i % divisor === 2 || i % divisor === 7 || size.width < 500) {
            return (
              <img
                alt=''
                key={`${i}`}
                src={src}
                className='image-link'
                style={{
                  width: "100%",
                  display: "block",
                  cursor: "pointer",
                  maxHeight: "500px",
                  objectFit: "contain",
                  background: "inherit",
                }}
                onClick={() => toggleModal(image)}
              />
            );
          } else {
            const randomHeight = size.width > 500 ? 320 : 150;
            if (size.width > 500)
              return (
                <>
                  <div
                    key={`${i}`}
                    style={{
                      height: `${randomHeight}px`,
                      backgroundColor: "#dbd7d7",
                      width: "100%",
                      display: "block",
                    }}
                  ></div>
                  <img
                    alt=''
                    key={`${i}`}
                    src={src}
                    className='image-link'
                    style={{
                      width: "100%",
                      display: "block",
                      cursor: "pointer",
                      maxHeight: "500px",
                      objectFit: "contain",
                      background: "inherit",
                    }}
                    onClick={() => toggleModal(image)}
                  />
                  <div
                    key={`${i}`}
                    style={{
                      height: `${randomHeight}px`,
                      backgroundColor: "#dbd7d7",
                      width: "100%",
                      display: "block",
                    }}
                  ></div>
                </>
              );
          }
        })}
      </Masonry>
      <ModalComponent image={image} isOpen={isModalOpen} toggleModal={toggleModal} />
    </ResponsiveMasonry>
  );
}
// return (
//   <div className={styles.root}>
//     <ModalComponent isOpen={isModalOpen} toggleModal={toggleModal} image={image} />
//     <div className={styles.staticBg}>
//       <p className={styles.storiesHeading}>Stories</p>
//     </div>
//     <div className={styles.content}>
//       {Array.from({ length: iterations }, (_, iteration) => {
//         return data.map((item, idx) => {
//           const adjustedIdx = (idx + iteration * numImages) % numImages;
//           return (
//             <ImageDiv
//               toggleModal={toggleModal}
//               setImage={setImage}
//               index={adjustedIdx}
//               img={`https:${storiesImages[adjustedIdx].fields.file.url}`}
//               size={size}
//               content={storiesImages[adjustedIdx].fields.description}
//               key={adjustedIdx}
//             ></ImageDiv>
//           );
//         });
//       })}
//     </div>
//   </div>
// );
